import React, { useEffect } from 'react'
import { useAppDispatch } from '../../store/Hooks'
import { logout } from '../../store/Store'
import useWindowDimensions from '../../utils/hooks/windowSize/useWindowSize'
import { DeniedDevice } from '../PopUps/UserNotification/DeniedDevice'
import { MAX_LENGTH_WINDOW } from '../../utils/constants/text-constants'
/**
 * Esta función verifica el ancho de la ventana y cierra la sesión del usuario si su sesión ha expirado.
 * @returns se devuelve el componente funcional `WindowDimensionComponent`
 */

const WindowDimensionComponent = () => {
  const {width}= useWindowDimensions()
  const dispatch = useAppDispatch()
  useEffect(() => {
    const d = localStorage.getItem('date')
    if(d!==null){
      const dateExpiration = new Date(d!)
      const dateCurrent= new Date()
      if(dateExpiration>dateCurrent){
      }else{
        dispatch(logout());
      }
    }else{
    }
  }, [])
  
  return (
    <>
       {
            width < MAX_LENGTH_WINDOW &&
            <DeniedDevice/>
       }
    </>
  )
}

export default WindowDimensionComponent