import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPadrinoxColdexState } from '../../../utils/interfaces/store/IStore'
import { Padrino } from '../../../utils/types/Padrino'
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces'



const initialState: IPadrinoxColdexState = {
  padrinos: [],
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}

export const padrinoxColdexSlice = createSlice({
  name: 'PadrinoXcoldex',
  initialState,
  reducers: {
    startPradinosLoading:(state)=>{
        state.isLoading=true
    },
    setPadrinos:(state,{payload}:PayloadAction<IPayloadSetdata<Array<Padrino>>>)=>{
        state.isLoading=false
        state.padrinos = payload.resultObject;
        state.resultMessage = payload.resultMessage!;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.resultMessage = payload.error
      state.dataError= payload.iserror
      state.shortMessage= payload.shorterror
      state.open= payload.open!
    },
    deletePadrinoLocal:(state,{payload}:PayloadAction<Padrino>)=>{
      state.padrinos = state.padrinos.filter( padrino => padrino.email !== payload.email);
      state.isLoading=false;
    },
    addPadrinoLocal:(state,{payload}:PayloadAction<Padrino>)=>{
      state.padrinos.push(payload)
      state.isLoading=false;
    },
    updatePadrinoLocal: (state,{payload}:PayloadAction<Padrino>) => {
      state.padrinos = state.padrinos.map( padrino => {
          if ( padrino.email === payload.email && padrino.idColdex===payload.idColdex ) {
              return payload;
          }
          return padrino;
      });  
      state.isLoading=false;
      
  },
  },
})

// Action creators are generated for each case reducer function
export const {  startPradinosLoading,setPadrinos,setError,deletePadrinoLocal,addPadrinoLocal,updatePadrinoLocal} = padrinoxColdexSlice.actions

export default padrinoxColdexSlice.reducer