import {
  AdminPanelSettingsOutlined,
  BarChartOutlined,
  Delete,
  Edit,
  GroupSharp,
  PreviewSharp as PreviewEye,
  UploadFileSharp,
  Visibility,
  DesktopMac,
  VisibilityOutlined,
  DownloadOutlined,
} from "@mui/icons-material";
import {
  ACTION_BUTTON_DELETE,
  ACTION_BUTTON_PERMISSION,
  ACTION_BUTTON_PREVIEW,
  ACTION_BUTTON_REPORTS,
  ACTION_BUTTON_SECURITY,
  ACTION_BUTTON_TEMPLETE,
  ACTION_BUTTON_UPDATE,
  ACTION_BUTTON_USERS,
  ACTION_BUTTON_MONITORING_REPORT,
  ACTION_BUTTON_VIEW_RESOURCE,
  ACTION_BUTTON_DOWNLOAD_RESOURCE
} from "../../utils/constants/text-constants";
import { IButton } from "../../utils/interfaces/app/IButton";

/** Este código exporta un componente de React llamado `ButtonActionTable` que incluye cuatro props: `flag`, `functionSelect`, `select` y `type`. Devuelve un botón con un icono basado en la prop `type`
 * La propiedad `functionSelect` se llama cuando se hace clic en el botón y se pasa la propiedad `select`. La prop `flag` se usa para habilitar o deshabilitar el botón. */
export const ButtonActionTable = ({
  flag,
  functionSelect,
  select,
  type,
}: IButton) => {
  return (
    <div className="action-content">
      <button
        className="btn-action"
        disabled={flag ? false : true}
        onClick={() => functionSelect(select)}
      >
        {type === ACTION_BUTTON_DELETE && <Delete fontSize="medium" />}
        {type === ACTION_BUTTON_UPDATE && <Edit fontSize="medium" />}
        {type === ACTION_BUTTON_PERMISSION && (
          <AdminPanelSettingsOutlined fontSize="medium" />
        )}
        {type === ACTION_BUTTON_PREVIEW && <PreviewEye fontSize="medium" />}
        {type === ACTION_BUTTON_TEMPLETE && (
          <UploadFileSharp fontSize="medium" />
        )}
        {type === ACTION_BUTTON_USERS && <GroupSharp fontSize="medium" />}
        {type === ACTION_BUTTON_SECURITY && <Visibility fontSize="medium" />}
        {type === ACTION_BUTTON_REPORTS && (
          <BarChartOutlined fontSize="medium" />
        )}
        {type === ACTION_BUTTON_MONITORING_REPORT && (
          <DesktopMac fontSize="medium" />
        )}
        {type === ACTION_BUTTON_VIEW_RESOURCE && (
          <VisibilityOutlined fontSize="medium"></VisibilityOutlined>
        )}
        {type === ACTION_BUTTON_DOWNLOAD_RESOURCE && (
          <DownloadOutlined fontSize="medium"></DownloadOutlined>
        )}

      </button>
    </div>
  );
};
