export const MESSAGE_ERROR_TIMEOUT = "Error en tiempo de Ejecución, Intente mas tarde."
export const MESSAGE_ERROR_ACTIVE_ACCOUNT = "Esta cuenta ya fue activada"
export const MESSAGE_SUCESS_ACTIVE_ACCOUNT = "Usuario Activado Correctamente"
export const MESSAGE_WARNING_ACTIVE_ACCOUNT = "Contraseñas no coinciden."
export const MESSAGE_ACTION_TYPE_CREATE="Crear"
export const MESSAGE_ACTION_TYPE_GET="Obtener"
export const MESSAGE_ACTION_TYPE_UPDATE="Actualizar"
export const MESSAGE_ACTION_TYPE_DELETE="Borrar"
export const MESSAGE_ACTION_TYPE_EMAIL="Enviar"
export const MESSAGE_CONTEXT_USERS="Usuario(os)"
export const MESSAGE_CONTEXT__CATEGORY="Categorias"
export const MESSAGE_CONTEXT_COMPANY="Compañias"
export const MESSAGE_CONTEXT_COLDEX="Coldex"
export const MESSAGE_CONTEXT_COMPANYXINDXCOLDEX="Compañia por Industria por Coldex"
export const MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY="COMPANYCOLDEXCATEGORY"
export const MESSAGE_CONTEXT_FORMUSERRESPONSE="Formulario de Usuario"
export const MESSAGE_CONTEXT_PADRINO="Padrino"
export const MESSAGE_CONTEXT_PARAMETERS="Parametros"
export const MESSAGE_CONTEXT_Permissions="Permisos"
export const MESSAGE_CONTEXT_POLLTYPE="Poll Type"
export const MESSAGE_CONTEXT_POLLXCOLDEX="Datos del cuestionario"
export const MESSAGE_CONTEXT_ROLS="Roles"
export const MESSAGE_CONTEXT_RESOURCES="Recursos"
export const MESSAGE_CONTEXT_SECUSERXCOLDEX="Permisos seguridad usuario por coldex"
export const MESSAGE_CONTEXT_USERANSWERXCOLDEX="Guardar progreso"
export const MESSAGE_CONTEXT_USERXCOLDEX="User x Coldex"
export const MESSAGE_CONTEXT_GETTEMPLATE="Obtener plantilla"
export const MESSAGE_CONTEXT_SENDEMAIL="Enviar plantilla"
export const MESSAGE_CONTEXT_ALERT_NOFOUND = "No hay mas información"
export const MESSAGE_CONTEXT_ALERT_QUESTIONS= "Las siguientes preguntas están sin responder"
export const MESSAGE_CONTEXT_ALERT_MATRIX=  "Las siguientes temáticas están sin responder"
export const MESSAGE_CONTEXT_ROWDATA=  "Al guardar data"
export const MESSAGE_CONTEXT_EMAIL=  "Correo"
export const MESSAGE_CONTEXT_EMAIL_ERROR=  "Usuario no existente"
export const MESSAGE_CONTEXT_EMAIL_SUCCESS=  "Su Respuesta ha sido enviada exitosamente. Numero de radicado: "
export const MESSAGE_CONTEXT_EMAIL_SUCCESS_SEND =   "Correo Enviado exitosamente."