import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICompanyColdexCategory } from '../../../utils/interfaces/store/IStore';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';
import { CompanyColdexCategory } from '../../../utils/types/CompanyColdexCategory';;

const initialState: ICompanyColdexCategory = {
    companyColdexCategory: undefined,
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}
export const companyColdexCategory = createSlice({
    name: 'companyColdexCategory',
    initialState,
    reducers:{
        startLoadingcompanyColdexCategory: (state) =>{
            state.isLoading = true;
        },
        setcompanyColdexCategory:(state, {payload}:PayloadAction<IPayloadSetdata<CompanyColdexCategory|undefined>>)=>{
            state.isLoading = false;
            state.dataError = false;
            state.companyColdexCategory = payload.resultObject;
            state.resultMessage =payload.resultMessage!;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.shortMessage = payload.shorterror;
            state.open = payload.open!;
            state.companyColdexCategory=undefined
        }
    }
})

export const {startLoadingcompanyColdexCategory, setcompanyColdexCategory,setError} = companyColdexCategory.actions

export default companyColdexCategory.reducer
