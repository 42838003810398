import {
  startLoading,
  setErrorSendMail,
  setFinishSendMail,
  startUpdate,
  setReset,
  setUpdateTemplateMailError
} from "./sendMailSlice";
import { MailBody } from "../../../utils/types/MailBody";
import { Coldex } from "../../../utils/types/Coldex";
import { Action, Dispatch } from "@reduxjs/toolkit";
import { MESSAGE_ACTION_TYPE_EMAIL, MESSAGE_CONTEXT_EMAIL, MESSAGE_CONTEXT_EMAIL_ERROR, MESSAGE_CONTEXT_EMAIL_SUCCESS, MESSAGE_CONTEXT_EMAIL_SUCCESS_SEND, MESSAGE_ERROR_TIMEOUT } from "../../../utils/constants/message-constants";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import { setError } from "../formUserResponse";
import { ERROR_UPDATE_TEMPLATE, SUBJECT_REGISTER_FORM_CONTACT, SUBJECT_REGISTER_REMEMBER_PASSWORD, SUBJECT_REGISTER_REMEMBER_REPORT } from "../../../utils/constants/text-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";

/**
  * Esta función envía un correo electrónico utilizando ColdexAPI y envía acciones para indicar el estado de inicio, finalización y error del proceso de envío de correo electrónico.
  * @param {MailBody} mail: el parámetro mail es un objeto de tipo MailBody, que contiene la información necesaria para enviar un correo electrónico. Tiene campos como la dirección de correo electrónico del destinatario, el asunto del correo electrónico y el cuerpo del correo electrónico.
  * @returns La función `postSendMailxUser` devuelve una función asíncrona que toma una función `dispatch` como argumento. Esta función envía acciones para comenzar a cargar, terminar de enviar el correo y establecer un error, si lo hay. También realiza una llamada a la API para enviar un correo electrónico utilizando el objeto `mail` pasado como argumento.
  */
export const postSendMailxUser = (mail: MailBody) => {
  return async (dispatch: Dispatch<Action>,) => {
    dispatch(startLoading());
    try {
      await ColdexAPI.post(`emails/Mail/SendMail`, mail);
      dispatch(setFinishSendMail());
    } catch (error) {
      dispatch(setErrorSendMail({ httpError: error ,isError:true}));
      dispatch(setFinishSendMail());
    }
  };
};

/**
  * Esta es una función de TypeScript que envía un correo electrónico mediante ColdexAPI y envía acciones para actualizar el estado en función del éxito o fracaso de la solicitud.
  * @param {MailBody} mail: el parámetro mail es un objeto de tipo MailBody, que contiene la información necesaria para enviar un correo electrónico, como la dirección de correo electrónico del destinatario, el asunto y el cuerpo del mensaje.
  * @returns La función `postSendMail` devuelve una función asíncrona que toma una función `dispatch` como argumento.
  */
export const postSendMail = (mail: MailBody) => {
  return async (dispatch: Dispatch<Action>,) => {
    
    dispatch(startLoading());
    try {
      await ColdexAPI.post<string>(`emails/Mail/SendMail`, mail);
      dispatch(setErrorSendMail({ httpError: "",isError:false, open:true})); 
      dispatch(setFinishSendMail());
    } catch (error) {
      dispatch(setErrorSendMail({ httpError: error,isError:true, open:true })); 
      dispatch(setFinishSendMail());

    }
  };
};

/**
  * Esta es una función de TypeScript que actualiza una plantilla de correo electrónico y la envía a una dirección de correo electrónico específica, con parámetros opcionales para un objeto Coldex y un indicador booleano para restablecer la contraseña.
  * @param {string} email: una cadena que representa la dirección de correo electrónico a la que se enviará el correo de plantilla.
  * @param {Coldex} coldex - El parámetro `coldex` es un objeto opcional de tipo `Coldex`. Contiene información sobre un Coldex específico, como su nombre y año. Si se proporciona, las propiedades `coldexName` y `coldexYear` se utilizarán para agregar al asunto del correo electrónico.
  * @param {boolean} [isPassword=false]: un indicador booleano que indica si el correo electrónico que se actualiza es para restablecer una contraseña o no. Si es verdadero, el asunto del correo electrónico se configurará como "Recuperación de contraseña"
  * @returns a Promise que resuelve los datos devueltos por la llamada a la API para enviar un correo electrónico con una plantilla actualizada.
  */
export  const updateTemplateMail = (email: string, coldex?: Coldex,isPassword:boolean=false) => {
  return async (dispatch:Dispatch<Action>) => {
    dispatch(startLoading());
    dispatch(startUpdate());
    const formData = new FormData();
    formData.append("ToEmail", email);
    if(coldex!=undefined){
      formData.append("ColdexName", coldex!.coldexName!);
    }
    try {
        let bodyMail 
        if(isPassword){
          const { data } = await ColdexAPI.post<IApiResult<string>>(
            `emails/Mail/UpdateTemplatePassword`,
              formData
            );
            bodyMail=data.resultObject
        }else{
          const { data } = await ColdexAPI.post<IApiResult<string>>(
            `emails/Mail/UpdateTemplate`,
              formData
            );
            bodyMail=data.resultObject
        }
        
          let mail_Body: MailBody = emailConfig(bodyMail,email, isPassword?SUBJECT_REGISTER_REMEMBER_PASSWORD:coldex!.coldexName + " " + coldex!.coldexYear, false) 
          let data2;
            if(bodyMail !== ERROR_UPDATE_TEMPLATE){ 
              const { data } =  await ColdexAPI.post<IApiResult<string>>(`emails/Mail/SendMail`, mail_Body)
              data2=data
              dispatch(setErrorSendMail({ httpError:MESSAGE_CONTEXT_EMAIL_SUCCESS_SEND, isError:false, open:true})); 
              dispatch(setFinishSendMail());
            }else{
              dispatch(setUpdateTemplateMailError({httpErrorUpdate: `${MESSAGE_CONTEXT_EMAIL_ERROR} al ${MESSAGE_ACTION_TYPE_EMAIL} en ${MESSAGE_CONTEXT_EMAIL}`,isError:true, open:true}))
              dispatch(setFinishSendMail());
            }
          return data2
    } catch (error) {
        dispatch(setUpdateTemplateMailError({httpErrorUpdate:`${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_EMAIL} en ${MESSAGE_CONTEXT_EMAIL}`,isError:true, open:true}))
        dispatch(setFinishSendMail());
      }
  };
};


export const updateTemplateNotifyPadrino = (email: string)=>{
  return async (dispatch:Dispatch<Action>) => {
    dispatch(startLoading());
    dispatch(startUpdate());
    try {
      const { data } = await ColdexAPI.post<IApiResult<string>>(`emails/Mail/UpdateTemplatNotifyPadrino`);
      let mail_Body: MailBody = emailConfig(data.resultObject,email, SUBJECT_REGISTER_REMEMBER_REPORT, false);
      let data2;
      if(data.resultObject !== ERROR_UPDATE_TEMPLATE){ 
        const { data } =  await ColdexAPI.post<IApiResult<string>>(`emails/Mail/SendMail`, mail_Body)
        data2=data
        dispatch(setErrorSendMail({ httpError:MESSAGE_CONTEXT_EMAIL_SUCCESS_SEND, isError:false, open:true})); 
        dispatch(setFinishSendMail());
      }else{
        dispatch(setUpdateTemplateMailError({httpErrorUpdate: `${MESSAGE_CONTEXT_EMAIL_ERROR} al ${MESSAGE_ACTION_TYPE_EMAIL} en ${MESSAGE_CONTEXT_EMAIL}`,isError:true, open:true}))
        dispatch(setFinishSendMail());
      }
      return data2
    } catch (error) {
      dispatch(setUpdateTemplateMailError({httpErrorUpdate:`${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_EMAIL} en ${MESSAGE_CONTEXT_EMAIL}`,isError:true, open:true}))
      dispatch(setFinishSendMail());
    }
  }
}

/**
  * Esta es una función de TypeScript que envía una respuesta por correo electrónico a un usuario después de que se haya enviado un formulario.
  * @param {string} response: una cadena que representa la respuesta a un formulario.
  * @param {string} emailUser: la dirección de correo electrónico del usuario que recibirá el correo electrónico.
  * @param {número} responseID: el ID de respuesta es un parámetro numérico que representa el ID del formulario de respuesta.
  * @retorna una función asíncrona que toma como parámetro una función de despacho.
  */
export const postSendResponseFormxUser = (response : string, emailUser: string, responseID : number) => {
  return async (dispatch: Dispatch<Action>,) => {
    dispatch(startLoading());
    try {
      const { data: template } = await ColdexAPI.post<IApiResult<string>>( `emails/Mail/UpdateTemplateFormResponse?response=${response}` );
      if(template !== null){
        let mail_Body: MailBody = emailConfig(template.resultObject, emailUser, SUBJECT_REGISTER_FORM_CONTACT, true);  
        await ColdexAPI.post<IApiResult<string>>(`emails/Mail/SendMail`, mail_Body).finally(()=>{
          dispatch(setError({ iserror: false, shorterror: `${MESSAGE_CONTEXT_EMAIL_SUCCESS} ${responseID}`, open: true,error:'' }));
        });
         dispatch(setFinishSendMail());
      }
    } catch (error) {
      dispatch(setErrorSendMail({ httpError: error ,isError:true}));
      dispatch(setFinishSendMail());
    }
  };
};

function bufferToBase64(buffer: Uint8Array) {
  var binstr = Array.prototype.map.call(buffer, function (ch) {
      return String.fromCharCode(ch);
  }).join('');
  return btoa(binstr);
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
      dispatch(setReset());
  }
}

function emailConfig (template:string, emailUser:string, subject:string, flag:boolean) :  MailBody{
  let mail_Body = {
    template: template,
    emailUser:emailUser,
    subject: subject,
    flag: flag 
  }
 return mail_Body
}