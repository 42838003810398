import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_ACTION_TYPE_GET, MESSAGE_CONTEXT_ROWDATA, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { RowDataColdex } from '../../../utils/types/RowDataColdex';
import { IColdexSecurity } from '../../../utils/types/UserColdex';
import {  startLoading,setError,endLoading,setRowDataColdexSlice, setResetRowDataSlice} from './rowDataColdexSlice';

/**
  * Esta es una función de TypeScript React que publica datos sin procesar en ColdexAPI y envía acciones de carga y error según la respuesta.
  * Respuestas @param: un array de objetos RowDataColdex que se enviarán como carga útil en la solicitud POST a ColdexAPI (para coldex sectorial) 
  * @returns una Promesa que se resuelve como indefinida.
  */
export const postRawData = (answers:Array<RowDataColdex>) => {
    return async( dispatch:Dispatch<Action> ) => {
        dispatch( startLoading() );
        try {
            const { data } = await ColdexAPI.post<IApiResult<Array<RowDataColdex>>>(`adminColdex/RowDataColdex`,answers);
            dispatch(endLoading());
            if (data.dataError) {
                dispatch(
                  setError({
                    error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_ROWDATA}`,
                    iserror: true,
                    shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_ROWDATA}`,
                    open: true,
                  })
                );
            }else{
              dispatch(setRowDataColdexSlice({resultObject:answers}))
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_ROWDATA}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_ROWDATA}`, open: true }));
        }
    }    
}

/**
  * Esta es una función que recupera datos de las respuestas de un coldex de un punto final de API y envía acciones en función de la respuesta.
  * @param {IColdexSecurity} coldexSecurity: objeto de interfaz IColdexSecurity que contiene información de seguridad para acceder al extremo de la API.
  * @returns La función `getRowData` devuelve una función que toma un parámetro `dispatch` y devuelve una Promesa. (para coldex sectorial) 
  */
export const getRowData = (coldexSecurity: IColdexSecurity) => {
    return async( dispatch:Dispatch<Action> ) => {
        dispatch( startLoading() );
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<RowDataColdex>>>(
                `adminColdex/RowDataColdex/Answers?IdColdex=${coldexSecurity.idColdex}&IdPoll=${coldexSecurity.idPoll}&IdCategory=${coldexSecurity.idCategory}&IdType=${coldexSecurity.idType}&Email=${coldexSecurity.email}`
              );
              if (data.dataError) {
                dispatch(
                  setError({
                    error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_ROWDATA}`,
                    iserror: true,
                    shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_ROWDATA}`,
                    open: true,
                  })
                );
            }else{
                dispatch(setRowDataColdexSlice({resultObject:data.resultObject}))
            }
            dispatch(endLoading());
            
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_ROWDATA}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_ROWDATA}`, open: true }));
        }
    }    
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}

export const resetRowData=() =>{
  return async (dispatch: Dispatch<Action>) => {
    dispatch(setResetRowDataSlice());
}
}