import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/Hooks";
import { getColdexxPoll, setInfo } from "../../../store/slices/pollxColdex";
import { IPreviewData } from "../../interfaces/app/IPopUps";
import { Coldex } from "../../types/Coldex";
import { ColdexConfigPreview } from "../../types/ColdexConfig";
import { Category, Levels, Poll } from "../../types/Polls";


/**
* Este es un enlace personalizado en TypeScript que devuelve varias variables de estado y funciones para manejar
  * acciones para obtener una vista previa de un Coldex.
  * @param {Coldex} coldex - Un objeto Coldex.
  * @param {Función} setIsOpen - Una función que establece el estado de si una ventana emergente está abierta o
  * no.
  * @returns La función `usePreviewActionsColdex` devuelve un objeto que contiene lo siguiente
  * propiedades y funciones:category,type,polls,poll,levels,dimension,categories, setDimension, hanndleSelectCategory,hanndleColdexType,handleType, handleclosePopUp
 */
const usePreviewActionsColdex = (coldex: Coldex, setIsOpen: Function) =>{
    const dispatch = useAppDispatch();
    const [category, setCategory] = useState<string>();
    const [type, setType] = useState<string>();
    const [polls, setPolls] = useState<Array<Poll>>();
    const [poll, setPoll] = useState<string>();
    const [levels, setLevels] = useState<Array<Levels>>();
    const [dimension, setDimension] = useState<string>();
    const [categories, setCategories] = useState<Array<Category>>();
    useEffect(() => {
        if (dimension !== undefined) {
            if (dimension.length !== 0) {
              dispatch(setInfo({ resultObject: null }));
              const auxColdexConfigPreview : ColdexConfigPreview = {
                IdColdex: coldex.id!,
                IdPoll: poll!,
                IDCategory: category!,
                PollLevel: Number(dimension),
                IDType: type!,
              }
              dispatch(
                getColdexxPoll(auxColdexConfigPreview)
              );
            }
          }
    }, [dimension])//eslint-disable-line react-hooks/exhaustive-deps
    

 /**
    * Esta función establece varios estados en función de la categoría seleccionada.
   * @param {Categoría} s - s es un parámetro de tipo Categoría. Es probable que sea un objeto que contiene
   * información sobre una categoría, como su ID y encuestas asociadas. La función
   * hanndleSelectCategory toma este parámetro como entrada y realiza varias acciones en función de su
   * propiedades.
   */
    const hanndleSelectCategory = (s: Category) => {
        setCategory(s.idCategory)
        setPolls(s.polls)
        setPoll(undefined)
        dispatch(setInfo({ resultObject: null }));
        setLevels([])
        setDimension(undefined)
      };
    /**
     * La función establece varios estados en función del objeto Poll de entrada.
     * @param {Poll} s - El parámetro `s` es del tipo `Poll`.
      */
      const hanndleColdexType = (s: Poll) => {
        dispatch(setInfo({ resultObject: null }));
        setPoll(s.idPoll)
        setLevels(s.levels)
        setDimension(undefined)
      }; 

  /**
   *  Esta función establece resultObject en nulo y cierra una ventana emergente.
   */
      const handleclosePopUp = () => {
        dispatch(setInfo({ resultObject: null }));
        setIsOpen(false);
      };
    
    /**
   * Esta función establece varias variables de estado y envía una acción basada en la entrada
      * parámetro.
      * @param {IPreviewData} s - IPreviewData
     */
      const handleType = (s:IPreviewData)=>{
        setType(s.idType)
        setCategories(s.data)
        setCategory(undefined)
        setPolls([])
        setPoll(undefined)
        setLevels([])
        dispatch(setInfo({ resultObject: null }));
        setDimension(undefined)
      }
      return {category,type,polls,poll,levels,dimension,categories, setDimension, hanndleSelectCategory,hanndleColdexType,handleType, handleclosePopUp}
}

export default usePreviewActionsColdex;