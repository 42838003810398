
import { useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import { TEXT_DEVICE_DENIED, TITLE_LOGYCA} from "../../../utils/constants/text-constants";
import { PopUpTitle } from "../../Titles/PopUpTitle";
import "../popUps.scss";

/**
 * Este es un componente de TypeScript React que muestra una notificación emergente para un dispositivo denegado.
 * @returns Un componente funcional de React que muestra una ventana emergente con un título y un párrafo de texto. 
 * El texto se recupera de la tienda Redux o de una constante, dependiendo de si la variable `idioma` está definida o no.
 */
export const DeniedDevice = () => {
  const {language} = useAppSelector((state:RootState) => state.multiLanguage);
    return (
      <div className={"open-PopUp"}>
        <div className="popUp-Content">
          <PopUpTitle
            title={TITLE_LOGYCA} 
            setIsOpen={undefined}         
            ></PopUpTitle>
          <div>
          <p>{language !== undefined ? language.components.popUps.userNotification.deniedDevice.descriptions.TEXT_DEVICE_DENIED : TEXT_DEVICE_DENIED}</p>
            <br/>
          </div>
        </div>
      </div>
    );
  };