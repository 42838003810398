import {createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserxColdexState } from '../../../utils/interfaces/store/IStore'
import { ColdexUser } from '../../../utils/types/Coldex'
import { UserColdex } from '../../../utils/types/UserColdex'
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces'



const initialState: IUserxColdexState = {
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
  coldex: [],
  users: []
}
export const userxColdexSlice = createSlice({
  name: 'UserxColdex',
  initialState,
  reducers: {
    startUserxColdexLoading:(state)=>{
        state.isLoading=true
    },
    setUserxColdex:(state,{payload}:PayloadAction<IPayloadSetdata<Array<UserColdex>>>)=>{
        state.isLoading=false
        state.users = payload.resultObject;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading:(state)=>{
      state.isLoading=false
    },
    setColdexxUser:(state,{payload}:PayloadAction<IPayloadSetdata< Array<ColdexUser>>>)=>{
      state.coldex=payload.resultObject;
      state.isLoading=false
    },
    deleteUserxColdexLocal:(state,{payload}:PayloadAction<string>)=>{
      state.users = state.users.filter( user => user.email !== payload);
      state.isLoading=false;
    },
    addUserxColdexLocal:(state,{payload}:PayloadAction<UserColdex>)=>{
      state.users.push(payload)
    },
    updateUserxColdexLocal: (state, {payload}:PayloadAction<UserColdex> ) => {
      state.users = state.users.map( user => {
        var newUser = Object.assign({} , user)
          if ( user.email === payload.email && user.idColdex===payload.idColdex) {
              newUser.email=payload.email
              newUser.position=payload.position
              newUser.state=payload.state
              newUser.idType=payload.idType
              return newUser;
          }
          return newUser;
      });
      
  },
  },
})

export const {  startUserxColdexLoading,setUserxColdex,setError,endLoading,setColdexxUser,addUserxColdexLocal,deleteUserxColdexLocal,updateUserxColdexLocal} = userxColdexSlice.actions

export default userxColdexSlice.reducer