import { createSlice } from "@reduxjs/toolkit";
import { IReports } from "../../../utils/interfaces/store/IStore";

const initialState: IReports = {
  session: {
    companyName: null,
    companyRol: null,
    userPermission: null,
    category: [],
    isSession: false,
  },
  visiblePlotIndex: 0,
  visibleSectionIndex: 0,
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetStore: () => initialState,
    setSession: (state, { payload }) => {
      state.session = {
        companyName: payload.companyName,
        companyRol: payload.companyRol,
        userPermission: payload.userPermission,
        category: payload.category,
        isSession: true,
      };
    },
    incrementPlotIndex: (state) => {
      state.visiblePlotIndex = state.visiblePlotIndex + 1;
    },
    resetPlotIndex: (state) => {
      state.visiblePlotIndex = 0;
    },
    incrementSectionIndex: (state) => {
      state.visibleSectionIndex = state.visibleSectionIndex + 1;
    },
  },
});

export const { setSession, resetStore, incrementPlotIndex,resetPlotIndex,incrementSectionIndex } =
  reportSlice.actions;

export default reportSlice.reducer;
