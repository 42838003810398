import React from 'react'
import {  useAppSelector } from '../../store/Hooks'
import { resetError as resetErrorAdminUser } from '../../store/slices/adminUsers';
import { resetError as resetErrorCategory } from '../../store/slices/categoryXColdex';
import { resetError as resetErrorColdex } from '../../store/slices/coldex';
import { resetError as resetErrorCompany } from '../../store/slices/company';
import { resetError as resetErrorCompanyxIndxC } from '../../store/slices/companyXIndXColdex';
import { resetError as resetErrorPadrino } from '../../store/slices/padrinoxColdex';
import { resetError as resetErrorParameters } from '../../store/slices/parameters';
import { resetError as resetErrorPermissions } from '../../store/slices/permissionXRol';
import { resetError as resetErrorPollTypes } from '../../store/slices/pollTypes';
import { resetError as resetErrorPollTxColdex } from '../../store/slices/pollxColdex';
import { resetError as resetErrorRols } from '../../store/slices/role';
import { resetError as resetErrorUserxColdex } from '../../store/slices/userxColdex';
import { resetError as resetErrorSecxUserxColdex } from '../../store/slices/securityxUserxColdex';
import { resetError as resetErrorUserAnswer } from '../../store/slices/userAnswerxColdex';
import { resetError as resetErrorTemplate } from '../../store/slices/templeteFile';
import { resetError as resetErrorSendEmail } from '../../store/slices/sendMail';
import { resetError as resetErrorRowData } from '../../store/slices/rowData';
import { resetError as resetErrorUserFormResponse } from '../../store/slices/formUserResponse';
import { resetError as resetErrorSectionResource } from '../../store/slices/sectionResource';
import { resetError as resetErrorSection } from '../../store/slices/section';
import { resetError as resetErrorSectionForm } from '../../store/slices/sectionForm';
import { RootState } from "../../store/Store";
import Alert from '../Alert/Alerts';


  /**
    * La función Errores muestra alertas basadas en estados de error de varias store del  Redux.
    * @returns El componente de errores devuelve una lista de componentes de alerta según el estado de varios segmentos de Redux. Cada componente de alerta muestra un mensaje de error con un título, tipo, texto y subtexto. El tipo del componente de alerta está determinado por si hay un error o no.
    */
const Errors = () => {
  const { open: openAdminUser, dataError: dataErrorAdminUser, resultMessage: resultMessageAdminUser, shortMessage: shortMessageAdminUser } = useAppSelector((state: RootState) => state.adminuser);
  const { open: openCategory, dataError: dataErrorCategory, resultMessage: resultMessageCategory, shortMessage: shortMessageCategory } = useAppSelector((state: RootState) => state.categoryxColdex);
  const { open: openColdex, dataError: dataErrorColdex, resultMessage: resultMessageColdex, shortMessage: shortMessageColdex } = useAppSelector((state: RootState) => state.coldex);
  const { open: openCompany, dataError: dataErrorCompany, resultMessage: resultMessageCompany, shortMessage: shortMessageCompany } = useAppSelector((state: RootState) => state.company);
  const { open: openCompanyxIndxC, dataError: dataErrorCompanyxIndxC, resultMessage: resultMessageCompanyxIndxC, shortMessage: shortMessageCompanyxIndxC } = useAppSelector((state: RootState) => state.companyxIndxColdex);
  const { open: openPadrino, dataError: dataErrorPadrino, resultMessage: resultMessagePadrino, shortMessage: shortMessagePadrino } = useAppSelector((state: RootState) => state.padrinos);
  const { open: openParameters, dataError: dataErrorParameters, resultMessage: resultMessageParameters, shortMessage: shortMessageParameters } = useAppSelector((state: RootState) => state.parameters);
  const { open: openPermissions, dataError: dataErrorPermissions, resultMessage: resultMessagePermissions, shortMessage: shortMessagePermissions } = useAppSelector((state: RootState) => state.permission);
  const { open: openPollType, dataError: dataErrorPollType, resultMessage: resultMessagePollType, shortMessage: shortMessagePollType } = useAppSelector((state: RootState) => state.polltypes);
  const { open: openPollxColdex, dataError: dataErrorPollxColdex, resultMessage: resultMessagePollxColdex, shortMessage: shortMessagePollxColdex } = useAppSelector((state: RootState) => state.pollxColdex);
  const { open: opeRols, dataError: dataErroRols, resultMessage: resultMessagRols, shortMessage: shortMessagRols } = useAppSelector((state: RootState) => state.rol);
  const { open: opeUserxColdex, dataError: dataErroUserxColdex, resultMessage: resultMessagUserxColdex, shortMessage: shortMessagUserxColdex } = useAppSelector((state: RootState) => state.userxcoldex);
  const { open: opeSecxUserxColdex, dataError: dataErroSecxUserxColdex, resultMessage: resultMessagSecxUserxColdex, shortMessage: shortMessagSecxUserxColdex } = useAppSelector((state: RootState) => state.userxsecurityxcoldex);
  const { open: opeUserAnswer, dataError: dataErroUserAnswer, resultMessage: resultMessagUserAnswer, shortMessage: shortMessagUserAnswer } = useAppSelector((state: RootState) => state.useranswerxcoldex);
  const { open: opeRowData, dataError: dataErroRowData, resultMessage: resultMessagRowData, shortMessage: shortMessagRowData } = useAppSelector((state: RootState) => state.rowData);
  const { iserror: dataErrorTemplate, error: resultMessageTemplate, shortMessage: shortMessageTemplate } = useAppSelector((state: RootState) => state.templeteFile);
  const { open: openUserFormResponse, dataError: dataErrorUserResponse, shortMessage: shortMessageUserResponse, resultMessage: resultMessageUserFormResponse } = useAppSelector((state: RootState) => state.sectionFormResponseUser);
  const { open: openSectionResource, dataError: dataErrorSectionResource, shortMessage: shortMessageSectionResource, resultMessage: resultMesssageSectionResource } = useAppSelector((state: RootState) => state.sectionResource);
  const { open: openSection, dataError: dataErrorSection, shortMessage: shortMessageSection, resultMessage: resultMesssageSection } = useAppSelector((state: RootState) => state.section);
  const { open: openSectionForm, dataError: dataErrorSectionForm, shortMessage: shortMessageSectionForm, resultMessage: resultMessageSectionForm } = useAppSelector((state: RootState) => state.sectionForm);
  const { language } = useAppSelector((state: RootState) => state.multiLanguage);
  const {isError,httpError,httpErrorUpdate, open} = useAppSelector((state: RootState) => state.sendMail);
 
  return (
    <>
      {
        openAdminUser &&
        <Alert title={dataErrorAdminUser?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorAdminUser ? 'SUCCESS' : 'ERROR'} open={openAdminUser} text={shortMessageAdminUser} setopen={resetErrorAdminUser} subtext={resultMessageAdminUser}></Alert>
      }
      {
        openCategory &&
        <Alert title={dataErrorCategory?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorCategory ? 'SUCCESS' : 'ERROR'} open={openCategory} text={shortMessageCategory} setopen={resetErrorCategory} subtext={resultMessageCategory}></Alert>
      }
      {
        openColdex &&
        <Alert title={dataErrorColdex?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorColdex ? 'SUCCESS' : 'ERROR'} open={openColdex} text={shortMessageColdex} setopen={resetErrorColdex} subtext={resultMessageColdex}></Alert>
      }
      {
        openCompany &&
        <Alert  title={dataErrorCompany?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorCompany ? 'SUCCESS' : 'ERROR'} open={openCompany} text={shortMessageCompany} setopen={resetErrorCompany} subtext={resultMessageCompany}></Alert>
      }
      {
        openCompanyxIndxC &&
        <Alert title={dataErrorCompanyxIndxC?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorCompanyxIndxC ? 'SUCCESS' : 'ERROR'} open={openCompanyxIndxC} text={shortMessageCompanyxIndxC} setopen={resetErrorCompanyxIndxC} subtext={resultMessageCompanyxIndxC}></Alert>
      }
      {
        openPadrino &&
        <Alert  title={dataErrorPadrino?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorPadrino ? 'SUCCESS' : 'ERROR'} open={openPadrino} text={shortMessagePadrino} setopen={resetErrorPadrino} subtext={resultMessagePadrino}></Alert>
      }
      {
        openParameters &&
        <Alert title={dataErrorParameters?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorParameters ? 'SUCCESS' : 'ERROR'} open={openParameters} text={shortMessageParameters} setopen={resetErrorParameters} subtext={resultMessageParameters}></Alert>
      }
      {
        openPermissions &&
        <Alert title={dataErrorPermissions?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorPermissions ? 'SUCCESS' : 'ERROR'} open={openPermissions} text={shortMessagePermissions} setopen={resetErrorPermissions} subtext={resultMessagePermissions}></Alert>
      }
       {
        openPollType &&
        <Alert title={dataErrorPollType?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorPollType ? 'SUCCESS' : 'ERROR'} open={openPollType} text={shortMessagePollType} setopen={resetErrorPollTypes} subtext={resultMessagePollType}></Alert>
      }
      {
        openPollxColdex &&
        <Alert title={dataErrorPollxColdex?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErrorPollxColdex ? 'SUCCESS' : 'ERROR'} open={openPollxColdex} text={shortMessagePollxColdex} setopen={resetErrorPollTxColdex} subtext={resultMessagePollxColdex}></Alert>
      }
      {
        opeRols &&
        <Alert  title={dataErroRols?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErroRols ? 'SUCCESS' : 'ERROR'} open={opeRols} text={shortMessagRols} setopen={resetErrorRols} subtext={resultMessagRols}></Alert>
      }
      {
        opeUserxColdex &&
        <Alert title={dataErroUserxColdex?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErroUserxColdex ? 'SUCCESS' : 'ERROR'} open={opeUserxColdex} text={shortMessagUserxColdex} setopen={resetErrorUserxColdex} subtext={resultMessagUserxColdex}></Alert>
      }
      {
        opeSecxUserxColdex &&
        <Alert  title={dataErroSecxUserxColdex?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErroSecxUserxColdex ? 'SUCCESS' : 'ERROR'} open={opeSecxUserxColdex} text={shortMessagSecxUserxColdex} setopen={resetErrorSecxUserxColdex} subtext={resultMessagSecxUserxColdex}></Alert>
      }
      {
        opeUserAnswer &&
        <Alert  title={dataErroUserAnswer?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErroUserAnswer ? 'SUCCESS' : 'ERROR'} open={opeUserAnswer} text={shortMessagRowData} setopen={resetErrorUserAnswer} subtext={resultMessagUserAnswer}></Alert>
      }
      {
        opeRowData &&
        <Alert title={dataErroRowData?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={!dataErroRowData ? 'SUCCESS' : 'ERROR'} open={opeRowData} text={shortMessagUserAnswer} setopen={resetErrorRowData} subtext={resultMessagRowData}></Alert>
      }
      {
        dataErrorTemplate &&
        <Alert  title={dataErrorTemplate?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorTemplate ? 'SUCCESS' : 'ERROR'} open={dataErrorTemplate} text={shortMessageTemplate} setopen={resetErrorTemplate} subtext={resultMessageTemplate}></Alert>
      }
      {
        openUserFormResponse &&
        <Alert  title={dataErrorUserResponse?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorUserResponse ? 'SUCCESS' : 'ERROR'} open={openUserFormResponse} text={shortMessageUserResponse} setopen={resetErrorUserFormResponse} subtext={resultMessageUserFormResponse}></Alert>
      }
      {
        openSectionResource &&
        <Alert  title={dataErrorSectionResource?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorSectionResource ? 'SUCCESS' : 'ERROR'} open={openSectionResource} text={shortMessageSectionResource} setopen={resetErrorSectionResource} subtext={resultMesssageSectionResource}></Alert>
      }
      {
        openSection &&
        <Alert  title={dataErrorSection?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorSection ? 'SUCCESS' : 'ERROR'} open={openSection} text={shortMessageSection} setopen={resetErrorSection} subtext={resultMesssageSection}></Alert>
      }
      {
        openSectionForm &&
        <Alert  title={dataErrorSectionForm?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS}type={!dataErrorSectionForm ? 'SUCCESS' : 'ERROR'} open={openSectionForm} text={shortMessageSectionForm} setopen={resetErrorSectionForm} subtext={resultMessageSectionForm}></Alert>
      }
      {
        open &&
        <Alert title={isError?"ERROR": language.components.errors.titles.TITLE_WELCOME_SUCCESS} type={isError?'ERROR':'SUCCESS'} open={open} text={httpError.toString().length>0?httpError:httpErrorUpdate} setopen={resetErrorSendEmail}></Alert>
      }
      
    </>
  )
}

export default Errors