
import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_CONTEXT_COMPANYXINDXCOLDEX, MESSAGE_CONTEXT_FORMUSERRESPONSE, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { UserResponseSend } from '../../../utils/types/ReourcesFormUser';
import { startLoadingSendResponse, setError, setUserResponseFormResult } from './formUserResponseSlice';

/**
  * Esta función crea una respuesta de formulario para un usuario y envía acciones en función del éxito o fracaso de la llamada a la API.
  * @param {UserResponseSend} userResponse: el parámetro `userResponse` es un objeto de tipo `UserResponseSend`, que se pasa como argumento a la función `createFormResponsexUser`.
  * @retorna una función asíncrona que toma una función de envío como argumento y devuelve una Promesa. Promise se resuelve en un objeto que contiene una matriz de objetos UserResponseSend.
  */
export const createFormResponsexUser= (userResponse: UserResponseSend) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(startLoadingSendResponse());
        try {
            const { data } = await ColdexAPI.post<IApiResult<UserResponseSend>>("/adminresources/api/SectionFormResponse", userResponse);
            if (data.dataError) {
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_FORMUSERRESPONSE}`, open: true }));
            } else {
              dispatch( setUserResponseFormResult(data.resultObject))    
              return data
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_FORMUSERRESPONSE}`, open: true }));
        }
    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}