/**
 * Este es un enlace personalizado en TypeScript que maneja acciones de formulario para crear, actualizar y eliminar
 * Datos de Coldex, y también actualiza el estado de los datos de Coldex.
 * @param {Function} [setIsOpen] -Funcion para cambiar el estado del modal
 * @param {Coldex} [coldex] - coldex
 * @param {String} [type] - tipo de coldex
 * @returns retorna un objeto con varias funciones y atributos
 * handleUpdateStateColdex: funcion para actualizar el stado del coldex
 * handleCreateColdex: funcion para crear un coldex
 * handleUpdateColdex: funcion para actualizar el coldex
 * handleDeleteColdex: funcion para elliminar el coldex
 * startDate: fecha de inicio del coldex
 * setStartDate: funcion para set la fecha de inicio
 * coldexName: nombre del coldex
 * setName: funcion que setea el nombre del coldex
 * endDate: fecha fin del coldex
 * setEndDate: funcion para set la fecha fin
 * coldexYear: año del coldex
 * setYear:funcion para set año del coldex
 * description: descripcion del coldex
 * setDescription:funcion del set  descripcion del coldex
 * coldexCountry : pais del coldex
 * setCountry: funcion setea el pais del coldex
 * status: estado del coldex
 * setStatus: funcion que setea el estado del coldex
 * coldexType:tipo del coldex
 * setColdexType:funcion que setea el estado del coldex
 */
import React, { useState } from 'react'
import { useAppDispatch } from '../../../store/Hooks';
import { deleteColdex, updateColdex, createColdex } from '../../../store/slices/coldex';
import { CreateCompanyColdexCategory, UpdateCompanyColdexCategory } from '../../../store/slices/companyColdexCategory';
import {  CONFIG, FILTER_COLDEX_PUBLIC_TYPE, FILTER_COLDEX_RED_TYPE, FILTER_COLDEX_SECTORIAL_TYPE, NUMBER_DEFAULT, TITLE_COLDEX_SECTION_PUBLICO, TITLE_COLDEX_SECTION_RED, TITLE_COLDEX_SECTION_SECTORIAL } from '../../constants/text-constants';
import { IFormInputsColdex } from '../../interfaces/app/IFormInputs';
import { Coldex } from '../../types/Coldex'
import { CompanyColdexCategory } from '../../types/CompanyColdexCategory';

const useFormActionsColdex = (setIsOpen? : Function, coldex? : Coldex, type?: String) => {
    const dispatch = useAppDispatch();
    const [startDate, setStartDate] = useState<string  | number |undefined>(coldex !== undefined ?coldex.startDate : "");
    const [coldexName, setName] = useState<string | undefined>(coldex?.coldexName);
    const [endDate, setEndDate] = useState<string |  number |undefined>(coldex !== undefined ? coldex.endDate : "");
    const [coldexYear, setYear] = useState<number | undefined | string>(coldex?.coldexYear);
    const [description, setDescription] = useState<string | undefined>(coldex?.description);
    const [coldexCountry, setCountry] = useState<string | undefined>(coldex?.coldexCountry);
    const [status, setStatus] = useState<string | undefined>(coldex?.status);
    const [coldexType, setColdexType] = useState<string>(
      type !== undefined && type === TITLE_COLDEX_SECTION_SECTORIAL ?
       FILTER_COLDEX_SECTORIAL_TYPE : type === TITLE_COLDEX_SECTION_RED ?
       FILTER_COLDEX_RED_TYPE : type === TITLE_COLDEX_SECTION_PUBLICO ?
       FILTER_COLDEX_PUBLIC_TYPE:""
      );
   
    /**
    * Esta función crea un objeto Coldex y lo envía, con opciones adicionales
    * datos si se especifica un cierto tipo.
    * @param {IFormInputsColdex} data: objeto IFormInputsColdex que contiene las entradas de formulario para crear Coldex
    */
    const handleCreateColdex = (data: IFormInputsColdex): void => {
        if(type===FILTER_COLDEX_RED_TYPE.toUpperCase()){
          //enviar a la otra tabla
          const auxCompany = data.companyF.split("-");
          dispatch(CreateCompanyColdexCategory({
            status: false,
            categoryName: data.categoryNameF,
            tdoc: auxCompany[0].replace(" ", "") === "null"?"null" : auxCompany[0].replace(" ", ""),
            ndoc: auxCompany[1].replace(" ", "") === "null"?"null" : auxCompany[1].replace(" ", "")
          })).then(response=>{
            const coldex :Coldex={
              coldexName: data.coldexNameF ,
              coldexCountry: data.coldexCountryF,
              startDate: startDate,
              endDate: endDate,
              coldexYear: data.coldexYearF,
              description: data.descriptionF,
              status:CONFIG,
              coldexType,
              ndoc: auxCompany[1].replace(" ", "") === "null"?"null" : auxCompany[1].replace(" ", ""),
              tdoc: auxCompany[0].replace(" ", "") === "null"?"null" : auxCompany[0].replace(" ", ""),
              idColdexCategory:response?.id
            }
            dispatch(
              createColdex(coldex)
            )
          })
        }else{
          const coldex :Coldex={
            coldexName: data.coldexNameF ,
            coldexCountry: data.coldexCountryF,
            startDate: startDate,
            endDate: endDate,
            coldexYear: data.coldexYearF,
            description: data.descriptionF,
            status:CONFIG,
            coldexType,
          }
          dispatch(
            createColdex(coldex)
/**
 * This function updates a coldex and its associated company category based on user input.
 * @param {IFormInputsColdex} data - IFormInputsColdex object containing form input values
 * @param {CompanyColdexCategory} companyColdexCategory - It is a parameter of type
 * CompanyColdexCategory that is being passed to the handleUpdateColdex function.
 */
          )
        }
        if(setIsOpen !== undefined){
          setIsOpen(false);
        }
    };

    /**
   * Esta función actualiza un objeto de Coldex y su categoría de empresa asociada según la entrada del usuario.
      * @param {IFormInputsColdex} data: objeto IFormInputsColdex que contiene valores de entrada de formulario
      * @param {CompanyColdexCategory} companyColdexCategory - El parámetro `companyColdexCategory` es
      * de tipo `CompanyColdexCategory`, que es un objeto que contiene información sobre la empresa
      * categoría en el sistema Coldex, solo se usa en lider de red
     */
    const handleUpdateColdex = (data: IFormInputsColdex,companyColdexCategory:CompanyColdexCategory): void => {
        if(type===FILTER_COLDEX_RED_TYPE.toUpperCase()){
          const auxCompany = data.companyF.split("-");
          if(coldex?.tdoc===auxCompany[0].replace(" ", "") && coldex?.ndoc===auxCompany[1].replace(" ", "") && companyColdexCategory.categoryName===data.categoryNameF){
            dispatch(
              updateColdex({
                id: coldex !== undefined ? coldex.id : NUMBER_DEFAULT,
                coldexName: data.coldexNameF,
                coldexCountry: data.coldexCountryF,
                startDate: startDate,
                endDate: endDate,
                coldexYear: data.coldexYearF,
                description: data.descriptionF,
                status,
                coldexType,
                ndoc: coldex.ndoc,
                tdoc: coldex.tdoc,
                idColdexCategory:coldex?.idColdexCategory
              })
            );
          }else{
            dispatch(
            updateColdex({
                id: coldex !== undefined ? coldex.id : NUMBER_DEFAULT,
                coldexName: data.coldexNameF,
                coldexCountry: data.coldexCountryF,
                startDate: startDate,
                endDate: endDate,
                coldexYear: data.coldexYearF,
                description: data.descriptionF,
                status,
                coldexType,
                ndoc: auxCompany[1].replace(" ", "") === "null"?"null" : auxCompany[1].replace(" ", ""),
                tdoc: auxCompany[0].replace(" ", "") === "null"?"null" : auxCompany[0].replace(" ", ""),
                idColdexCategory:coldex?.idColdexCategory
              })
            )
            const uCompanyColdexCategory:CompanyColdexCategory={
              status: companyColdexCategory.status,
              categoryName: data.categoryNameF,
              tdoc: auxCompany[0].replace(" ", "") === "null"?"null" : auxCompany[0].replace(" ", ""),
              ndoc: auxCompany[1].replace(" ", "") === "null"?"null" : auxCompany[1].replace(" ", ""),
              id:companyColdexCategory.id!
            }
            dispatch(UpdateCompanyColdexCategory(coldex?.idColdexCategory!,uCompanyColdexCategory))
          }
        }else{
          dispatch(
            updateColdex({
              id: coldex !== undefined ? coldex.id : NUMBER_DEFAULT,
              coldexName: data.coldexNameF,
              coldexCountry: data.coldexCountryF,
              startDate: startDate,
              endDate: endDate,
              coldexYear: data.coldexYearF,
              description: data.descriptionF,
              status,
              coldexType,
            })
          );
        }
        if(setIsOpen !== undefined){
          setIsOpen(false);
        }
    };
 /**
  * Esta función maneja la eliminación de un de Coldex y cierra un modal.
  * @param e - React.MouseEvent<Element, MouseEvent> - Este es el tipo de objeto de evento que es
  * pasado a la función cuando se desencadena el evento. Es un evento del mouse que ocurre en un elemento.
  * en la aplicación React.
  */
  const handleDeleteColdex = (
    e: React.MouseEvent<Element, MouseEvent>
  ): void => {
    e.preventDefault();
    if(coldex !== undefined){
      dispatch(deleteColdex(coldex.id!))
      if(setIsOpen !== undefined){
        setIsOpen(false);
      }
    }
  };

   /**
   * Esta función actualiza el estado de un objeto Coldex y envía el objeto actualizado.
     * @param {Coldex} coldexUpdate - Es un objeto de tipo Coldex que contiene la actualización
     * información para un de Coldex. 
     * @param {string} statusUpdate: una cadena que representa el estado actualizado de un objeto Coldex.
    */
    const handleUpdateStateColdex = (coldexUpdate : Coldex, statusUpdate : string)=>{
      dispatch(updateColdex({...coldexUpdate, status: statusUpdate}))
     }
  return {handleUpdateStateColdex, handleCreateColdex,handleUpdateColdex,handleDeleteColdex,startDate, setStartDate,coldexName, setName,endDate, setEndDate,coldexYear, setYear,description, setDescription,coldexCountry, setCountry,status, setStatus,coldexType, setColdexType }
}
export default useFormActionsColdex;
