import { AnalyticsOutlined, Business, DynamicForm, Group, Home, Person, PollOutlined, QuestionAnswerOutlined, Settings, SourceOutlined, SupervisorAccountOutlined, Work, VideoLibrary, Image, InsertDriveFile, HelpCenter } from '@mui/icons-material'
import { ICON_QUESTION_ANSWER, TITLE_ADMIN, TITLE_ADMIN_SECTION_BUSINESS, TITLE_ADMIN_SECTION_REPORTS, TITLE_ADMIN_SECTION_RESOURCES, TITLE_ADMIN_SECTION_ROLES, TITLE_ADMIN_SECTION_USERS, TITLE_COLDEX, TITLE_COLDEX_SECTION_RED, TITLE_COLDEX_SECTION_SECTORIAL, TITLE_MAIN, TITLE_POLLS_COLDEX, TITLE_REPORTS, TITLE_SECTION_PADRINOS, TITLE_SECTION_USERS_COLDEX, ACTION_BUTTON_VIDEO, ACTION_BUTTON_IMAGES,ACTION_BUTTON_DOCUMENTS,ACTION_BUTTON_LINKS, TITLE_COLDEX_SECTION_PUBLICO } from '../../../utils/constants/text-constants'
import { Iicon } from '../../../utils/interfaces/app/ITitles'

/**
  * La función devuelve un componente de icono basado en la propiedad del icono de entrada.
  * @param {Iicon}: el código anterior define un componente funcional de React llamado "IconSelect" que toma un objeto como parámetro con una 
  * propiedad llamada "icono" de tipo  "Iicon". El componente devuelve un componente de icono basado en el valor de la propiedad "icono". 
  * La interfaz "Iicon" no se muestra en @returns Un componente que representa un icono en función del valor de la propiedad `icon` que se
  * le pasa.
 */
export const IconSelect = ({icon}: Iicon) => {
    return (
    <>
    {
        icon === TITLE_MAIN &&
        <Home fontSize='medium'/>
    }
    {
        icon === TITLE_ADMIN &&
        <Work fontSize='medium'/>
    }
    {
        icon === TITLE_COLDEX &&
        <DynamicForm fontSize="medium" />
    }
    {
        icon === TITLE_REPORTS &&
        <AnalyticsOutlined fontSize="medium" />
    }
    {
        icon === TITLE_ADMIN_SECTION_USERS  &&
        <Person fontSize="medium" />
    }
    {
        icon === TITLE_ADMIN_SECTION_BUSINESS &&
        <Business fontSize="medium" />
    }
    {
        icon === TITLE_ADMIN_SECTION_ROLES  &&
        <Group fontSize="medium" />
    }
    {
        icon === TITLE_ADMIN_SECTION_RESOURCES &&
        <SourceOutlined fontSize="medium" />
    }
    {
        icon?.includes(TITLE_COLDEX_SECTION_SECTORIAL) &&
        <Settings fontSize="medium" />
    }
    {
        icon?.includes(TITLE_COLDEX_SECTION_RED) &&
        <Settings fontSize="medium" />
    }
    {
        icon?.includes(TITLE_COLDEX_SECTION_PUBLICO) &&
        <Settings fontSize="medium" />
    }
    {
        icon === TITLE_ADMIN_SECTION_REPORTS &&
        <SourceOutlined fontSize="medium" />
    }
    {
        icon === TITLE_SECTION_PADRINOS &&
        <SupervisorAccountOutlined fontSize="medium" />
    }
    {
        icon === TITLE_SECTION_USERS_COLDEX &&
        <Group fontSize="medium" />
    }
    {
        icon === TITLE_POLLS_COLDEX &&
        <PollOutlined fontSize="medium" />
    }
    {
        icon === ICON_QUESTION_ANSWER &&
        <QuestionAnswerOutlined fontSize="medium" />
    }
    {
        icon === ACTION_BUTTON_VIDEO &&
        <VideoLibrary fontSize="medium" />
    }
    {
        icon === ACTION_BUTTON_IMAGES &&
        <Image fontSize="medium" />
    }
    {
        icon === ACTION_BUTTON_DOCUMENTS &&
        <InsertDriveFile fontSize="medium" />
    }
    {
        icon === ACTION_BUTTON_LINKS &&
        <HelpCenter fontSize="medium" />
    }
    
    
    </>
  )
}
