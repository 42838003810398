import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompanyState } from '../../../utils/interfaces/store/IStore';
import { Company } from '../../../utils/types/Company';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';

const initialState: CompanyState = {
    companiesList: [],
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}

export const companySlice = createSlice({
    name: 'Company',
    initialState,
    reducers:{
        startLoadingCompany: (state) =>{
            state.isLoading = true;
        },
        endLoadingCompany: (state) => {
            state.isLoading = false;
        },
        setCompanies:(state, {payload}:PayloadAction<IPayloadSetdata<Array<Company>>>)=>{
            state.dataError = false;
            state.isLoading = false;
            state.companiesList = payload.resultObject;
            state.resultMessage = payload.resultMessage!;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.shortMessage = payload.shorterror;
            state.open = payload.open!;
        },
        addCompanyLocal:(state,{payload}:PayloadAction<Company>)=>{
            state.companiesList.push(payload)
            state.isLoading = false;
          },
        updateCompanyLocal: (state, {payload}:PayloadAction<Company> ) => { 
            state.companiesList = state.companiesList.map( company => {
                if ( company.ndoc === payload.ndoc && company.tdoc===payload.tdoc ) {
                    return payload;
                }
                return company;
            });
            state.isLoading = false;
        },
    }
})

export const {startLoadingCompany, setCompanies,setError,addCompanyLocal,updateCompanyLocal, endLoadingCompany} = companySlice.actions

export default companySlice.reducer
