import { Close } from '@mui/icons-material'
import { ITitlePopUp } from '../../utils/interfaces/app/IPopUps'

/**
 * Este es un componente de TypeScript React que muestra un título emergente con un botón de cierre opcional.
 * @param {ITitlePopUp}  -`setIsOpen`: una función que establece el estado de si la ventana emergente está abierta
 * o cerrado
 */
export const PopUpTitle = ({setIsOpen,title}: ITitlePopUp) => {
  return (
    <>
    <div className="popUp-Content__Close">
      {setIsOpen!==undefined&& 
        <Close fontSize='medium'  onClick={() =>setIsOpen!(false)} color='disabled' />
      }
    </div>
    <div className="popUp-Content__title">
      <h3>{title}</h3>
    </div>
  </>
  )
}
