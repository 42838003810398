
import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { DATE_FORMAT } from '../../../utils/constants/dateFormat';
import { MESSAGE_ERROR_TIMEOUT, MESSAGE_ACTION_TYPE_CREATE, MESSAGE_CONTEXT_USERXCOLDEX, MESSAGE_ACTION_TYPE_GET, MESSAGE_ACTION_TYPE_DELETE, MESSAGE_ACTION_TYPE_UPDATE } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { ColdexUser } from '../../../utils/types/Coldex';
import { UserColdex } from '../../../utils/types/UserColdex';
import {  startUserxColdexLoading,setUserxColdex,setError,endLoading, setColdexxUser,addUserxColdexLocal,deleteUserxColdexLocal,updateUserxColdexLocal} from './userxColdexSlice';


/**
  * La función `postUsersxColdex` postea una lista de usuarios en ColdexAPI y envía acciones según el resultado.
  * @param users: una matriz de objetos de tipo `UserColdex` que contiene información sobre los usuarios en una prueba de Coldex. Esta matriz se pasa como argumento a la función `postUsersxColdex`, que es responsable de realizar una solicitud posterior a ColdexAPI para agregar usuarios a la prueba.
  * @returns Una función que toma una matriz de objetos `UserColdex` y devuelve una función asíncrona que envía acciones para comenzar a cargar usuarios, realizar una solicitud posterior a ColdexAPI y enviar un error o agregar el primer usuario en la matriz al estado local dependiendo de la respuesta de la API.
  */
export const postUsersxColdex = (users:Array<UserColdex>) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try {
            const { data } = await ColdexAPI.post<IApiResult<Array<UserColdex>>>(`adminColdex/UserxColdex/ListUserxColdex`,users);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
            }else{
                dispatch(addUserxColdexLocal(users[0]))
                dispatch(endLoading())
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }    
/**
 * This function posts a user's answer to a Coldex test and dispatches actions based on the result.
 * @param {UserAnswerxColdex} userAnswerxColdex - The parameter `userAnswerxColdex` is an object of
 * type `UserAnswerxColdex` which contains information about a user's answer to a question in a Coldex
 * test. This object is passed as an argument to the function `postUserAnswerxColdex` which is
 * responsible for
 * @returns A function that takes in a userAnswerxColdex object and returns an async function that
 * dispatches actions to start loading userAnswerxColdex, make a post request to the ColdexAPI, and
 * either dispatch an error or add the userAnswerxColdex to the local state depending on the response
 * from the API.
 */
}

/**
  * Esta es una función que recupera datos de usuario que participan en el coldex y envía acciones basadas en el resultado.
  * @param {number} idcoldex - El parámetro `idcoldex` es un número que representa la ID de un objeto Coldex. Se usa en la llamada API para recuperar una lista de usuarios asociados con ese objeto Coldex.
  * @returns La función `getUsers` está devolviendo una función que toma un parámetro `dispatch` y devuelve una Promesa. La Promesa resuelve enviar la acción `startUserxColdexLoading`, enviar la acción `setError` con un mensaje de error si hay un error en la llamada API, o enviar la acción `setUserxColdex` con el objeto de resultado si la llamada API es exitosa
  */
export const getUsers = (idcoldex:number) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<UserColdex>>>(`adminColdex/UserxColdex/${idcoldex}`);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));

            }else{
                dispatch(setUserxColdex({resultObject:data.resultObject}));
            }
         
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }    
}

/**
  * Esta es una función de TypeScript React que recupera los Coldex por correo electrónico  de usuarioy envía acciones
  * basado en el resultado.
  * @param {string} email: el parámetro email es una cadena que representa la dirección de correo electrónico de un usuario.
  * Esta función se utiliza para recuperar información sobre los Coldex en función de su dirección de correo electrónico.
  * @returns Una función que toma un correo electrónico como parámetro y devuelve una función asíncrona que
  * envía acciones para comenzar a cargar un usuario desde ColdexAPI y luego establece resultObject
  * del usuario o establece un error si hay un tiempo de espera u otro error.
  */
export const getColdexbyEmail = (email:string) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<ColdexUser>>>(`adminColdex/UserxColdex/coldex/${email}?Date=${new Date().toLocaleString(DATE_FORMAT).split(" ")[0]}`);
            dispatch(setColdexxUser({resultObject:data.resultObject}));
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }    
}

/**
  * Esta es una función de TypeScript React que elimina a un usuario de un coldex y envía acciones según el resultado.
  * @param {UserColdex} user: el parámetro de usuario es un objeto de tipo UserColdex, que contiene información sobre un usuario en el sistema ColdexAPI.
  * @returns La función `deleteUser` devuelve una función que toma un parámetro `dispatch` y devuelve una Promesa. La Promesa resuelve enviar las acciones `deleteUserxColdexLocal` y `endLoading` si la llamada a la API tiene éxito, o enviar una acción `setError` si hay un error.
  */
export const deleteUser = (user:UserColdex) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try { 
            const { data } = await ColdexAPI.delete<IApiResult<Array<UserColdex>>>(`adminColdex/UserxColdex`,{data:user});
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
            }else{
                dispatch(deleteUserxColdexLocal(user.email!))
                dispatch(endLoading())
            }
                    
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }    
}

/**
  * Esta es una función de TypeScript React que elimina un usuario por correo electrónico mediante una llamada a la API y envía acciones para actualizar el estado en consecuencia.
  * @param {string} email: el correo electrónico del usuario que debe eliminarse del sistema Coldex.
  * @returns Una función que toma un parámetro de cadena y devuelve una función asincrónica que envía acciones para comenzar a cargar, eliminar un usuario por correo electrónico mediante una llamada a la API y envía acciones para eliminar el usuario localmente y finalizar la carga. Si hay un error, envía una acción para establecer un mensaje de error.
  */
export const deleteUserByEmail = (email:string) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try {
            
            const { data } = await ColdexAPI.delete<IApiResult<Array<UserColdex>>>(`adminColdex/UserxColdex/${email}`);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
            }else{
                dispatch(deleteUserxColdexLocal(email))
                dispatch(endLoading())
            }
                    
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }    
}

/**
  * Esta es una función TypeScript React que actualiza a un usuario y envía acciones
  * basado en el resultado.
  * @param {UserColdex} user: el objeto de usuario que debe actualizarse en la base de datos.
  * @returns La función `updateUser` devuelve una función que toma un parámetro `dispatch` y
  * devuelve una Promesa. La Promesa resuelve enviar `updateUserxColdexLocal` o se encarga de enviar una acción `setError` si hay un error.
  */
export const updateUser=(user:UserColdex)=>{
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startUserxColdexLoading() );
        try {
            const { data } = await ColdexAPI.put<IApiResult<Array<UserColdex>>>(`adminColdex/UserxColdex/${user.email!.replaceAll('@','%40')}`,user);
            
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
            }else{
                dispatch(updateUserxColdexLocal(user))
                dispatch(endLoading());
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERXCOLDEX}`, open: true }));
        }
    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}