import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import { MESSAGE_ERROR_TIMEOUT,MESSAGE_CONTEXT_USERS,MESSAGE_ACTION_TYPE_CREATE,MESSAGE_ACTION_TYPE_DELETE,MESSAGE_ACTION_TYPE_UPDATE,MESSAGE_ACTION_TYPE_GET } from "../../../utils/constants/message-constants";
import { User } from "../../../utils/types/User";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import {
  setUsers,
  startLoadingUsers,
  setError,
  endLoadingUsers,
  setUser,
  startLoadingUser,
  deleteUserLocal,
  addUserLocal,
  updateUserLocal
} from "./adminUsersSlice";

/**
 * Esta función recupera una lista de usuarios de una API y envía acciones basadas en el éxito o
  * fracaso de la solicitud.
  * @returns Una función que devuelve una función asíncrona que toma una función de despacho como un
  * argumento. La función interna realiza una llamada a la API para recuperar una lista de usuarios y envía acciones
  * basado en el resultado.
 */
export const getUsers = () => {
  return async (dispatch:  Dispatch<Action>) => {
    dispatch(startLoadingUsers());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<User>>>(`adminUser/User/List`);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,
            open: true,
          })
        );
      } else {
        dispatch(setUsers({ resultObject: data.resultObject }));
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERS}`,
          iserror: true,
          shorterror: MESSAGE_ERROR_TIMEOUT,
          open: true,
        })
      );
    }
  };
};

/**
* Esta es una función de TypeScript React que recupera a un usuario por correo electrónico usando una llamada API y envía
  * acciones basadas en el resultado.
  * @param {string} email: el parámetro email es una cadena que representa la dirección de correo electrónico de un usuario.
  * Esta función está diseñada para recuperar datos de usuario de una API en función de su dirección de correo electrónico.
  * @returns Una función que toma una cadena de correo electrónico como entrada y devuelve una función asíncrona que
  * toma una función de envío como entrada. La función interna realiza una llamada a la API para recuperar un objeto de usuario
  * según el correo electrónico proporcionado y envía acciones para actualizar el estado según el resultado de la
  * Llamada API.
 */
export const getUserxEmail = (email: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingUser());
    try {
      const { data } = await ColdexAPI.get<IApiResult<User>>(`adminUser/User/Email/${email}`);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,
            open: true,
          })
        );
      } else {
        dispatch(setUser({resultObject:data.resultObject}));
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERS}`,
          iserror: true,
          shorterror: MESSAGE_ERROR_TIMEOUT,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que elimina a un usuario con una dirección de correo electrónico dada y envía
  * acciones para actualizar el estado en consecuencia.
  * @param {string} email: el parámetro email es una cadena que representa la dirección de correo electrónico del
  * usuario que necesita ser eliminado.
  * @returns La función `deleteUser` devuelve una función que toma un argumento `dispatch` y devuelve un
  * Promesa que se resuelve en `data2`, objeto ApiResult con lo que retorna el API.
 */
export const deleteUser = (email: string) => {
  return async (dispatch: /*Dispatch<Action>*/any) => {
    dispatch(startLoadingUsers());
    try {
      const { data } = await ColdexAPI.delete<IApiResult<User>>(`adminUser/User/${email}`);
      let data2 = data;
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_USERS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,
            open: true,
          })
        );
      } else {
        dispatch( deleteUserLocal(email))
      }
    return data2;
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_USERS}`,
          iserror: true,
          shorterror: MESSAGE_ERROR_TIMEOUT,
          open: true,
        })
      );
    }
  };
};

/**
 * Esta función crea un nuevo usuario y envía una acción para actualizar el estado con la información del nuevo usuario.
  * @param {Usuario} user: el objeto de usuario que contiene la información del usuario que se creará.
  * @returns La función `CreateUser` está devolviendo una función asíncrona que toma un `despacho`
  * Funciona como un argumento. La función devuelta envía acciones para actualizar el estado del
  * aplicación relacionada con la creación de un nuevo usuario. También hace una llamada a la API para crear un nuevo usuario y
  * Despacha acciones basadas en la respuesta de la API.
 */
export const CreateUser = (user: User) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingUsers());
    try {
      const { data } = await ColdexAPI.post<IApiResult<User>>(`adminUser/User`, user);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,
            open: true,
          })
        );
      } else {
        dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERS}`, iserror: false ,shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,open:true}));
        dispatch(addUserLocal(user))
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERS}`,
          iserror: true,
          shorterror: MESSAGE_ERROR_TIMEOUT,
          open: true,
        })
      );
    }
  };
};

/**
 * Esta es una función que actualiza la información de un usuario y envía acciones para manejar la carga y
  * estados de error.
  * @param {User} user: el objeto de usuario que debe actualizarse.
  * @returns La función `UpdateUser` devuelve una función asíncrona que toma una función `dispatch`
  * como argumento.
 */
export const UpdateUser = (user: User,isPasswordEncrypted:boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingUsers());
    try {
      const { data } = await ColdexAPI.put<IApiResult<User>>(`adminUser/User/${user.id}?isPasswordEncrypted=${isPasswordEncrypted}`, user);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_USERS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,
            open: true,
          })
        );
      } else {
        dispatch(updateUserLocal(user))
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_USERS}`,
          iserror: true,
          shorterror: MESSAGE_ERROR_TIMEOUT,
          open: true,
        })
      );
    }
    dispatch(endLoadingUsers())
  };
};

/**
 * Esta es una función que crea usuarios en lotes y envía acciones de carga y error
  * respectivamente.
  * @param users: un array de objetos User que se crearán en el backend.
  * @returns Una función que toma una matriz de usuarios como entrada y devuelve una función asíncrona que
  * envía acciones para comenzar a cargar usuarios, crear usuarios publicándolos en un punto final de API y finalizar
  * carga de usuarios. Si hay un error durante el proceso, envía una acción de error.
 */
export const CreateUsers = (users: Array<User>) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingUsers());
    try {
      let count: number = 0;
      let usersFragment : Array<Array<User>> = segmentUserList(users)
       usersFragment.forEach(async userFragment => {
           const { data } = await ColdexAPI.post<IApiResult<number>>(`adminUser/User/users`, userFragment);
           if(data.resultObject !== 0 ){
             count++
           }
           if(count === userFragment.length){
             if (data.dataError) {
               dispatch(
                 setError({
                   error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERS}`,
                   iserror: true,
                   shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERS}`,
                   open: true,
                 })
               );
             } else {
               dispatch(endLoadingUsers());
             }
           }
       })
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERS}`,
          iserror: true,
          shorterror: MESSAGE_ERROR_TIMEOUT,
          open: true,
        })
      );
    }
  };
};

/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};

/**
 * La función segmenta un array dado de usuarios en arrays más pequeños de un tamaño específico.
  * @param userColdex: un array de objetos de usuario que debe segmentarse en arrays más pequeñas.
  * @devuelve un array de arrays, donde cada array interno contiene un segmento del array `userColdex` original
  * con una longitud máxima de 50.
 */
function segmentUserList(userColdex : Array<User>){
    const fragmentCount = 50
    let usersArraysList : Array<Array<User>> = []
    for (let index = 0; index < userColdex.length; index += fragmentCount) {
      let userColdexArray: Array<User> = userColdex.slice(index, index+fragmentCount)
      usersArraysList.push(userColdexArray)
    }
  return usersArraysList
}


