import React from 'react';
import { SpinnerCircular } from 'spinners-react';
import { useAppSelector } from '../../../../store/Hooks';
import { RootState } from '../../../../store/Store';
import { COLOR_SPINNER_LOADER_STADISTICS, TITLE_COLDEX_SECTION_SECTORIAL } from '../../../../utils/constants/text-constants';
import {  IStadistics } from '../../../../utils/interfaces/app/IPopUps';
import './stadistics.scss'
/**  El código anterior es un componente funcional de React que genera un conjunto de estadísticas relacionadas con un Coldex
 * sistema. El componente recibe varios apoyos como `loadStadisticCompany`, `loadStadisticSector`,
 * `usersColdex`, `loadStadisticColdex`, `dataColdex`, `padrinoxColdex` y `type`. utiliza el
 * Gancho `useAppSelector` para acceder al estado de la tienda Redux y mostrar los giradores de carga mientras el
 * se están extrayendo datos. El componente representa las estadísticas en un conjunto de elementos `div` con sus
 * etiquetas y valores correspondientes */
export const Stadistics = React.memo(({loadStadisticCompany,loadStadisticSector,usersColdex,loadStadisticColdex,dataColdex, padrinoxColdex, type}: IStadistics) => {
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
    const { isLoading: loadinguserxcoldex } = useAppSelector(
        (state: RootState) => state.userxcoldex
      );
      const { isLoading: loadinguser } = useAppSelector(
        (state: RootState) => state.loginuser
      );
      const { isLoading: loadinguserxcoldexxsecurity } = useAppSelector(
        (state: RootState) => state.userxsecurityxcoldex
      );

        return (
        <>
          <div className="stadistictemplate">
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadStadisticSector && !loadStadisticCompany && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_SECTORS}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticSector
                    ? dataColdex.sectores
                    : "-"}
                </span>
              </div>
            </div>
            {type === TITLE_COLDEX_SECTION_SECTORIAL&& (
              <div className="stadistictemplate__stadistic">
                <div className="stadistictemplate__stadistic_name">
                  {loadStadisticCompany && !loadStadisticSector && (
                    <SpinnerCircular
                      size={24}
                      color={COLOR_SPINNER_LOADER_STADISTICS}
                      thickness={180}
                    />
                  )}
                  <span>{language.components.forms.file.stadistics.labels.LABEL_COMPANY}</span>
                </div>
                <div className="stadistictemplate__stadistic_resultS">
                  <span>
                    {dataColdex !== undefined && !loadStadisticCompany
                      ? dataColdex.compañiasxsector
                      : "-"}
                  </span>
                </div>
              </div>
            )}
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadStadisticColdex && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_DIMENSIONS}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticColdex
                    ? dataColdex.dimensiones
                    : "-"}
                </span>
              </div>
            </div>
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadStadisticColdex && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_THEME}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticColdex
                    ? dataColdex.tematicas
                    : "-"}
                </span>
              </div>
            </div>
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadStadisticColdex && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_QUESTIONS}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticColdex
                    ? dataColdex.preguntas
                    : "-"}
                </span>
              </div>
            </div>
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadStadisticColdex && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_STATEMENT}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticColdex
                    ? dataColdex.enunciados
                    : "-"}
                </span>
              </div>
            </div>
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadinguser && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                {loadinguserxcoldex && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                {loadinguserxcoldexxsecurity && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_USERCOLDEX}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticColdex
                    ? usersColdex.length
                    : "-"}
                </span>
              </div>
            </div>
            <div className="stadistictemplate__stadistic">
              <div className="stadistictemplate__stadistic_name">
                {loadinguser && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                {loadinguserxcoldex && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                {loadinguserxcoldexxsecurity && (
                  <SpinnerCircular
                    size={24}
                    color={COLOR_SPINNER_LOADER_STADISTICS}
                    thickness={180}
                  />
                )}
                <span>{language.components.forms.file.stadistics.labels.LABEL_PADRINOCOLDEX}</span>
              </div>
              <div className="stadistictemplate__stadistic_resultS">
                <span>
                  {dataColdex !== undefined && !loadStadisticColdex
                    ? padrinoxColdex.length
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </>
  )
})
