import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";

import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_ACTION_TYPE_UPDATE,
  MESSAGE_CONTEXT_RESOURCES,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";

import { Section } from "../../../utils/types/Section";
import { startLoadingSection, setSection, setError, updateSectionLocal } from "./sectionSlice";

/**
  * Esta es una función que recupera datos de las secciones y envía acciones en función del resultado.
  * @returns Una función que devuelve una función asincrónica que toma un parámetro de envío y envía acciones según el resultado de una llamada a la API.
  */
export const getSection = () => {
  return async (dispatch: Dispatch<Action> | any) => {
    dispatch(startLoadingSection());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<Section>>>("/adminresources/api/Section");
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setSection({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_RESOURCES}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta función crea una nueva sección y envía acciones para actualizar el estado en consecuencia.
  * @param {Section} section: el objeto de sección que se creará y enviará al extremo de la API.
  * @returns Una función que toma una función de despacho como argumento y devuelve una Promesa.
  */
export const createSection = (section: Section) => {
  return async (dispatch: Dispatch<Action> | any) => {
    dispatch(startLoadingSection());
    try {
      const { data } = await ColdexAPI.post<IApiResult<Array<Section>>>("/adminresources/api/Section", section);
      if (data.dataError) {
        dispatch(setError({ iserror: true, error: data.resultMessage,shorterror:data.shortMessage }));
      } else {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: false,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
        dispatch(getSection());
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que actualiza una sección y envía una acción para actualizar el estado en consecuencia.
  * @param {Section} section - Una variable de tipo Section, que es un objeto que contiene información sobre una sección.
  * @returns Una función que toma un objeto de "sección" como entrada y devuelve una función asíncrona que toma una función de "dispatch" como entrada. La función devuelta envía acciones para actualizar una sección en el estado de la aplicación y manejar cualquier error que pueda ocurrir durante el proceso de actualización.
  */
export const updateSection = (section: Section) => {
  return async (dispatch: Dispatch<Action> | any) => {
    dispatch(startLoadingSection());
    try {
      const { data } = await ColdexAPI.put<IApiResult<Array<Section>>>(
        `/adminresources/api/Section/${section.sectionID}`,
        section
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      } else {
         dispatch(updateSectionLocal(section))
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
          open: true,
        })
      );
    }
  };
};

/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
