import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISectionState } from "../../../utils/interfaces/store/IStore";
import { Section } from "../../../utils/types/Section";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";

const initialState: ISectionState = {
  sections: [],
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false
};

export const sectionSlice = createSlice({
  name: 'Section',
  initialState,
  reducers: {
    startLoadingSection: (state ) => {
      state.isLoading = true;
    },
    setSection: (state, {payload}:PayloadAction<IPayloadSetdata<Array<Section>>>) => {
      state.dataError = false;
      state.isLoading = false;
      state.sections = payload.resultObject;
      state.resultMessage = payload.resultMessage!;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.dataError = payload.iserror;
      state.isLoading = false;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open =payload.open!;
    },
    updateSectionLocal: (state, {payload}:PayloadAction<Section> ) => { 
      state.sections = state.sections.map( section => {
          if ( section.sectionID === payload.sectionID ) {
              return payload;
          }
          return section;
      });
      state.isLoading = false
    }
  },
});

export const { startLoadingSection, setSection, setError, updateSectionLocal} =
  sectionSlice.actions;
export default sectionSlice.reducer;
