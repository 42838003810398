import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRolState } from '../../../utils/interfaces/store/IStore';
import { Role } from '../../../utils/types/Role';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';


const initialState:IRolState  = {
    rolsList: [],
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}

export const rolSlice = createSlice({
    name: 'rol',
    initialState,
    reducers:{
        startLoadingRol: (state) =>{
            state.isLoading = true;
        },
        setRols:(state, {payload}:PayloadAction<IPayloadSetdata<Array<Role>>>)=>{
            state.dataError = false;
            state.isLoading = false;
            state.rolsList =payload.resultObject;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.dataError = payload.iserror;
            state.isLoading = false;
            state.resultMessage =payload.error;
            state.shortMessage = payload.shorterror;
            state.open =payload.open!;
        }
    }
})

export const {startLoadingRol, setRols,setError} = rolSlice.actions

export default rolSlice.reducer
