import { useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import {
  TITLE_COLDEX_SECTION_PUBLICO,
  TITLE_COLDEX_SECTION_RED,
  TITLE_COLDEX_SECTION_SECTORIAL
} from "../../../utils/constants/text-constants";
import { IPopUpColdex } from "../../../utils/interfaces/app/IPopUps";
import { UploadFileForm } from "../../Forms/UploadFileForm/UploadFileForm";
import { PopUpTitle } from "../../Titles/PopUpTitle";

/**  Este es un componente funcional llamado `UploadTemplatePopUp` que toma un objeto con cuatro propiedades 
 * (`isOpen`, `setIsOpen`, `coldex` y `type`) que coinciden con la interfaz `IPopUpColdex`. Devuelve JSX que 
 * muestra una ventana emergente con un título y un formulario para cargar un archivo. El título y el formulario 
 * que se muestran dependen de la propiedad `type` pasada. El hook `useAppSelector` se utiliza para recuperar 
 * la propiedad `idioma` de la porción `multiLanguage` de la tienda Redux. 
 * */

export const UploadTemplatePopUp = ({
  isOpen,
  setIsOpen,
  coldex,
  type,
}: IPopUpColdex) => {
  const {language} = useAppSelector((state:RootState) => state.multiLanguage);
  return (
    <div className={isOpen ? "open-PopUp" : "close-PopUp"}>
      <div className="popUp-Content">
        {type === TITLE_COLDEX_SECTION_RED && (
          <>
            <PopUpTitle
              setIsOpen={setIsOpen}
              title={`${language.components.popUps.uploadTemplate.titles.TITLE_FORM_UPLOAD_TEMPLATE} ${language.components.popUps.uploadTemplate.titles.TITLE_COLDEX_SECTION_RED}`}
            ></PopUpTitle>
            <UploadFileForm
              isOpen={false}
              setIsOpen={setIsOpen}
              coldex={coldex}
              type={TITLE_COLDEX_SECTION_RED}
            ></UploadFileForm>
          </>
        )}
        {type === TITLE_COLDEX_SECTION_SECTORIAL && (
          <>
            <PopUpTitle
              setIsOpen={setIsOpen}
              title={`${language.components.popUps.uploadTemplate.titles.TITLE_FORM_UPLOAD_TEMPLATE} ${language.components.popUps.uploadTemplate.titles.TITLE_COLDEX_SECTION_SECTORIAL}`}
            ></PopUpTitle>
            <UploadFileForm
              isOpen={false}
              setIsOpen={setIsOpen}
              coldex={coldex}
              type={TITLE_COLDEX_SECTION_SECTORIAL}
            ></UploadFileForm>
          </>
        )}
         {type === TITLE_COLDEX_SECTION_PUBLICO && (
          <>
            <PopUpTitle
              setIsOpen={setIsOpen}
              title={`${language.components.popUps.uploadTemplate.titles.TITLE_FORM_UPLOAD_TEMPLATE} ${language.components.popUps.uploadTemplate.titles.TITLE_COLDEX_SECTION_PUBLICO}`}
            ></PopUpTitle>
            <UploadFileForm
              isOpen={false}
              setIsOpen={setIsOpen}
              coldex={coldex}
              type={TITLE_COLDEX_SECTION_PUBLICO}
            ></UploadFileForm>
          </>
        )}
      </div>
    </div>
  );
};
