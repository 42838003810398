import { createSlice } from "@reduxjs/toolkit";
import { IMonotoringReport } from "../../../utils/interfaces/store/IStore";

const initialState: IMonotoringReport = {
  company_by_evaluator: null,
  evaluator_position: null,
  evaluator_company: null,
  company_to: null,
  company_from: null,
  is_loading: true,
  category: null,
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setMonitoringReports: (state,{payload}) => {
      state.company_by_evaluator = payload.company_by_evaluator;
      state.evaluator_company = payload.evaluator_company;
      state.evaluator_position = payload.evaluator_position;
      state.is_loading = false;
      state.category = payload.category;
      state.company_to = payload.company_to;
      state.company_from = payload.company_from;
    },
  },
});

export const { setMonitoringReports } = reportSlice.actions;

export default reportSlice.reducer;
