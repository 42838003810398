
import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_GET, MESSAGE_CONTEXT_GETTEMPLATE, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { startTempleteLoading, setTempleteFile,setError } from './templeteSlice';



/**
 * This is a TypeScript React function that retrieves a file template from an API and dispatches
 * actions based on the success or failure of the request.
 * @param {string} nameFile - A string representing the name of the file to be downloaded.
 * @returns A function that takes a string parameter and returns an asynchronous function that
 * dispatches actions to start loading a template file, make an API call to download the file, and set
 * the downloaded file as a result object. If there is an error, it dispatches actions to set an error
 * message and display an error dialog.
 */
export const getTemplete = (nameFile:string) => {
    return async( dispatch:Dispatch<Action> ) => {
        dispatch( startTempleteLoading() );
        try {
            const { data } = await ColdexAPI.get(`adminColdex/File/DownloadFile?nameFile=${nameFile}`, {responseType:"blob"}).then().catch().finally();
            const url = window.URL.createObjectURL(new Blob([data]));
            dispatch( setTempleteFile({resultObject:url}));
        } catch (error) {
            dispatch(setError({iserror:true,error:MESSAGE_ERROR_TIMEOUT, shorterror:""}))
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_GETTEMPLATE}`, iserror: false, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_GETTEMPLATE}`, open: true }));

        }
    }    
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror:"" }));
    }
}