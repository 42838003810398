import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT_POLLXCOLDEX,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { Generic, IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { ColdexConfig, ColdexConfigPreview } from "../../../utils/types/ColdexConfig";
import { IColdexSecurity } from "../../../utils/types/UserColdex";
import {
  startLoadingPollxColdexConfig,
  setPollxColdexConfig,
  setInfo,
  setError,
} from "./pollxColdexConfig";

/**
  * Esta función recupera datos de configuración de todos los Coldex de una API y envía acciones basadas en el resultado.
  * @returns Una función que devuelve una Promesa que envía acciones para comenzar a cargar la configuración de PollxColdex, realiza una llamada API para recuperar datos y envía una acción para configurar los datos recuperados o una acción de error si hay un error.
  */
export const getColdex = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingPollxColdexConfig());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<ColdexConfig>>>("adminColdex/PollxColdex");
      if (data.dataError) {
        dispatch(
          setError({
            error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            iserror: true,
            shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setPollxColdexConfig({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que recupera datos de configuración de Coldex desde un extremo de la API y envía acciones en función del resultado.
  * @param {number} Idcoldex - El parámetro `Idcoldex` es un número que representa la ID del Coldex. Esta función se usa para obtener la información de configuración de Coldex desde un extremo de la API usando esta ID.
  * @returns Una función que toma un parámetro numérico y devuelve una función asíncrona que toma un parámetro de envío y envía acciones según el resultado de una llamada a la API.
  */
export const getColdexConfig = (Idcoldex: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingPollxColdexConfig());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<ColdexConfig>>>(
        "adminColdex/PollxColdex/ColdexInfo/" + Idcoldex
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            iserror: true,
            shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setPollxColdexConfig({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función que crea una configuración de Coldex enviando los datos al API y envía acciones basadas en el éxito o fracaso de la llamada a la API.
  * @param coldexConfig: un array de objetos ColdexConfig que se enviarán al servidor para crear una nueva configuración.
  * @returns Una función que toma una matriz de objetos ColdexConfig como parámetro y devuelve una función asíncrona que toma una función de envío como parámetro. La función asíncrona envía un POST solicitud a un punto final de API específico con la matriz ColdexConfig provista y envía un error o una acción exitosa según la respuesta recibida.
  */
export const CreateColdexConfig = (coldexConfig: Array<ColdexConfig>) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await ColdexAPI.post<IApiResult<Array<ColdexConfig>>>(
        `adminColdex/PollxColdex/`,
        coldexConfig
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setPollxColdexConfig({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que recupera datos de un punto final de API y envía un error o un objeto de resultado a la tienda de Redux.
  * @param {ColdexConfigPreview} coldexConfig: es un objeto que contiene parámetros de configuración para la encuesta de Coldex.
  * @returns Una función que toma un objeto `coldexConfig` como entrada y devuelve una función asíncrona que distribuye acciones basadas en el resultado de una llamada API.
  */
export const getColdexxPoll = (coldexConfig: ColdexConfigPreview) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await ColdexAPI.get<IApiResult<Generic>>(
        `adminColdex/PollxColdex/coldexConfig?IdColdex=${coldexConfig.IdColdex}&IdPoll=${coldexConfig.IdPoll}&IDCategory=${coldexConfig.IDCategory}&PollLevel=${coldexConfig.PollLevel}&IsRoot=1&IDType=${coldexConfig.IDType}`
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setInfo({ resultObject: JSON.parse(data.resultObject.response) })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que recupera la información de seguridad de Coldex de un usuario y envía una acción basada en el resultado.
  * @param {IColdexSecurity} coldexSecurity: un objeto que contiene las siguientes propiedades: idColdex (string), idPoll (string), idCategory (string), idType (string) y correo electrónico (string). Estas propiedades se utilizan para construir una URL para una llamada a la API.
  * @returns Una función que toma un objeto `coldexSecurity` y devuelve una función asincrónica que envía acciones basadas en el resultado de una solicitud GET a un extremo de ColdexAPI.
  */
export const getColdexForUser = (coldexSecurity: IColdexSecurity) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await ColdexAPI.get(
        `adminColdex/PollxColdex/Security?IdColdex=${coldexSecurity.idColdex}&IdPoll=${coldexSecurity.idPoll}&IdCategory=${coldexSecurity.idCategory}&IdType=${coldexSecurity.idType}&Email=${coldexSecurity.email}`
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
            open: true,
          })
        );
      } else {
        if (data.resultObject.response !== "]") {
          dispatch(
            setPollxColdexConfig({
              resultObject: JSON.parse(data.resultObject.response),
            })
          );
        } else {
          dispatch(
            setError({
              error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX} No se encontro relacion de usuario.`,
              iserror: true,
              shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
              open: true,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_POLLXCOLDEX}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
