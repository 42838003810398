import { useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import useFormActionsColdex from "../../../utils/hooks/coldex/useFormActionsColdex";
import { IPopUpColdex } from "../../../utils/interfaces/app/IPopUps";

import "../popUps.scss";

/**
 * Este es un componente de TypeScript React que muestra una ventana emergente para eliminar un 
 * "coldex".
 * @param {IPopUpColdex}  - - `isOpen`: un valor booleano que indica si la ventana emergente está 
 * actualmente abierta o cerrada
 * @returns Este código exporta un componente funcional de React llamado `DeleteColdexPopUp` que 
 * devuelve un elemento JSX. El elemento JSX contiene un div con un nombre de clase determinado 
 * por la propiedad `isOpen` y contiene varios elementos secundarios, incluidos un título, 
 * un formulario y dos botones.
 */
export const DeleteColdexPopUp = ({
  isOpen,
  setIsOpen,
  coldex,
}: IPopUpColdex) => {
  const { handleDeleteColdex } = useFormActionsColdex(setIsOpen, coldex);
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  return (
    <div className={isOpen ? "open-PopUp" : "close-PopUp"}>
      <div className="popUp-Content">
        <div className="popUp-Content__title">
          <h3>{language.components.popUps.coldex.titles.TITLE_FORM_DELETE_COLDEX}</h3>
        </div>
        <div className="popUp-Content__form">
          <p> {language.components.popUps.coldex.descriptions.DESCRIPTION_DELETE_COLDEX} <b>{coldex?.coldexName}</b>?
          </p>
        </div>
        <div className="popUp-Content__actions">
          <button className="btn_OutLine" onClick={(e) => setIsOpen(false)}>
            {language.components.popUps.buttons.BTN_FORM_CANCEL}
           
          </button>
          <button onClick={(e) => handleDeleteColdex(e)}> {language.components.popUps.buttons.BTN_CONTINUE}</button>
        </div>
      </div>
      <div>
        <div></div>
      </div>
    </div>
  );
};
