import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IParametersState,
  Parameters,
} from "../../../utils/interfaces/store/IStore";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";

const initialState: IParametersState = {
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
  parameters:{
    parametersFormUser: [],
  parametersTypeRole: [],
  parameterCompanyType: [],
  parameterCompanyTDOC: [],
  parametersColdexType: [],
  parameterSectionType: [],
  parameterResourcesForm: [],
  parameterResourcesType: [],
  parameterInconsType: [],
  }
};

export const parametersSlice = createSlice({
  name: "Parameters",
  initialState,
  reducers: {
    startParametersLoading: (state) => {
      state.isLoading = true;
    },
    setParameters: (state,  { payload }: PayloadAction<IPayloadSetdata<Parameters>>) => {
      state.isLoading = false;
      state.parameters = payload.resultObject;
      state.resultMessage = payload.resultMessage!;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading = false;
      state.resultMessage = payload.error;
      state.dataError = payload.iserror;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
  },
});

// Action creators are generated for each case reducer function
export const { startParametersLoading, setParameters, setError } =
  parametersSlice.actions;

export default parametersSlice.reducer;
