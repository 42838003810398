import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import { OPTION_COMPANY_DEFAULT } from "../../constants/text-constants";
import { Company } from "../../types/Company";

/**
 * Este es un Custom hook en TypeScript que recupera una lista de empresas del estado de la aplicación 
 * y agrega una opción predeterminada al comienzo de la lista.
 * @returns devuelve un objeto con una sola propiedad `companies`, que es una
 *  matriz de objetos `Company`. La matriz `companies` se obtiene de la tienda Redux usando el `useAppSelector` 
 * y se actualiza cada vez que cambia el estado `companiesList`. La matriz `companies` también se modifica para
 *  incluir un objeto de empresa predeterminado al comienzo de la matriz.
 */
const useCompanies = () =>{
    const { companiesList} = useAppSelector((state: RootState) => state.company);
    const [companies, setCompanies] = useState<Array<Company>>();

    useEffect(() => {
    if(companiesList.length > 0){
        const companiesAux : Array<Company> = [...companiesList]
        companiesAux.unshift({tdoc:null,ndoc:null,companyName:OPTION_COMPANY_DEFAULT,companyType:"",state:false})
        setCompanies(companiesAux)
      }  
    }, [companiesList])
    return {companies};
}


export default useCompanies;