import { AxiosInstance } from "axios";

export const axiosInterceptorHeaders = (api: AxiosInstance) => {
  api.interceptors.request.use(
    (req) => {
      if (req.headers !== undefined) {
        req.headers["Access-Control-Allow-Origin"] = "*";
        if (
          req.url === "auth/Account" ||
          req.url === "emails/Mail/UpdateTemplatePassword" ||
          req.url === "emails/Mail/UpdateTemplate"
        ) {
        } else {
          if (localStorage.getItem("token") !== undefined) {
            req.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
          }
        }
      }
      return req;
    },
    (err) => {
      return Promise.reject(err)
    }
  );

  api.interceptors.response.use(response=>{
    return response 
  }, async error=>{  
    return Promise.reject(error)
  })
};

