import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_ACTION_TYPE_GET, MESSAGE_ACTION_TYPE_UPDATE, MESSAGE_CONTEXT_COLDEX, MESSAGE_CONTEXT_COMPANY, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { Company } from '../../../utils/types/Company';
import {startLoadingCompany, setCompanies, setError,addCompanyLocal,updateCompanyLocal, endLoadingCompany} from './companySlice';

/**
* Esta función recupera una lista de empresas de una API y envía acciones basadas en el éxito
  * o fallo de la llamada a la API.
  * @returns Una función que devuelve una función asíncrona que toma una función 'dispatch' como un
  * argumento.
 */
export const getCompanys = () => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch(startLoadingCompany() );
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<Company>>>("/adminUser/Company");
            if(data.dataError){
                dispatch(setError({ error:`${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`, iserror: true ,shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}` ,open:true}));

            }else{
                dispatch(setCompanies({ resultObject: data.resultObject, resultMessage: data.resultMessage  }) );
            }
        } catch (error) {
            dispatch(setError({ error:`${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COMPANY}`, iserror: true ,shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANY}` ,open:true}));
        }
       }
}

/**
* Esta función crea una empresa al realizar una llamada a la API y enviar acciones en función de la respuesta.
  * @param {Company} company: el objeto de empresa que se creará y enviará al servidor.
  * @returns La función `createCompany` está devolviendo una función asíncrona que toma un `despacho`
  * Funciona como un argumento. La función devuelta despacha la acción `startLoadingCompany`, luego envía una
  * Solicitud POST a ColdexAPI para crear una nueva empresa con el objeto "empresa" proporcionado. Si el
  * la solicitud es exitosa, envía la acción `addCompanyLocal` con el objeto de empresa creado. Sino ejecuta setError
 */
export const createCompany = (company:Company)=>{
    return async(dispatch:Dispatch<Action>)=>{
        dispatch(startLoadingCompany() );
        try {
            const { data } = await ColdexAPI.post<IApiResult<Company>>("/adminUser/Company",company);
            if(data.dataError){
                dispatch(setError({ error:`${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COLDEX}`, iserror: false ,shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}` ,open:true}));
            }else{
                dispatch(addCompanyLocal(company))
            }
        } catch (error) {
            dispatch(setError({ error:`${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANY}`, iserror: true ,shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANY}` ,open:true}));
        }
    }
}

/**
  * Esta es una función que actualiza la información de una empresa y envía acciones para manejar la carga y
  * estados de error.
  * @param {Company} company: el objeto de la empresa que debe actualizarse.
  * @returns La función `updateCompany` devuelve una función que toma un parámetro `dispatch` y
  * devuelve una Promesa. La Promesa resuelve enviar una acción `updateCompanyLocal` o
  * enviar una acción `setError` si hay un error.
 */
export const updateCompany = (company:Company)=>{
    return async(dispatch:Dispatch<Action>)=>{
        dispatch(startLoadingCompany() );
        try {
            const { data } = await ColdexAPI.put<IApiResult<Company>>(`/adminUser/Company/Update?NdocBusinessID=${company.ndoc}&TdocBusinessID=${company.tdoc}`,company);
            if(data.dataError){
                dispatch(setError({ error:`${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_COLDEX}`, iserror: true ,shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}` ,open:true}));
            }else{
               
                dispatch(updateCompanyLocal(company))
            }
        } catch (error) {
            dispatch(setError({ error:`${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_COMPANY}`, iserror: true ,shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANY}` ,open:true}));
        }
        dispatch(endLoadingCompany())
    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = ()=>{
    return async( dispatch:Dispatch<Action> ) => {
      dispatch(setError({ error: "", iserror: false, shorterror: "",open:false }));
    }
  }

