import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICategoryxColdexState } from '../../../utils/interfaces/store/IStore';
import { Category } from '../../../utils/types/Categorys';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';

const initialState: ICategoryxColdexState = {
    categoryResults: [],
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}

export const categorySlice = createSlice({
    name: 'Category',
    initialState,
    reducers:{
        startLoadingCategorys: (state) =>{
            state.isLoading = true;
        },
        setCategoryxColdex:(state, {payload}:PayloadAction<IPayloadSetdata<Array<Category>>>)=>{
            state.isLoading = false;
            state.dataError = false;
            state.categoryResults = payload.resultObject;
            state.resultMessage = payload.resultMessage!;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading=false;
            state.dataError=payload.iserror;
            state.resultMessage=payload.error;
            state.shortMessage=payload.shorterror;
            state.open=payload.open!;
        }
    }
})

export const {startLoadingCategorys, setCategoryxColdex,setError} = categorySlice.actions

export default categorySlice.reducer
