export const HOME_ROUTE = "/"

export const ADMINISTRATION_ROUTE = '/administracion'
export const COLDEX_ROUTE = '/coldex'
export const REPORTS_ROUTE = '/reportes'
export const LOGIN_ROUTE= '/login'
export const RESOURCES_USER_ROUTE= 'recursos'


// CHILDRENS REACT OUTLET
export const USERS_ROUTE = "usuarios"
export const USERS_COLDEX_ROUTE = "user"
export const ROLE_ROUTE = "roles"
export const COMPANIES_ROUTE = "empresas"
export const RESOURCES_ROUTE = 'secciones'
export const COLDEX_NEWTWORK_ROUTE = "red"
export const COLDEX_SECTORIAL_ROUTE = "sectorial"
export const COLDEX_PUBLICO_ROUTE = "publico"

//ROUTES MENU

export const MENU_USERS_ROUTE = "/administracion/usuarios"
export const MENU_COMPANY_ROUTE = "/administracion/empresas"
export const MENU_ROLE_ROUTE = "/administracion/roles"
export const MENU_SECTION_ROUTE = "/administracion/secciones"

export const MENU_COLDEX_NETWORK_ROUTE = "/coldex/red"
export const MENU_COLDEX_SECTORIAL_ROUTE = "/coldex/sectorial"
export const MENU_COLDEX_PUBLIC_ROUTE = "/coldex/publico"

//ROUTES RESOURCES

export const SECTION_RESOURCES = "/Configuracion/Recursos"
export const SECTION_FORM = "/Configuracion/Formulario"
