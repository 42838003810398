import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_ACTION_TYPE_GET, MESSAGE_CONTEXT_USERANSWERXCOLDEX, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { UserAnswerxColdex } from '../../../utils/types/UserAnswerxColdex';
import {startLoadingUserAnswerxColdex, setUserAnswerxColdex,setError,AddAnswerLocal} from './userAnswerxColdexSlice';



/**
  * Esta es una función de TypeScript React que recupera el progreso de las respuestas de los usuarios para una prueba de Coldex desde una API y envía acciones basadas en el resultado.
  * @param {number} id: la identificación de una prueba Coldex.
  * @param {string} email: el parámetro email es una cadena que representa la dirección de correo electrónico de un usuario. Se utiliza como filtro para recuperar respuestas de usuario para un usuario específico en ColdexAPI.
  * @returns Esta función devuelve una Promesa que envía una acción para comenzar a cargar las respuestas de los usuarios para una prueba de Coldex, realiza una llamada a la API para obtener las respuestas de los usuarios para una prueba de Coldex con una ID y un correo electrónico específicos, y luego envía una acción para configurar el usuario. respuestas o un mensaje de error dependiendo del resultado de la llamada a la API.
  */
export const getuserAnswerxColdex = (id:number,email:string) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch(startLoadingUserAnswerxColdex() );
        const { data } = await ColdexAPI.get<IApiResult<Array<UserAnswerxColdex>>>(`adminColdex/UserAnswerxColdex/GetUserAnswerxColdex?idColdex=${id}&email=${email}`);
        try {
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, open: true }));

            }else{
                dispatch( setUserAnswerxColdex({ resultObject: data.resultObject, resultMessage: data.resultMessage  }) );
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, open: true }));
        }
    }
}

/**
  * Esta función postea el progeso de las respuesta de un usuario a una prueba de Coldex y envía acciones basadas en el resultado.
  * @param {UserAnswerxColdex} userAnswerxColdex: el parámetro `userAnswerxColdex` es un objeto de tipo `UserAnswerxColdex` que contiene información sobre el progreso de la respuestas de Coldex. Este objeto se pasa como argumento a la función `postUserAnswerxColdex` 
  * @returns Una función que toma un objeto userAnswerxColdex y devuelve una función asíncrona que envía acciones para comenzar a cargar userAnswerxColdex, realizar una solicitud posterior a ColdexAPI y enviar un error o agregar el usuarioAnswerxColdex al estado local según la respuesta de la API.
  */
export const postUserAnswerxColdex = (userAnswerxColdex:UserAnswerxColdex)=>{
    return async(dispatch:Dispatch<Action>)=>{
        dispatch(startLoadingUserAnswerxColdex() );
        try {
            const { data } = await ColdexAPI.post<IApiResult<Array<UserAnswerxColdex>>>("adminColdex/UserAnswerxColdex",userAnswerxColdex);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, open: true }));

            }else{
                
                dispatch(AddAnswerLocal(userAnswerxColdex))
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_USERANSWERXCOLDEX}`, open: true }));
        }
    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}