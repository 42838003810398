
import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_ACTION_TYPE_GET, MESSAGE_CONTEXT_COMPANYXINDXCOLDEX, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { SectorxCompany } from '../../../utils/types/SectorxCompany';
import { startLoadingCIndXColdex, setCIndXColdex, setError } from './companyXIndXColdex';

/**
  * Esta es una función de TypeScript React que recupera datos de un API y envía acciones en función del resultado.
  * @param {number} id: el ID del Coldex para la que se obtienen los datos de la industria y de Coldex.
  * @returns Una función que toma un parámetro numérico y devuelve una función asíncrona que toma un parámetro de envío y envía acciones según el resultado de una llamada a la API.
  */
export const getCompanyXIndXColdex= (id: number) => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(startLoadingCIndXColdex());
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<SectorxCompany>>>("adminColdex/CompanyXIndustryXColdex/Coldex/" + id);
            if (data.dataError) {
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, open: true }));
            } else {
                dispatch(setCIndXColdex({ resultObject: data.resultObject, resultMessage: data.resultMessage }));
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, open: true }));
        }

    }
}

/**
  * Esta es una función de TypeScript React que crea una nueva entrada en una tabla de base de datos llamada "CompanyXIndustryXColdex" utilizando datos de una matriz de objetos "SectorxCompany".
  * @param sectorxCompany: una matriz de objetos que representan la relación entre una empresa y una industria en el contexto de una prueba Coldex.
  * @returns Una función que toma una matriz de objetos SectorxCompany como entrada y devuelve una función asíncrona que envía una acción basada en el resultado de una solicitud POST a un extremo de ColdexAPI. Si hay un error, envía una acción de error con detalles sobre el error. Si no hay error, no despacha ninguna acción.
  */
export const CreateCompanyXIndXColdex = (sectorxCompany:Array<SectorxCompany>) => {
    return async (dispatch: Dispatch<Action>) => {
        try {
            const { data } = await ColdexAPI.post<IApiResult<Array<SectorxCompany>>>(`adminColdex/CompanyXIndustryXColdex`, sectorxCompany, { headers: { "Content-Type": "application/json" }, });
            if (data.dataError) {
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, open: true }));
            } else {
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANYXINDXCOLDEX}`, open: true }));
        }

    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}