import { useEffect, useState } from "react";
import { IPreviewData } from "../../interfaces/app/IPopUps";
import { ColdexConfig } from "../../types/ColdexConfig";
import { Category } from "../../types/Polls";

/**
 * Esta es una función de TypeScript que toma una matriz de objetos ColdexConfig y devuelve un objeto
  * con una propiedad de datos que contiene una matriz de objetos IPreviewData.
  * @param PollxColdexConfig - Una matriz de objetos que contienen datos de configuración para encuestas en un Coldex
  * sistema.
  * @returns Un objeto con una sola propiedad "datos", que contiene una matriz de objetos con propiedades
  * "idType" y "datos". La propiedad "datos" es una matriz de objetos con propiedades "descripción",
  * "idCategory", "idType" y "polls". La propiedad "polls" es una matriz de objetos con propiedades
  * "idPoll", "pollDescription" y "niveles",etc.
 */
const usePreviewColdex = (PollxColdexConfig: Array<ColdexConfig>) => {
    const [data, setData] = useState<Array<IPreviewData>>([]);
    useEffect(() => {
        const aux1: Array<Category> = [];
        if (PollxColdexConfig !== undefined) {
          if (PollxColdexConfig.length !== 0) {
            PollxColdexConfig.forEach((e: any) => {
              if (aux1.length === 0) {
                let x = {
                  description: e.description,
                  idCategory: e.idCategory,
                  idType: e.idType,
                  polls: [
                    {
                      idPoll: e.idPoll,
                      pollDescription: e.pollDescription,
                      levels: [
                        {
                          pollLevel: e.pollLevel,
                          pollType: e.pollType,
                          value: e.value,
                        },
                      ],
                    },
                  ],
                };
                aux1.push(x);
    
              } else {
                let auxF = false;
                for (let index = 0; index < aux1.length; index++) {
                  if (aux1[index].idType === e.idType) {
                    if (aux1[index].description === e.description) {
                      let auxLevels = false;
                      for (let j = 0; j < aux1[index].polls.length; j++) {
                        if (aux1[index].polls[j].idPoll === e.idPoll) {
                          aux1[index].polls[j].levels.push({
                            pollLevel: e.pollLevel,
                            pollType: e.pollType,
                            value: e.value,
                          });
                          auxLevels = true;
                          auxF = true;
                        }
                      }
                      if (auxLevels === false) {
                        aux1[index].polls.push({
                          idPoll: e.idPoll,
                          pollDescription: e.pollDescription,
                          levels: [
                            {
                              pollLevel: e.pollLevel,
                              pollType: e.pollType,
                              value: e.value,
                            },
                          ],
                        });
                        auxF = true;
                      }
                    }
                  }
                }
                if (auxF === false) {
                  let x = {
                    description: e.description,
                    idCategory: e.idCategory,
                    idType: e.idType,
                    polls: [
                      {
                        idPoll: e.idPoll,
                        pollDescription: e.pollDescription,
                        levels: [
                          {
                            pollLevel: e.pollLevel,
                            pollType: e.pollType,
                            value: e.value,
                          },
                        ],
                      },
                    ],
                  };
                  aux1.push(x);
                }
              }
            });
            const aux2: Array<IPreviewData> = []
            aux1.forEach((p:Category) => {
              if (aux2.length === 0) {
                aux2.push({ idType: p.idType, data: [p] })
              } else {
                let flagExistinAux2 = false
                aux2.forEach((types: any) => {
                  if (types.idType === p.idType) {
                    types.data.push(p)
                    flagExistinAux2 = true
                  }
                })
                if (flagExistinAux2 === false) {
                  aux2.push({ idType: p.idType, data: [p] })
                }
              }
            })
            setData(aux2);
          }
        }
      }, [PollxColdexConfig]);
      return {data}
}

export default usePreviewColdex;