import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFormUserResponseState } from "../../../utils/interfaces/store/IStore";
import { UserResponseSend } from "../../../utils/types/ReourcesFormUser";
import {  IPayloadSetError } from "../../Interfaces";


const initialState:IFormUserResponseState = {
    UserResponsesSend: [],
    responseSendResult: undefined,
    shortMessage: "",
    resultMessage: "",
    dataError: false,
    open: false,
    isLoading: false
}


export const formUserResponseSlice = createSlice({
    name: 'formUserResponse',
    initialState,
    reducers:{
       startLoadingSendResponse:(state) =>{
            state.isLoading = true;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.shortMessage = payload.shorterror;
            state.open = payload.open!;
        },
        setUserResponseFormResult:(state,{payload}:PayloadAction<UserResponseSend>)=>{
            state.responseSendResult = payload;
        }
    }
})

export const {startLoadingSendResponse,setError,setUserResponseFormResult} = formUserResponseSlice.actions

export default formUserResponseSlice.reducer
