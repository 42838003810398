import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITempleteState } from '../../../utils/interfaces/store/IStore'
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces'

const initialState: ITempleteState = {
  file: "",
  isLoading: false,
  iserror:false,
  error:"",
  shortMessage:""
}

export const templeteSlice = createSlice({
  name: 'TempleteFile',
  initialState,
  reducers: {
    startTempleteLoading:(state)=>{
        state.isLoading=true
    },
    setTempleteFile:(state,{payload}:PayloadAction<IPayloadSetdata<string>>)=>{
        state.isLoading=false
        state.file = payload.resultObject;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.iserror = payload.iserror;
      state.error = payload.error;
    }
  },
})

// Action creators are generated for each case reducer function
export const {  startTempleteLoading,setTempleteFile,setError} = templeteSlice.actions

export default templeteSlice.reducer