import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IColdexState } from '../../../utils/interfaces/store/IStore';
import { Coldex } from '../../../utils/types/Coldex';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';


const initialState: IColdexState = {
    coldexs: [],
    resultMessage: '',
    dataError: false,
    shortMessage: '',
    open: false,
    isLoading: false,
}

export const coldexSlice = createSlice({
    name: 'coldex',
    initialState,
    reducers:{
        startLoadingColdex: (state) =>{
            state.isLoading = true;
        },
        setColdex:(state, {payload}:PayloadAction<IPayloadSetdata<Array<Coldex>>>)=>{
            state.isLoading = false;
            state.dataError = false;
            state.coldexs = payload.resultObject;
            state.resultMessage = payload.resultMessage!; 
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.open = payload.open!;
            state.shortMessage = payload.shorterror;
        },
        updateColdexLocal: (state, {payload}:PayloadAction<Coldex> ) => { 
            state.coldexs = state.coldexs.map( coldex => {
                if ( coldex.id === payload.id ) {
                    return payload;
                }
                return coldex;
            });
            state.isLoading = false
        },
        deleteColdexLocal:(state, {payload}:PayloadAction<number>)=>{
            state.coldexs = state.coldexs.filter( coldex => coldex.id !== payload);
            state.isLoading=false;
          },
        addColdexLocal:(state,{payload}:PayloadAction<Coldex>)=>{
            state.coldexs.push(payload)
            state.isLoading=false;
          }
    }
})

export const {startLoadingColdex, setColdex, setError, updateColdexLocal,deleteColdexLocal,addColdexLocal } = coldexSlice.actions

export default coldexSlice.reducer
