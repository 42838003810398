import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sendMailxUserState } from '../../../utils/interfaces/store/IStore'
import { IPayloadSetMail, IPayloadUpdateTemplate } from '../../Interfaces'

const initialState: sendMailxUserState = {
    response: "",
    responseUpdate: "",
    httpError: "",
    httpErrorUpdate: "",
    isSendMail: false,
    isSendMailEnd: false,
    isUpdateTemplateMail : false,
    isError : false,
    open: false,
    
}

export const sendMailSlice = createSlice({
    name: 'sendMail',
    initialState,
    reducers:{
        startLoading:(state)=>{
            state.isSendMail=true
        },
        startUpdate:(state)=>{
            state.isUpdateTemplateMail= true
            state.isSendMailEnd = false
        },
        setSendMailSlice:(state, {payload}:PayloadAction<IPayloadUpdateTemplate>)=>{
            state.isSendMail=false
            state.response = payload.response;
            state.isSendMailEnd = false
        },
        setErrorSendMail:(state, {payload}:PayloadAction<IPayloadSetMail>) =>{
            state.isSendMail = false
            state.isSendMailEnd = false
            state.httpError= payload.httpError
            state.open =payload.open!
        },
        setFinishSendMail:(state) =>{
            state.isSendMail = false
            state.isSendMailEnd = true
            state.isUpdateTemplateMail = false
        },
        setUpdateTemplateMailError:(state, {payload}:PayloadAction<IPayloadSetMail>) =>{
            state.isUpdateTemplateMail = false
            state.isSendMailEnd = false
            state.httpErrorUpdate= payload.httpErrorUpdate!;
            state.isError = payload.isError!
            state.open = payload.open!
        },
        setUpdateTemplateMail:(state, {payload}:PayloadAction<IPayloadUpdateTemplate>)=>{
            state.isUpdateTemplateMail =false;
            state.isSendMailEnd = false
            state.responseUpdate = payload.response;
        },
        setCloseAlert:(state) =>{
            state.isSendMailEnd = false    
        },
        setReset:(state)=>{
            state.httpErrorUpdate=""
            state.httpError=""
            state.isError=false
            state.open = false
        }
    }
})

export const {startLoading,setSendMailSlice,setErrorSendMail,setFinishSendMail, setUpdateTemplateMail,startUpdate, setUpdateTemplateMailError, setCloseAlert,setReset} = sendMailSlice.actions

export default sendMailSlice.reducer