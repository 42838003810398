
import { setUser, startLoginLoading,setError } from './loginUserSlice';
 import { startPermissionLoading} from '../permissionXRol';
import { MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { IApiResult, IRefreshToken, IUserLogin } from '../../../utils/interfaces/store/IApiResponse';



/**
  * Esta función inicia sesión en un usuario enviando su correo electrónico y contraseña a una API y almacenando el token resultante y la información del usuario en el localstorage.
  * @param {String} email: una cadena que representa la dirección de correo electrónico del usuario.
  * @param {String} password: el parámetro de contraseña es una cadena que representa la contraseña del usuario.
  * @returns La función `getUser` devuelve una función que toma un parámetro `dispatch` y devuelve una Promesa.
  */
export const getUser = (email:String,password:String) => {
    return async( dispatch: Dispatch<Action> ) => {
        dispatch( startLoginLoading() );
        try {
            const { data } = await ColdexAPI.post<IApiResult<IUserLogin>>(`auth/Account`, {"userName":email, "password": password});
            if(data.dataError){
                dispatch(setError({iserror:true,shorterror:data.shortMessage,error:data.resultMessage}))
            }else{
                dispatch(startPermissionLoading())
                localStorage.setItem('token', data.resultObject.jwtToken)
                //localStorage.setItem('token', data.resultObject.jwtToken)'
                const date= new Date()
                date.setHours(date.getHours()+1)
                localStorage.setItem('date', date.toString())
                dispatch(setUser({resultObject:{user:data.resultObject.user, expiresIn:data.resultObject.expiresIn,token :data.resultObject.jwtToken }}))
            }
        } catch (error) {
            dispatch(setError({iserror:true,error:MESSAGE_ERROR_TIMEOUT,shorterror:MESSAGE_ERROR_TIMEOUT}))
        }
    }
}

/**
  * Esta es una función de TypeScript React que actualiza el token de un usuario enviando una solicitud de publicación a un servidor y actualizando el token y la fecha en el almacenamiento local.
  * @param {String} email: el correo electrónico del usuario para el que se solicita el token de actualización.
  * @returns Una función que toma una cadena de correo electrónico como entrada y devuelve una función asíncrona que envía una acción basada en el resultado de una solicitud POST a un punto final de API específico. La acción enviada depende de si la solicitud fue exitosa o no. Si tiene éxito, la función actualiza el token y la fecha en el almacenamiento local. Si hay un error, envía una acción de error con un mensaje de error específico.
  */
export const refreshTokenUser=(email:String)=>{
    return async( dispatch: Dispatch<Action> ) => {
        try {
            const { data } = await ColdexAPI.post<IApiResult<IRefreshToken>>(`auth/Account/RefreshToken`, {"userName":email, "token": localStorage.getItem('token')});
            if(data.dataError){
                dispatch(setError({iserror:true,error:data.shortMessage,shorterror:data.resultMessage}))
            }else{
                localStorage.setItem('token', data.resultObject.refreshToken)
                const date= new Date()
                date.setHours(date.getHours()+1)
                localStorage.setItem('date', date.toString())
            }
        } catch (error) {
            dispatch(setError({iserror:true,error:MESSAGE_ERROR_TIMEOUT,shorterror:MESSAGE_ERROR_TIMEOUT}))
        }
    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = ()=>{
    return async( dispatch: Dispatch<Action> ) => {
        dispatch(setError({iserror:false,error:"",shorterror:""}))
    }
}