import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {  IRowDataColdexState } from '../../../utils/interfaces/store/IStore'
import { RowDataColdex } from '../../../utils/types/RowDataColdex'
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces'

const initialState: IRowDataColdexState  = {
  rowDateColdex: [],
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}

export const rowDataColdexSlice = createSlice({
  name: 'rowDataColdex',
  initialState,
  reducers: {
    startLoading:(state)=>{
        state.isLoading=true
    },
    setRowDataColdexSlice:(state,{payload}:PayloadAction<IPayloadSetdata<Array<RowDataColdex>>>)=>{
        state.isLoading=false
        state.rowDateColdex = state.rowDateColdex.concat(payload.resultObject);
    },
    setResetRowDataSlice:(state)=>{
      state.rowDateColdex = []
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading:(state)=>{
      state.isLoading=false
    }
  },
})

// Action creators are generated for each case reducer function
export const {  startLoading,setRowDataColdexSlice,setError,endLoading,setResetRowDataSlice} = rowDataColdexSlice.actions

export default rowDataColdexSlice.reducer