import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { CompanyColdexCategory } from "../../../utils/types/CompanyColdexCategory";
import {
  startLoadingcompanyColdexCategory,
  setError,
  setcompanyColdexCategory,
} from "./companyColdexCategory";

/**
 * Esta es una función de TypeScript React que crea una categoría Coldex de la empresa y envía la carga
  * y acciones de error.
  * @param {CompanyColdexCategory} companyColdexCategory - Este es un objeto que representa una empresa
  * categoría. Contiene propiedades como el nombre de la categoría, la descripción y
  * Otra información relevante. Este parámetro se utiliza como carga útil para la solicitud de API para crear un
  * nueva categoría de empresa.
  * @returns a Promise que se resuelve en el resultObject devuelto por el método ColdexAPI.post().
 */
export const CreateCompanyColdexCategory = (
  companyColdexCategory: CompanyColdexCategory
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingcompanyColdexCategory());
    try {
      const { data } = await ColdexAPI.post<
        IApiResult<CompanyColdexCategory>
      >(`adminColdex/CompanyColdexCategory`, companyColdexCategory, {
        headers: { "Content-Type": "application/json" },
      });
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
            open: true,
          })
        );
      } else {
      }
      return data.resultObject;
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que recupera la categoría Coldex de una empresa por ID y envía acciones según el resultado.
  * @param {bigint} id: el ID de la categoría Coldex de la empresa que debe recuperarse.
  * @returns Una función que toma un parámetro de ID y devuelve una función asíncrona que envía acciones para comenzar a cargar una categoría Coldex de la empresa, realizar una llamada a la API para recuperar los datos de la categoría en función de la ID y luego enviar una acción de categoría Coldex de la empresa establecida con los datos recuperados o una acción de error si hubo un error durante la llamada a la API. La función también devuelve los datos recuperados si tiene éxito.
  */
export const GetCompanyColdexCategory = (id: bigint) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingcompanyColdexCategory());
    try {
      const { data } = await ColdexAPI.get<
        IApiResult<CompanyColdexCategory>
      >(`adminColdex/CompanyColdexCategory?id=${id}`, {
        headers: { "Content-Type": "application/json" },
      });
      if (data.dataError) {
        dispatch( setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
            open: true,
          }));
        dispatch(setcompanyColdexCategory({resultObject:undefined })
        );
      } else {
        dispatch(setcompanyColdexCategory({resultObject: data.resultObject,}));
      }
      return data.resultObject;
    } catch (error) {
      dispatch(setcompanyColdexCategory({resultObject:undefined, }));
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          open: true,
        })
      );
    }
  };
};

/**
* Esta es una función de TypeScript React que actualiza la categoría Coldex de una empresa mediante una llamada API y envía una acción basada en el resultado.
  * @param {bigint} id: un identificador único para la categoría companyColdex que debe actualizarse. Es de tipo bigint.
  * @param {CompanyColdexCategory} companyColdexCategory: el parámetro `companyColdexCategory` es un objeto de tipo `CompanyColdexCategory` que contiene la información actualizada para la categoría Coldex de una empresa específica. Este objeto se pasa como segundo argumento al método `put` de la instancia `ColdexAPI` para actualizar el correspondiente
  * @returns Una función que toma un ID y un objeto CompanyColdexCategory como parámetros y devuelve una función asincrónica que envía una acción para actualizar un objeto CompanyColdexCategory en el estado usando datos de una llamada API.
 */
export const UpdateCompanyColdexCategory=(id:bigint,companyColdexCategory: CompanyColdexCategory)=>{
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await ColdexAPI.put<
      IApiResult<CompanyColdexCategory>
    >(`adminColdex/CompanyColdexCategory/${id}`, companyColdexCategory, {
      headers: { "Content-Type": "application/json" },
    });
    if (data.dataError) {
      dispatch( setError({
          error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          iserror: true,
          shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          open: true,
        }));
    } else {
      dispatch(setcompanyColdexCategory({resultObject: data.resultObject}));
    }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COMPANYCOLDEXCATEGORY}`,
          open: true,
        })
      );
    }
  }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
