import { useEffect, useState } from "react";
import { BUSSINES_PERMISSION, COLDEX_PERMISSION, REPORTS_PERMISSION, RESOURCES_PERMISSION, ROLES_PERMISSION } from "../../constants/text-constants";
import { PermissionxRol } from "../../types/Permission";

/**
 * Este custom hook en TypeScript  filtra una matriz de permisos y devuelve matrices separadas
 *  para diferentes tipos de permisos.
 * @param permissions - An array of objects representing permissions assigned to a user, where each
 * object contains information about the permission, such as the object name and the role assigned to
 * it.
 * @returns devuelve un objeto con cinco propiedades: `permissionsAdmin`, `permissionAdminCompanies`,
 *  `permissionAdminRole`, `permissionAdminResources` y `permissionAdminColdex`. Estas propiedades 
 * son matrices de objetos de tipo `PermissionxRol`.
 */
const usePermissionsAdmin = (permissions: Array<PermissionxRol>) =>{
    const [permissionsAdmin, setPemissionAdmin] = useState<Array<PermissionxRol>>([]);
    const [permissionAdminCompanies, setPermissionAdminCompanies] = useState<Array<PermissionxRol>>([]);
    const [permissionAdminRole, setPermissionAdminRole] = useState<Array<PermissionxRol>>([]);
    const [permissionAdminResources, setPermissionAdminnResources] = useState<Array<PermissionxRol>>([]);
    const [permissionAdminColdex, setPermissionAdminColdex] = useState<Array<PermissionxRol>>([]);
    useEffect(() => {
        let auxAdmin: Array<PermissionxRol> = []; //USers
        let auxAdminCompanies: Array<PermissionxRol> = []; // Companies
        let auxAdminRole: Array<PermissionxRol> = []; // Role
        let auxAdminResources: Array<PermissionxRol> = []; // resources
        let auxAdminColdex: Array<PermissionxRol> = []; // Coldex
        permissions.forEach((p) => {
          if (p.ObjectName !== COLDEX_PERMISSION && p.ObjectName !== REPORTS_PERMISSION) {
            auxAdmin.push(p);
          }
          if(p.ObjectName === BUSSINES_PERMISSION){
            auxAdminCompanies.push(p)
          }
          if(p.ObjectName === RESOURCES_PERMISSION){
            auxAdminResources.push(p)
          }
          if(p.ObjectName === ROLES_PERMISSION){
            auxAdminRole.push(p)
          }
          if(p.ObjectName === COLDEX_PERMISSION){
            auxAdminColdex.push(p)
          }
          
        });
        setPemissionAdmin(auxAdmin);
        setPermissionAdminCompanies(auxAdminCompanies)
        setPermissionAdminnResources(auxAdminResources)
        setPermissionAdminRole(auxAdminRole)
        setPermissionAdminColdex(auxAdminColdex)
    }, [permissions]);

    return {permissionsAdmin, permissionAdminCompanies,permissionAdminRole,permissionAdminResources, permissionAdminColdex} 
};


export default usePermissionsAdmin;