import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISectionResourceState } from "../../../utils/interfaces/store/IStore";
import { SectionResource } from "../../../utils/types/Section";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";

const initialState: ISectionResourceState = {
  sectionsresources: [],
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false
};

export const sectionResourceSlice = createSlice({
  name: 'SectionResource',
  initialState,
  reducers: {
    startLoadingSectionResource: (state) => {
      state.isLoading = true;
    },
    setSectionResource: (state,  {payload}:PayloadAction<IPayloadSetdata<Array<SectionResource>>>) => {
      state.dataError = false;
      state.isLoading = false;
      state.sectionsresources = payload.resultObject;
      state.resultMessage =payload.resultMessage!;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    updateSectionResourceLocal: (state, {payload}:PayloadAction<SectionResource> ) => { 
      state.sectionsresources = state.sectionsresources.map( sectionResource => {
          if ( sectionResource.resourceID === payload.resourceID ) {
              return payload;
          }
          return sectionResource;
      });
      state.isLoading = false
    }
  },
});

export const { startLoadingSectionResource, setSectionResource, setError, updateSectionResourceLocal } =
  sectionResourceSlice.actions;
export default sectionResourceSlice.reducer;
