import { OPTION_COMPANY_USER_DEFAULT, OPTION_DOCUMENT_DEFAULT, OPTION_ID_DEFAULT, OPTION_ROLE_DEFAULT, PARAMETERS_CDXTYPE, PARAMETERS_COMPANYTYPE, PARAMETERS_FORMS, PARAMETERS_ROLE, PARAMETERS_TDOCCOMPANY, PARAMETERS_RESOURCESTYPE, PARAMETERS_RFORM, OPTION_RFORM_USER_DEFAULT, PARAMETERS_SECTIONTYPE, OPTION_SECCION_USER_DEFAULT, OPTION_RESOURCES_DEFAULT, PARAMETERS_ICONSPOLL } from "../../constants/text-constants";
import { Parameter } from "../../types/Parameter";

/**
 * el custom Hook "usParameters" filtra y organiza una serie de parámetros en función de sus respectivos ID.
 * @param parameters - Una matriz de objetos de parámetro.
 * @returns La función `usParameters` devuelve un objeto que contiene varias matrices de objetos `Parameter` 
 * que se han filtrado y modificado en función de su propiedad `idParameter`.
 */
const usParameters = (parameters: Array<Parameter>) => {
  
    const parametersFormUser : Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_FORMS)
    const parametersTypeRole : Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_ROLE)
    parametersTypeRole.unshift({description:OPTION_ROLE_DEFAULT,idParameter:OPTION_ID_DEFAULT, value: -1})
    const parameterCompanyType: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_COMPANYTYPE) 
    parameterCompanyType.unshift({description:OPTION_COMPANY_USER_DEFAULT,idParameter:OPTION_ID_DEFAULT, value: -1})
    const parameterCompanyTDOC: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_TDOCCOMPANY) 
    parameterCompanyTDOC.unshift({description:OPTION_DOCUMENT_DEFAULT,idParameter:OPTION_ID_DEFAULT, value: -1})
    const parametersColdexType: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_CDXTYPE)
    const parameterSectionType: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_SECTIONTYPE) 
    parameterSectionType.unshift({description:OPTION_SECCION_USER_DEFAULT,idParameter:OPTION_ID_DEFAULT, value: -1})
    const parameterResourcesForm: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_RFORM)
    parameterResourcesForm.unshift({description:OPTION_RFORM_USER_DEFAULT,idParameter:OPTION_ID_DEFAULT, value: -1})
    const parameterResourcesType: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_RESOURCESTYPE)
    parameterResourcesType.unshift({description:OPTION_RESOURCES_DEFAULT,idParameter:OPTION_ID_DEFAULT, value: -1})
    const parameterInconsType: Array<Parameter> = parameters.filter(parameter => parameter.idParameter === PARAMETERS_ICONSPOLL)

    
    return {parametersFormUser,parametersTypeRole,parameterCompanyType,parameterCompanyTDOC,parametersColdexType,parameterSectionType,parameterResourcesForm,parameterResourcesType,parameterInconsType}
}

export default usParameters;