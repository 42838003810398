import { useEffect, useState } from "react";
import {
  ACTIVE_PERMISSION,
  CHANGE_STATE_COLDEX_PERMISSION_ID,
  COLDEX_PERMISSION,
  CREATE_COLDEX_PERMISSION_ID,
  DELETE_COLDEX_PERMISSION_ID,
  DOWNLOAD_TEMPLATE_COLDEX_PERMISSION_ID,
  UPDATE_COLDEX_PERMISSION_ID,
  VIEWS_COLDEX_REPORTS_PERMISSION_ID,
  VIEW_PADRINOCOLDEX_PERMISSION_ID,
  VIEW_PREVIEW_COLDEX_PERMISSION_ID,
  VIEW_USERSCOLDEX_PERMISSION_ID,
  VIEW_USERS_COLDEX_PERMISSION_ID,
  WRITE_COLDEX_PERMISSION_ID,
} from "../../constants/text-constants";
import { PermissionxRol } from "../../types/Permission";

/**
 * Este custom hook en TypeScript toma una variedad de permisos y devuelve varios indicadores y
  * matrices basadas en los permisos relacionados con una característica específica llamada "Coldex".
 * @param permissions - Una matriz de objetos que representan los permisos asignados a un usuario o rol. 
 * Cada objeto contiene información sobre un permiso específico, como el nombre del objeto, el ID y la regla de seguridad."
 * @returns Un objeto con las siguientes propiedades:
 * - `permissionsUser`: una matriz de objetos `PermissionxRol`.
 * - `flagEdit`:un valor booleano que indica si el usuario tiene permiso para editar.
 * - `flagDelete`: un valor booleano que indica si el usuario tiene permiso para eliminar.
 * - `flagCreate`: un valor booleano que indica si el usuario tiene permiso para crear.
 * - `flagWrite`: un valor booleano que indica si el usuario tiene permiso para escribir.
 * - `flagPreview`: un valor booleano que indica si el usuario tiene permiso para observar el preview del Coldex.
 * - `flagDownload`: un valor booleano que indica si el usuario tiene permiso para descargar plantillas.
 * - `flagViewReports`: un valor booleano que indica si el usuario tiene permiso para ver reportes.
 * - `flagChangeState`: un valor booleano que indica si el usuario tiene permiso para cambiarle el estado a un coldex.
 * - `flagViewUserColdex`: un valor booleano que indica si el usuario tiene permiso para ver usuarios de un coldex.
 * - `permissionUserColdex`: un valor booleano que indica si el usuario tiene permiso para ver los permisos de un usuario.
 * - `permissionPadrinoColdex`: un valor booleano que indica si el usuario tiene permiso para ver los padrinos de un coldex.
 */

const usePermissionsColdex = (permissions: Array<PermissionxRol>) => {
  const [permissionsUser, setPemissionColdex] = useState<Array<PermissionxRol>>(
    []
  );
  const [permissionUserColdex, setPermissionsUserColdex] = useState<Array<PermissionxRol>>()
  const [permissionPadrinoColdex, setPermissionsPadrinoColdex] = useState<Array<PermissionxRol>>()
  const [flagEdit, setFlagEdit] = useState<boolean>(false);
  const [flagDelete, setFlagDelete] = useState<boolean>(false);
  const [flagCreate, setflagCreate] = useState<boolean>(false);
  const [flagWrite, setflagWrite] = useState<boolean>(false);
  const [flagPreview, setFlagPreview] = useState<boolean>(false);
  const [flagDownload, setFlagDownload] = useState<boolean>(false);
  const [flagViewReports, setFlagViewReports] = useState<boolean>(false);
  const [flagChangeState, setFlagChangeState] = useState<boolean>(false);
  const [flagViewUserColdex, setViewUserColdex] = useState<boolean>(false);

  useEffect(() => {
    let auxColdex: Array<PermissionxRol> = [];
    permissions.forEach((permission) => {
      if (permission.ObjectName === COLDEX_PERMISSION) {
        auxColdex.push(permission);
        if (permission.Children.length > 0) {
          permission.Children.forEach((permission: PermissionxRol) => {
            switch (permission.ID) {
              case CREATE_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setflagCreate(true);
                break;
              case UPDATE_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setFlagEdit(true);
                break;
              case DELETE_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setFlagDelete(true);
                break;
              case WRITE_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setflagWrite(true);
                break;
              case VIEW_PREVIEW_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setFlagPreview(true);
                break;
              case DOWNLOAD_TEMPLATE_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setFlagDownload(true);
                break;
              case VIEWS_COLDEX_REPORTS_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setFlagViewReports(true);
                break;
              case CHANGE_STATE_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION)
                  setFlagChangeState(true);
                break;
              case VIEW_USERS_COLDEX_PERMISSION_ID:
                if (permission.SecurityRule === ACTIVE_PERMISSION){
                  setViewUserColdex(true);
                  let auxPermissionUser : Array<PermissionxRol>=[]
                  let auxPermissionPadrino : Array<PermissionxRol> = []  
                  auxPermissionUser = permission.Children.filter(perm=> perm.ID === VIEW_USERSCOLDEX_PERMISSION_ID)
                  auxPermissionPadrino = permission.Children.filter(perm=> perm.ID === VIEW_PADRINOCOLDEX_PERMISSION_ID)
                  setPermissionsUserColdex(auxPermissionUser)
                  setPermissionsPadrinoColdex(auxPermissionPadrino)
                }
                break;
              default:
                break;
            }
          });
        }
      }
    });
    setPemissionColdex(auxColdex);
  }, [permissions]);

  return {
    permissionsUser,
    flagEdit,
    flagDelete,
    flagCreate,
    flagWrite,
    flagPreview,
    flagDownload,
    flagViewReports,
    flagChangeState,
    flagViewUserColdex,
    permissionUserColdex,
    permissionPadrinoColdex
  };
};

export default usePermissionsColdex;
