import { TableCell, TableRow } from "@mui/material";
import React, { memo } from "react";
import { Navigate } from "react-router";
import { useAppSelector } from "../../../../../store/Hooks";
import { RootState } from "../../../../../store/Store";
import { CONFIG, CONFIG_ACTIVE, CONFIG_CLOSE, ACTION_BUTTON_PREVIEW, ACTION_BUTTON_UPDATE, ACTION_BUTTON_DELETE, ACTION_BUTTON_TEMPLETE, ACTION_BUTTON_USERS, ACTION_BUTTON_REPORTS, ACTION_BUTTON_MONITORING_REPORT, REPORT_ACTION, MONOTORING_REPORT_ACTION, } from "../../../../../utils/constants/text-constants";
import useFormActionsColdex from "../../../../../utils/hooks/coldex/useFormActionsColdex";
import usePermissionsColdex from "../../../../../utils/hooks/permissions/usePermissionsColdex";
import { Coldex } from "../../../../../utils/types/Coldex";
import { ButtonActionTable } from "../../../../Buttons/ButtonActionTable";
interface ITableRowColdex {
    row: Coldex;
    type:string
    handleSetPreviewColdex:Function
    handleSetUpdateColdex:Function
    handleSetDeleteColdex:Function
    handleSetUploadTemplete:Function
    handleSetDetails:Function
    handleSetReports:Function
    handleSetMonitoringReports:Function
    idcoldex:number | undefined
    coldex:Coldex
    action:string | undefined
  }
/**  Este código define un componente funcional de React llamado `TableRowColdexComponent`. Toma un objeto 
 * con dos propiedades `row` y `type` como apoyos. Utiliza varios ganchos personalizados para obtener datos 
 * y funciones de la tienda Redux y administra el estado de una ventana emergente. Representa una fila de tabla
 *  con varias celdas de tabla, cada una de las cuales contiene datos del objeto `fila`. También genera varios 
 *  botones que activan diferentes acciones cuando se hace clic, según los permisos del usuario y el estado actual
 *  del objeto `fila`. Finalmente, renderiza condicionalmente diferentes ventanas emergentes dependiendo de
 * en qué botón se hizo clic. La función `memo` se usa para optimizar el rendimiento memorizando el componente 
 * y solo volviéndolo a renderizar cuando cambian los valores de los parametros. 
 * */
const TableRowColdexComponent = memo(({row,
  handleSetPreviewColdex,
  handleSetUpdateColdex,
  handleSetDeleteColdex,
  handleSetUploadTemplete,
  handleSetDetails,
  handleSetReports,
  handleSetMonitoringReports,
  idcoldex,coldex,action}:ITableRowColdex) => {

      const { permissionRolUser } = useAppSelector((state: RootState) => state.permission);
      const { flagDelete, flagEdit, flagPreview,flagDownload,flagViewReports,flagChangeState,flagViewUserColdex } = usePermissionsColdex(permissionRolUser);
      const { handleUpdateStateColdex } = useFormActionsColdex();

  return (
    <>
      <TableRow>
        <TableCell>{row.coldexName?.toLowerCase()}</TableCell>
        <TableCell>{row.coldexYear}</TableCell>
        <TableCell>{row.coldexCountry?.toLowerCase()}</TableCell>
        <TableCell width={400}>{row.description}</TableCell>
        <TableCell width={180}>
          <select
            name={row.id!.toString()}
            value={row.status}
            onChange={(e) => {
              handleUpdateStateColdex(row, e.currentTarget.value);
            }}
            disabled={!flagChangeState ? true : false}
          >
            <option value={CONFIG}>{"CONFIGURACIÓN".toLowerCase()}</option>
            <option value={CONFIG_ACTIVE}>{"ACTIVO".toLowerCase()}</option>
            <option value={CONFIG_CLOSE}>{"CERRADO".toLowerCase()}</option>
          </select>
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={flagPreview}
            functionSelect={handleSetPreviewColdex}
            select={row}
            type={ACTION_BUTTON_PREVIEW}
          />
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={flagEdit}
            functionSelect={handleSetUpdateColdex}
            select={row}
            type={ACTION_BUTTON_UPDATE}
          />
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={flagDelete}
            functionSelect={handleSetDeleteColdex}
            select={row}
            type={ACTION_BUTTON_DELETE}
          />
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={flagDownload}
            functionSelect={handleSetUploadTemplete}
            select={row}
            type={ACTION_BUTTON_TEMPLETE}
          />
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={flagViewUserColdex}
            functionSelect={handleSetDetails}
            select={row}
            type={ACTION_BUTTON_USERS}
          />
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={flagViewReports}
            functionSelect={handleSetReports}
            select={row}
            type={ACTION_BUTTON_REPORTS}
          />
        </TableCell>
        <TableCell>
          <ButtonActionTable
            flag={true}
            functionSelect={handleSetMonitoringReports}
            select={row}
            type={ACTION_BUTTON_MONITORING_REPORT}
          />
        </TableCell>
      </TableRow>
      

      {idcoldex !== undefined && action === REPORT_ACTION && (
        <Navigate
          to={`reporte/${idcoldex.toString()}`}
          state={coldex}
          replace={true}
        />
      )}
      {action === MONOTORING_REPORT_ACTION && (
        <Navigate to={`monitoring_report`} state={coldex} replace={true} />
      )}
      {idcoldex !== undefined && action !== REPORT_ACTION && (
        <Navigate to={idcoldex.toString()} replace={true} />
      )}
     
    </>
  );
});

export default TableRowColdexComponent;
