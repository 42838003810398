import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserAnswerxColdex } from '../../../utils/interfaces/store/IStore';
import { UserAnswerxColdex } from '../../../utils/types/UserAnswerxColdex';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';

const initialState: IUserAnswerxColdex  = {
    shortMessage: "",
    resultMessage: "",
    dataError: false,
    open: false,
    isLoading: false,
    answers: []
}

export const userAnswerXColdexSlice = createSlice({
    name: 'useranswerxcoldex',
    initialState,
    reducers:{
        startLoadingUserAnswerxColdex: (state ) =>{
            state.isLoading = true;
        },
        setUserAnswerxColdex:(state, {payload}:PayloadAction<IPayloadSetdata<Array<UserAnswerxColdex>>>)=>{
            state.dataError = false;
            state.isLoading = false;
            state.answers = payload.resultObject;
            state.resultMessage = payload.resultMessage!;
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.dataError = payload.iserror;
            state.isLoading = false;
            state.resultMessage = payload.error;
            state.open = payload.open!;
            state.shortMessage = payload.shorterror;
        },
        AddAnswerLocal:(state,{payload}:PayloadAction<UserAnswerxColdex>)=>{
            if(state.answers.find(answer=>answer.email===payload.email && answer.idCategory===payload.idCategory&& answer.idColdex===payload.idColdex
                && answer.idPoll===payload.idPoll
                )){
                    state.answers = state.answers.map( answer => {
                        if ( answer.email===payload.email && answer.idCategory===payload.idCategory&& answer.idColdex===payload.idColdex  && answer.idPoll===payload.idPoll) {
                            return payload;
                        }
                        return answer;
                    });
                }else{
                    state.answers.push(payload)
                }
           
          
        }
    }
})

export const {startLoadingUserAnswerxColdex, setUserAnswerxColdex,setError,AddAnswerLocal} = userAnswerXColdexSlice.actions

export default userAnswerXColdexSlice.reducer