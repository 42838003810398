import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import { FILTER_COLDEX_RED_TYPE, TITLE_COLDEX_SECTION_PUBLICO, TITLE_COLDEX_SECTION_RED, TITLE_COLDEX_SECTION_SECTORIAL } from "../../../utils/constants/text-constants";
import { IPopUpColdex } from "../../../utils/interfaces/app/IPopUps";
import { ColdexForm } from "../../Forms/ColdexForm/ColdexForm";
import { PopUpTitle } from "../../Titles/PopUpTitle";
import "../popUps.scss";
import { resetError } from "../../../store/slices/companyColdexCategory";

/**
 * Este es un componente de TypeScript React que muestra una ventana emergente para crear 
 * un coldex.
 * @param {IPopUpColdex}  - - `isOpen`: un valor booleano que indica si la ventana emergente está 
 * actualmente abierta o cerrada
 * @returns Se devuelve un componente funcional de React llamado CreateColdexPopUp.
 */
export const CreateColdexPopUp = ({
  isOpen,setIsOpen,type
}: IPopUpColdex) => {
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const dispatch=useAppDispatch()
  const [allowRender, setallowRender] = useState(false)
  useEffect(() => {
    if(type===FILTER_COLDEX_RED_TYPE.toUpperCase()){
     dispatch(resetError()).then(()=>{
      setallowRender(true)
    
     })
    }
   }, [])
  return (
    <div className={isOpen ? "open-PopUp" : "close-PopUp"}>
    <div className="popUp-Content">
      {
        type === TITLE_COLDEX_SECTION_RED && allowRender&&
        <>
          <PopUpTitle setIsOpen={setIsOpen} title={`${language.components.popUps.coldex.titles.TITLE_FORM_CREATE_COLDEX} ${language.components.popUps.coldex.titles.TITLE_COLDEX_SECTION_RED}`}></PopUpTitle>     
          <ColdexForm isOpen={isOpen} setIsOpen={setIsOpen} type={TITLE_COLDEX_SECTION_RED}/> 
        </>
      }
      {
        type === TITLE_COLDEX_SECTION_SECTORIAL &&
        <>
        <PopUpTitle setIsOpen={setIsOpen} title={`${language.components.popUps.coldex.titles.TITLE_FORM_CREATE_COLDEX} ${language.components.popUps.coldex.titles.TITLE_COLDEX_SECTION_SECTORIAL}`}></PopUpTitle>    
        <ColdexForm isOpen={isOpen} setIsOpen={setIsOpen} type={TITLE_COLDEX_SECTION_SECTORIAL}/>  
        </>
      } 
        {
        type === TITLE_COLDEX_SECTION_PUBLICO &&
        <>
        <PopUpTitle setIsOpen={setIsOpen} title={`${language.components.popUps.coldex.titles.TITLE_FORM_CREATE_COLDEX} ${language.components.popUps.coldex.titles.TITLE_COLDEX_SECTION_PUBLICO}`}></PopUpTitle>    
        <ColdexForm isOpen={isOpen} setIsOpen={setIsOpen} type={TITLE_COLDEX_SECTION_PUBLICO}/>  
        </>
      } 
    </div>
    </div>
  );
};
