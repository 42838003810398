import { SpinnerCircular } from 'spinners-react';
import { useAppSelector } from '../../store/Hooks';
import { RootState } from '../../store/Store';
import { COLOR_SPINNER_LOADER_STADISTICS } from '../../utils/constants/text-constants';


/**  Este código define un componente funcional de React llamado `Loader`. Importa el componente `SpinnerCircular`
 * de la biblioteca `spinners-react` y algunas otras dependencias. */
const Loader = () => {
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  return (
    <div className={"open-PopUp" }>
     <div className="popUp-Content wide2 center">
        <SpinnerCircular size={80} color={COLOR_SPINNER_LOADER_STADISTICS} thickness={180}/>
        {
        language!=undefined&&
        <span>{language.components.loader.labels.LABEL_STATE_LOADER}</span>
        }
     </div>
    </div>
  )
}

export default Loader;