import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_ACTION_TYPE_UPDATE,
  MESSAGE_CONTEXT_RESOURCES,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { SectionResource } from "../../../utils/types/Section";
import {
  startLoadingSectionResource,
  setSectionResource,
  setError,
  updateSectionResourceLocal,
} from "./sectionResourceSlice";

/**
  * Esta es una función que recupera sección de recursos de una API y envía acciones basadas en el
  * resultado.
  * @returns Una función que toma una función de envío como argumento y devuelve una función asíncrona
  * función que envía acciones según el resultado de una llamada a la API.
  */
export const getSectionResource = () => {
  return async (dispatch: Dispatch<Action> | any) => {
    dispatch(startLoadingSectionResource());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<SectionResource>>>("/adminresources/api/SectionResource");
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setSectionResource({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_RESOURCES}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta función crea una nueva seccion de recursos mediante el envío de un archivo y otros datos a un extremo de la API y envía acciones en función de la respuesta.
  * @param {SectionResource} resources: el parámetro `resources` es un objeto que contiene información sobre una seccion recurso, incluido su tipo, ID de sección, nombre corto, descripción breve, estado, prioridad y fecha de actualización.
  * @param {File} file: el parámetro de archivo es un objeto de File que contiene los datos del archivo que se cargará.
  * @returns Una función que toma dos parámetros (recursos y archivo) y devuelve una función asincrónica que envía acciones según el resultado de una llamada a la API.
  */
export const createSectionResource = (
  resources: SectionResource,
  file: File
) => {
  return async (dispatch: Dispatch<Action> | any) => {
    dispatch(startLoadingSectionResource());
    try {
      var formData = new FormData();
      formData.append("file", file);
      const { data } = await ColdexAPI.post<IApiResult<Array<SectionResource>>>(`/adminresources/api/SectionResource?Type=${resources.type}&SectionID=${resources.sectionID}&ShortName=${resources.shortName}&Brief=${resources.brief}&State=${resources.state}&Order=${resources.priority}&UpdateDate=${resources.updateDate}`, formData);
      if (data.dataError) {
        dispatch(setError({ iserror: true, error: data.resultMessage,shorterror:data.shortMessage }));
      } else {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: false,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
        dispatch(getSectionResource());
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que actualiza un recurso con un archivo y envía acciones según el resultado.
  * @param {SectionResource} resources: el parámetro de recursos es un objeto de tipo SectionResource, que contiene información sobre seccion de recursos
  * @param {File} file: un objeto de archivo que contiene los datos que se cargarán.
  * @returns Una función que toma dos parámetros (recursos y archivo) y devuelve un asíncrono
  * función que envía acciones para actualizar una seccion de recursos y manejar cualquier error que pueda ocurrir.
  */
export const updateSectionResource = (
  resources: SectionResource,
  file?: File,
  flag?: Boolean
  ) => {
  return async (dispatch: Dispatch<Action> | any) => {
    dispatch(startLoadingSectionResource());
    try {
      var formData = new FormData();
      if(!flag){
        formData.append("file", file!);
      }
      const { data } = await ColdexAPI.put<IApiResult<Array<SectionResource>>>(`/adminresources/api/SectionResource?Type=${resources.type}&ResourceID=${resources.resourceID}&SectionID=${resources.sectionID}&ShortName=${resources.shortName}&Brief=${resources.brief}&State=${resources.state}&Priority=${resources.priority}&UpdateDate=${resources.updateDate}`, formData);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      } else {
        dispatch(updateSectionResourceLocal(resources))
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
      dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
  }
}
