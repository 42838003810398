import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoginState } from "../../../utils/interfaces/store/IStore";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";
import { User } from "../../../utils/types/User";
const initialState: ILoginState  = {
  isLogged: false,
  token: "",
  expiresIn: 0,
  user: {
    id: undefined,
    name: undefined,
    lastName: undefined,
    email: undefined,
    password: undefined,
    state: undefined,
    idRol: undefined,
    tdocCompany: undefined,
    ndocCompany: undefined,
    rol: undefined,
    phone: undefined,
    firstTimeLogging: undefined
  },
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false
};

export const loginUserSlice = createSlice({
  name: "loginUser",
  initialState,
  reducers: {
    startLoginLoading: (state) => {
      state.isLoading = true;
    },
    setUser: (state,  {payload}:PayloadAction<IPayloadSetdata<{user:User,expiresIn:number,token:string}>>) => {
      state.isLoading = false;
      state.isLogged = true;
      state.user = payload.resultObject.user;
      state.expiresIn = payload.resultObject.expiresIn;
      state.token = payload.resultObject.token;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.dataError = payload.iserror;
      state.isLoading = false;
      state.shortMessage = payload.shorterror;
      state.resultMessage = payload.error
    },
    setLogout: (state) => {
      state.isLogged = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { startLoginLoading, setUser, setError, setLogout } =
  loginUserSlice.actions;

export default loginUserSlice.reducer;
