import React from 'react'
import { useAppSelector } from '../../store/Hooks';
import { RootState } from '../../store/Store';
import Loader from '../Loader/Loader';

/**
  * El componente LanguageLoader muestra un cargador si el estado multilingüe se está cargando actualmente.
  */
export const LanguageLoader = () => {
const {isLoading} = useAppSelector( (state:RootState) => state.multiLanguage);
  return (
    <>
        {
            isLoading &&
            <Loader></Loader>
        }
    </>
  )
}
