import { createSlice,current, PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { ISecurityxUserxColdexState } from "../../../utils/interfaces/store/IStore";
import { Security } from "../../../utils/types/Security";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";

const initialState: ISecurityxUserxColdexState = {
  security: [],
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false,
};

export const securityxUserxColdexSlice = createSlice({
  name: "securityxUserxColdex",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    setUserxColdexSlice: (state,{payload}:PayloadAction<IPayloadSetdata<Array<Security>>>) => {
      state.isLoading = false;
      state.security = payload.resultObject;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading = false;
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
    deleteSecurityUserxColdexLocal: (state,{payload}:PayloadAction<Security>) => {
      state.security = state.security.filter(
        (user) =>
          user.email !== payload.email ||
          user.companyName !== payload.companyName ||
          user.idCategory !== payload.idCategory ||
          user.idColdex !== payload.idColdex ||
          user.idPoll !== payload.idPoll ||
          user.ndocCompany !== payload.ndocCompany ||
          user.pollLevel !== payload.pollLevel ||
          user.tdocCompany !== payload.tdocCompany
      );
      
      state.isLoading = false;
    },
    addSecurityUserxColdexLocal: (state, {payload}:PayloadAction<Security>) => {
      state.security.push(payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoading,
  setUserxColdexSlice,
  setError,
  endLoading,
  deleteSecurityUserxColdexLocal,
  addSecurityUserxColdexLocal,
} = securityxUserxColdexSlice.actions;

export default securityxUserxColdexSlice.reducer;
