import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_ACTION_TYPE_UPDATE,
  MESSAGE_CONTEXT_Permissions,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { Generic, IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { PermissionxRolUpdate } from "../../../utils/types/Permission";
import {
  startPermissionLoading,
  setPermission,
  setError,
  startPermissionLoadingUpdate,
  setPermissionRol,
  setPermissionUpdate,
} from "./permissionXRol";

/**
  * Esta es una función de TypeScript React que recupera permisos para un rol determinado y envía acciones en función de si flagUserPermission es verdadero o falso.
  * @param {número | string} rol: el ID o el nombre del rol para el que se obtienen los permisos.
  * @param {boolean} flagUserPermission: una bandera booleana que indica si el permiso que se recupera es para un usuario específico o para un rol. Si se establece en verdadero, el permiso se recuperará para un usuario; de lo contrario, se recuperará para un rol.
  * @returns Una función que toma dos parámetros (rol y flagUserPermission) y devuelve una función asíncrona que distribuye acciones según el resultado de una llamada API.
  */
export const getPermission = (
  rol: number | string,
  flagUserPermission?: boolean
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startPermissionLoading());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Generic>>(
        `adminUser/Permission/Permission?IdRol=${rol}`,
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_Permissions}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_Permissions}`,
            open: true,
          })
        );
      } else {
        if (flagUserPermission) {
          dispatch(
            setPermission({
              resultObject: data.resultObject.response,
              resultMessage: data.resultMessage
            })
          );
        } else {
          dispatch(
            setPermissionRol({
              resultObject: data.resultObject.response,
              resultMessage: data.resultMessage
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_Permissions}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_Permissions}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta es una función de TypeScript React que actualiza los permisos para un rol.
  * @param PermissionxRol: una array de objetos de tipo PermissionxRolUpdate, que se utiliza como carga para la llamada a la API para actualizar los permisos de un rol.
  * @returns Una función que toma una función de envío como argumento y devuelve una función asincrónica que realiza una solicitud PUT para actualizar los permisos de un rol. La función envía acciones para actualizar el estado con el resultado de la solicitud y cualquier error que ocurra.
  */
export const updatePermissonxRol = (
  PermissionxRol: Array<PermissionxRolUpdate>
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startPermissionLoadingUpdate());
    try {
      const { data } = await ColdexAPI.put<IApiResult<number>>(
        "adminUser/Permission/Update",
        PermissionxRol,
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_Permissions}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_Permissions}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setPermissionUpdate(data.resultObject)
        );
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_Permissions}`,
            iserror: false,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_Permissions}`,
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_Permissions}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_Permissions}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({
        iserror: false,
        error: "",
        open: false,
        shorterror: "",
      })
    );
  };
};
