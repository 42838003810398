import {
  QuestionAnswerOutlined,
  QuestionMarkOutlined,
} from "@mui/icons-material";
import { useAppSelector } from "../../store/Hooks";
import { RootState } from "../../store/Store";
import { ColdexInfo } from "../../utils/types/ColdexInfo";
import './coldexPreview.scss'

/**  Este código exporta un componente de React llamado `PreviewColdex` que muestra una vista previa de un objeto `ColdexInfo`. 
 * Utiliza el hook `useAppSelector` de la tienda Redux para recuperar el objeto `info` de la sección `pollxColdex` de la tienda. 
 * Luego mapea sobre la matriz `Children` del objeto `info` para representar los temas, preguntas y respuestas anidados. 
 * El componente utiliza iconos de IU de material y clases de CSS para diseñar la vista previa. 
 * */
export const PreviewColdex = () => {
  const { info } = useAppSelector((state: RootState) => state.pollxColdex);
  return (
    <div className="preview" key ={Number(info?.IdColdex)}>
      <h3 className="preview-title" >{info?.Value!}</h3>
      {
      info !== undefined && info?.Children!==undefined &&
      info?.Children!.map((t: ColdexInfo) => {
        return (
          <div >
            <h4 className="preview-topic">{t.Value}</h4>
            {t.Children !== undefined &&
              t.Children.map((p: ColdexInfo, index: number) => {
                return (
                  <>
                    <div className="mdf3" key={index}>
                      <QuestionMarkOutlined fontSize="small" />
                      <h5 className="preview-question">
                        {" "}
                        { p.Value.toUpperCase()}
                      </h5>
                    </div>
                    {p.Children !== undefined &&
                      p.Children.map((e: ColdexInfo, index : number) => {
                        return (
                            <div className="mdf2" key={index}>
                              <QuestionAnswerOutlined fontSize="small" />
                              <h6 className="preview-answer">
                                { e.Value}{" "}
                                <span className="preview-answer-cal">
                                  {e.Calification }
                                </span>
                              </h6>
                            </div>
                        );
                      })}
                  </>
                );
              })}
          </div >
        );
      })}
    </div>
  );
};
