import { useState } from "react";
import { useAppDispatch } from "../../../store/Hooks";
import { CreateUsers } from "../../../store/slices/adminUsers";
import { CreateCategorys } from "../../../store/slices/categoryXColdex";
import { CreateCompanyXIndXColdex } from "../../../store/slices/companyXIndXColdex";
import { postPadrinoxColdex } from "../../../store/slices/padrinoxColdex";
import { CreateColdexConfig } from "../../../store/slices/pollxColdex";
import { postUsersxSecurityxColdex } from "../../../store/slices/securityxUserxColdex";
import { postUsersxColdex } from "../../../store/slices/userxColdex";
import { PARAMETER_TYPE_D, PARAMETER_TYPE_E, PARAMETER_TYPE_P, PARAMETER_TYPE_T } from "../../constants/text-constants";
import { IPermissionColdex } from "../../interfaces/app/IPopUps";
import { DataColdex } from "../../types/Coldex";
import { ColdexConfig } from "../../types/ColdexConfig";
import { Padrino } from "../../types/Padrino";
import { Sector } from "../../types/Sector";
import { SectorxCompany } from "../../types/SectorxCompany";
import { User } from "../../types/User";
import { UserColdex } from "../../types/UserColdex";

/**
 * Esta funcion se encarga de crear los diferentes tipos de coldex de acuerdo a la plantilla cargada
 * @param sectors - Array de Sectores/categorias que se van a crear
 * @param sectorxCompany - Array de SectoresxCompañia que se van a crear
 * @param coldexConfig - Array de 
 * @param usersColdex - An array of User objects representing the users to be created in the system.
 * @param usersxColdex - An array of objects representing the relationship between users and their
 * corresponding Coldex configurations.
 * @param permissions - An array of objects representing the permissions granted to users for accessing
 * certain features or data in the Coldex system.
 * @param participants - An array of Sector objects representing the sectors that will participate in
 * the Coldex configuration.
 * @param padrinoxColdex - An array of objects representing the mentors (padrinos) for the Coldex
 * program.
 * @param {Function} setIsOpen - A function that sets the state of a boolean variable to control the
 * visibility of a modal or dialog box.
 * @returns retorna un objeto con 
 * flagStadisticView: flag para ver estadisticas
 * flagStatePreview:  flag del state preview
 * loadStadistic: flag de carga de estadisticas
 * loadStadisticCompany:   flag de carga de estadisticas de compañias
 * loadStadisticSector:  flag de carga de estadisticas de sectores
 * loadStadisticColdex:  flag de carga de estadisticas de los pollxcoldex
 * flagStateUploadTemplate:  flag de carga del template
 * dataColdex:  objeto con la data finalizada
 * previewP: valor del previewP
 * setPreviewP: Funcion para setear previewP
 * handleColdexCreateConfigSectorial: funcion para crear coldex sectorial
 * handleColdexCreateConfigNetworkLeader: funcion para crear coldex lider de red
 * handlePreview:  funcion para abrir preview
 * handleState: funcion para cancelar 
 * handleColdexCreateConfigPublic:  funcion para crear coldex publico
 */
const useColdexConfig = (
  sectors: Array<Sector>,
  sectorxCompany: Array<SectorxCompany>,
  coldexConfig: Array<ColdexConfig>,
  usersColdex: Array<User>,
  usersxColdex: Array<UserColdex>,
  permissions: Array<IPermissionColdex>,
  participants: Array<Sector>,
  padrinoxColdex: Array<Padrino>,
  setIsOpen: Function
) => {
  const dispatch = useAppDispatch();
  const [flagStadisticView, setFlagStadisticView] = useState<boolean>();
  const [flagStatePreview, setStatePreview] = useState<boolean>();
  const [loadStadistic, setLoadStadisctic] = useState<boolean>();
  const [loadStadisticCompany, setLoadStadiscticCompany] = useState<boolean>();
  const [loadStadisticSector, setLoadStadiscticSector] = useState<boolean>();
  const [loadStadisticColdex, setLoadStadiscticColdex] = useState<boolean>();
  const [previewP, setPreviewP] = useState<boolean>(false);
  const [flagStateUploadTemplate, setFlagStateUploadTemplate] =
    useState<boolean>();
  const [dataColdex, setdataColdex] = useState<DataColdex>();

  /**
   * Esta función maneja la creación de una configuración para un  Coldex de tipo sectorial, incluida la creación
   * categorías, empresas y configuraciones, así como creación de usuarios y permisos.
   */
  const handleColdexCreateConfigSectorial = () => {
    setFlagStadisticView(true);
    setStatePreview(true);
    setLoadStadisctic(true);
    if (
      sectors.length > 0 &&
      sectorxCompany.length > 0 &&
      coldexConfig.length > 0
    ) {
      dispatch(CreateCategorys(sectors)).then(() => {
        setLoadStadiscticSector(true);
        setLoadStadiscticCompany(false);
        dispatch(CreateCompanyXIndXColdex(sectorxCompany)).then(() => {
          setLoadStadiscticSector(false);
          setLoadStadiscticCompany(true);
          dispatch(CreateColdexConfig(coldexConfig))
            .then(() => {
              setLoadStadiscticCompany(false);
              setLoadStadiscticColdex(true);
              let x = {
                sectores: sectors.length,
                compañiasxsector: sectorxCompany.length,
                dimensiones: coldexConfig.filter((f) => {
                  return f.PollType === PARAMETER_TYPE_D;
                }).length,
                tematicas: coldexConfig.filter((f) => {
                  return f.PollType === PARAMETER_TYPE_T;
                }).length,
                preguntas: coldexConfig.filter((f) => {
                  return f.PollType === PARAMETER_TYPE_P;
                }).length,
                enunciados: coldexConfig.filter((f) => {
                  return f.PollType === PARAMETER_TYPE_E;
                }).length,
              };
              setdataColdex(x);

              dispatch(CreateUsers(usersColdex)).then(() => {
                dispatch(postUsersxColdex(usersxColdex)).then(() => {
                  dispatch(postUsersxSecurityxColdex(permissions)).then(() => {
                    if (padrinoxColdex.length > 0) {
                      dispatch(postPadrinoxColdex(padrinoxColdex)).then(() => {
                        setLoadStadisctic(false);
                      });
                    }
                  });
                });
              });
            })
            .finally(() => {
              setTimeout(() => {
                setLoadStadiscticColdex(false);
                setFlagStateUploadTemplate(true);
                setStatePreview(false);
              }, 5000);
            });
        });
      });
    }
  };
    /**
   * Esta función maneja la creación de una configuración para un  Coldex de tipo lider de red, incluida la creación
   * categorías, empresas y configuraciones, así como creación de usuarios y permisos.
   */
  const handleColdexCreateConfigNetworkLeader = () => {
    setFlagStadisticView(true);
    setLoadStadisctic(true);
    if (participants.length > 0 && coldexConfig.length > 0) {
      dispatch(CreateCategorys(participants)).then(() => {
        setLoadStadiscticSector(true);
        dispatch(CreateCompanyXIndXColdex(sectorxCompany)).then(
          () => {
            dispatch(CreateColdexConfig(coldexConfig))
              .then(() => {
                setLoadStadiscticSector(false);
                setLoadStadiscticColdex(true);
                let x = {
                  sectores: participants.length,
                  dimensiones: coldexConfig.filter((f) => {
                    return f.PollType === PARAMETER_TYPE_D;
                  }).length,
                  tematicas: coldexConfig.filter((f) => {
                    return f.PollType === PARAMETER_TYPE_T;
                  }).length,
                  preguntas: coldexConfig.filter((f) => {
                    return f.PollType === PARAMETER_TYPE_P;
                  }).length,
                  enunciados: coldexConfig.filter((f) => {
                    return f.PollType === PARAMETER_TYPE_E;
                  }).length,
                };
                setdataColdex(x);
                setLoadStadisctic(false);
                dispatch(CreateUsers(usersColdex)).then(() => {
                  dispatch(postUsersxColdex(usersxColdex)).then(() => {
                    dispatch(postUsersxSecurityxColdex(permissions)).then(() => {
                      if (padrinoxColdex.length > 0) {
                        dispatch(postPadrinoxColdex(padrinoxColdex)).then(() => {
                          setLoadStadisctic(false);
                        });
                      }
                    });
                  });
                });
              })
              .finally(() => {
                setTimeout(() => {
                  setLoadStadiscticColdex(false);
                  setFlagStateUploadTemplate(true);
                  setStatePreview(false);
                }, 5000);
              });
          }
        )

      });
    }
  };
  /**
   * Esta función maneja la creación de una configuración para un  Coldex de tipo publico, incluida la creación
   * categorías, empresas y configuraciones, así como creación de usuarios y permisos.
   */
  const handleColdexCreateConfigPublic= () => {
    setFlagStadisticView(true);
    setLoadStadisctic(true);
    if (participants.length > 0 && coldexConfig.length > 0) {
      dispatch(CreateCategorys(participants)).then(() => {
        setLoadStadiscticSector(true);
        dispatch(CreateColdexConfig(coldexConfig))
          .then(() => {
            setLoadStadiscticSector(false);
            setLoadStadiscticColdex(true);
            let x = {
              sectores: participants.length,
              dimensiones: coldexConfig.filter((f) => {
                return f.PollType === PARAMETER_TYPE_D;
              }).length,
              tematicas: coldexConfig.filter((f) => {
                return f.PollType === PARAMETER_TYPE_T;
              }).length,
              preguntas: coldexConfig.filter((f) => {
                return f.PollType === PARAMETER_TYPE_P;
              }).length,
              enunciados: coldexConfig.filter((f) => {
                return f.PollType === PARAMETER_TYPE_E;
              }).length,
            };
            setdataColdex(x);
            setLoadStadisctic(false);
              dispatch(postUsersxColdex(usersxColdex)).then(() => {
                dispatch(postUsersxSecurityxColdex(permissions)).then(() => {
                    setLoadStadisctic(false);
                });
              });
          })
          .finally(() => {
            setTimeout(() => {
              setLoadStadiscticColdex(false);
              setFlagStateUploadTemplate(true);
              setStatePreview(false);
            }, 5000);
          });
      });
    }
  };
 /**
  * Esta funcion permite activar la vista del preview
  */
  const handlePreview = () => {
    setPreviewP(true);
  };

/**
 * Esta funciona maneja el popup y cancela el envio
 * @param e - evento click
 */
  const handleState = (e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    setIsOpen(false);
    setFlagStadisticView(false);
  };



  return {
    flagStadisticView,
    flagStatePreview,
    loadStadistic,
    loadStadisticCompany,
    loadStadisticSector,
    loadStadisticColdex,
    flagStateUploadTemplate,
    dataColdex,
    previewP,
    setPreviewP,
    handleColdexCreateConfigSectorial,
    handleColdexCreateConfigNetworkLeader,
    handlePreview,
    handleState,
    handleColdexCreateConfigPublic
  };
};

export default useColdexConfig;
