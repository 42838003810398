import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISectionFormState } from "../../../utils/interfaces/store/IStore";
import { SectionForm } from "../../../utils/types/Section";
import { IPayloadSetdata, IPayloadSetError } from "../../Interfaces";

const initialState: ISectionFormState = {
  sectionsforms: [],
  shortMessage: "",
  resultMessage: "",
  dataError: false,
  open: false,
  isLoading: false
};

  
export const sectionResourceSlice = createSlice({
    name: "SectionForm",
    initialState,
    reducers: {
      startLoadingSectionForm: (state) => {
        state.isLoading = true;
      },
      setSectionForm: (state, {payload}:PayloadAction<IPayloadSetdata<Array<SectionForm>>>) => {
        state.dataError = false;
        state.isLoading = false;
        state.sectionsforms = payload.resultObject;
        state.resultMessage = payload.resultMessage!;
      },
      setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
        state.isLoading = false;
        state.dataError = payload.iserror;
        state.resultMessage = payload.error;
        state.shortMessage = payload.shorterror;
        state.open = payload.open!;
      },
    },
  });
  
  export const { startLoadingSectionForm, setSectionForm, setError } =
    sectionResourceSlice.actions;
  export default sectionResourceSlice.reducer;
  