import { ID_ROL_PADRINO, ID_ROL_USERCOLDEX, TITLE_COLDEX_SECTION_PUBLICO, TITLE_COLDEX_SECTION_RED, TITLE_COLDEX_SECTION_SECTORIAL } from "../../constants/text-constants";
import { IPermissionColdex } from "../../interfaces/app/IPopUps";
import { Coldex } from "../../types/Coldex";
import { ColdexConfig } from "../../types/ColdexConfig";
import { Padrino } from "../../types/Padrino";
import { Sector } from "../../types/Sector";
import { SectorxCompany } from "../../types/SectorxCompany";
import { User } from "../../types/User";
import { UserColdex } from "../../types/UserColdex";
import * as XLSX from "xlsx";
import React, { useState } from "react";
import { ICONFIG_GLOBAL_COLDEX, ISet_ColdexConfig, ISet_Padrinos, ISet_Permissions, ISet_Sectors, ISet_SectorxCompany, ISet_Users, Padrinos, Sectores, SectorxCompañia, Usuarios,ColdexConfig as ColdexConfigColdex,Permissions as PermissionsColdex } from "../../types/UploadTemplateColdex";

/**
 * Funcion que procesa una plantilla de coldex, para subir sus datos
 * @param coldex coldex del que se va a subir la plantilla
 * @param type tipo de coldex
 * @returns retorna un json con los siguien objetos y funciones
 * - file:
 * - sectors:array de Sectores Sectores/Categorias/Areas
 * - sectorxCompany: selaciona sectors con empresas
 * - coldexConfig: array con las dimensiones, areas/sectores, tipos de cuestionarios, descripciones, valores de calificacion, enunciados o preguntas, tematicas. 
 * - permissions: array con la informacion de que usuario responde que y a que empresa evalua
 * - participants:array de Sectores/Categorias/Areas
 * - usersColdex: array de usuarios
 * - usersxColdex: array de relaciones entre usuarios y coldex
 * - padrinoxColdex: array de padrinos del coldex
 * - handleProcessFile:Funcion que procesa el template
 */
const useActionsUploadTemplate = (coldex: Coldex, type: String) => {
        const [file, setFile] = useState<Blob | Object | any>();
        const [sectors, setSectors] = useState<Array<Sector>>([]);
        const [sectorxCompany, setSectorxCompany] = useState<Array<SectorxCompany>>([]);
        const [coldexConfig, setColdexConfig] = useState<Array<ColdexConfig>>([]);
        const [permissions, setPermissions] = useState<Array<IPermissionColdex>>([]);
        const [participants, setParticipants] = useState< Array<Sector>>([]);
        const [usersColdex, setUsersColdex] = useState<Array<User>>([]);
        const [usersxColdex, setUsersxColdex] = useState<Array<UserColdex>>([]);
        const [padrinoxColdex, setPadrinoxColdex] = useState<Array<Padrino>>([]);

        const handleProcessFile = (e: React.ChangeEvent<any>) => {
        let CONFIG_GLOBAL_COLDEX: ICONFIG_GLOBAL_COLDEX|undefined = undefined;
        const promise = new Promise<ICONFIG_GLOBAL_COLDEX>((resolve, reject) => {
          const fileReader: FileReader = new FileReader();
          setFile(e.target.files[0]);
          fileReader.readAsArrayBuffer(e.target.files[0]);
          fileReader.onload = (e: ProgressEvent<FileReader>)=> {
            const bufferArray = e.target!.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            /**NOMBRE DE HOJAS */
            if (type ===  TITLE_COLDEX_SECTION_SECTORIAL) {
              const S_sectors: string = wb.SheetNames[0];
              const S_sectorxCompany: string = wb.SheetNames[1];
              const S_coldexConfig: string = wb.SheetNames[2];
              const S_Users = wb.SheetNames[3];
              const S_permissions: string = wb.SheetNames[4];
              const S_Padrinos = wb.SheetNames[5];
              const RS_Sectors: XLSX.WorkSheet = wb.Sheets[S_sectors];
              const RS_sectorxCompany: XLSX.WorkSheet = wb.Sheets[S_sectorxCompany];
              const RS_coldexConfig: XLSX.WorkSheet = wb.Sheets[S_coldexConfig];
              const RS_users: XLSX.WorkSheet = wb.Sheets[S_Users];
              const RS_permissions: XLSX.WorkSheet = wb.Sheets[S_permissions];
              const RS_Padrinos: XLSX.WorkSheet = wb.Sheets[S_Padrinos];
              const Set_Sectors:ISet_Sectors  = {
                Sectores: XLSX.utils.sheet_to_json(RS_Sectors),
              };
              const Set_SectorxCompany: ISet_SectorxCompany  = {
                SectorxCompañia: XLSX.utils.sheet_to_json(RS_sectorxCompany),
              };
              const Set_ColdexConfig: ISet_ColdexConfig  = {
                ColdexConfig: XLSX.utils.sheet_to_json(RS_coldexConfig),
              };
              
              const Set_Permissions: ISet_Permissions = {
                Permissions: XLSX.utils.sheet_to_json(RS_permissions),
              };
              const Set_Users: ISet_Users = {
                Usuarios: XLSX.utils.sheet_to_json(RS_users),
              };
              const Set_Padrinos: ISet_Padrinos = {
                Padrinos: XLSX.utils.sheet_to_json(RS_Padrinos),
              };

              CONFIG_GLOBAL_COLDEX = {
                Sectors:Set_Sectors,
                SectorxCompany:Set_SectorxCompany,
                ColdexConfig:Set_ColdexConfig,
                Permissions:Set_Permissions,
                Users:Set_Users,
                Padrinos:Set_Padrinos
              }
              resolve(CONFIG_GLOBAL_COLDEX);
            } else if (type === TITLE_COLDEX_SECTION_RED) {
              const S_Categories = wb.SheetNames[0];
              const S_CategoryxCompany = wb.SheetNames[1];
              const S_ColdexConfig = wb.SheetNames[2];
              const S_Users = wb.SheetNames[3];
              const S_Permissions = wb.SheetNames[4];
              const S_Padrinos = wb.SheetNames[5];
              const RS_Categories: XLSX.WorkSheet = wb.Sheets[S_Categories];
              const RS_CategoryxCompany: XLSX.WorkSheet = wb.Sheets[S_CategoryxCompany];
              const RS_coldexConfig: XLSX.WorkSheet = wb.Sheets[S_ColdexConfig];
              const RS_users: XLSX.WorkSheet = wb.Sheets[S_Users];
              const RS_Permissions: XLSX.WorkSheet = wb.Sheets[S_Permissions];
              const RS_Padrinos: XLSX.WorkSheet = wb.Sheets[S_Padrinos];
              const Set_Participants: ISet_Sectors = {
                Sectores: XLSX.utils.sheet_to_json(RS_Categories),
              };
              const Set_SectorxCompany: ISet_SectorxCompany  = {
                SectorxCompañia: XLSX.utils.sheet_to_json(RS_CategoryxCompany),
              };
              const Set_ColdexConfig:ISet_ColdexConfig = {
                ColdexConfig: XLSX.utils.sheet_to_json(RS_coldexConfig),
              };
              const Set_Permissions: ISet_Permissions = {
                Permissions: XLSX.utils.sheet_to_json(RS_Permissions),
              };
              const Set_Users: ISet_Users = {
                Usuarios: XLSX.utils.sheet_to_json(RS_users),
              };
              const Set_Padrinos: ISet_Padrinos= {
                Padrinos: XLSX.utils.sheet_to_json(RS_Padrinos),
              };
              CONFIG_GLOBAL_COLDEX = {
                Sectors:Set_Participants,
                SectorxCompany:Set_SectorxCompany,
                ColdexConfig:Set_ColdexConfig,
                Permissions:Set_Permissions,
                Users:Set_Users,
                Padrinos:Set_Padrinos
              }
              
              resolve(CONFIG_GLOBAL_COLDEX);
            }else if (type === TITLE_COLDEX_SECTION_PUBLICO) {
              const S_Participants = wb.SheetNames[0];
              const S_ColdexConfig = wb.SheetNames[1];
              const S_Users = wb.SheetNames[2];
              const RS_Participants: XLSX.WorkSheet = wb.Sheets[S_Participants];
              const RS_coldexConfig: XLSX.WorkSheet = wb.Sheets[S_ColdexConfig];
              const Set_Participants: ISet_Sectors = {
                Sectores: XLSX.utils.sheet_to_json(RS_Participants),
              };
             
              const Set_ColdexConfig:ISet_ColdexConfig = {
                ColdexConfig: XLSX.utils.sheet_to_json(RS_coldexConfig),
              };
          
              CONFIG_GLOBAL_COLDEX = {
                Sectors:Set_Participants,
                ColdexConfig:Set_ColdexConfig,
            }
              
              resolve(CONFIG_GLOBAL_COLDEX);
            }
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
        promise.then((d: ICONFIG_GLOBAL_COLDEX) => {
          const permissionsColdex: Array<IPermissionColdex> = [];
          const users: Array<User> = [];
          const usersxColdex: Array<UserColdex> = [];
          let padrinosuser : Array<User>=[];
          const padrinos : Array<Padrino>=[];
          if (type ===  TITLE_COLDEX_SECTION_SECTORIAL) {
            let sectorAux: Array<Sector> = [];
            let sectorxCAux: Array<SectorxCompany> = [];
            let coldexConfigAux: Array<ColdexConfig> = [];
            d.Sectors.Sectores.map((sect:Sectores) => {
              let aux: Sector = {
                IDColdex: coldex.id!,
                code: sect.ID_SECTOR!,
                Description: sect.DESCRIPCION,
              };
              sectorAux.push(aux);
              return aux;
            });
            d.SectorxCompany!.SectorxCompañia.map((sect: SectorxCompañia) => {
              let aux: SectorxCompany = {
                IDColdex: coldex.id!,
                IdIndustry: sect.ID_INDUSTRIA,
                CompanyType: sect.TIPO_INDUSTRIA,
                TDOC: sect.TIPO_DOC,
                NDOC: sect.NUMERO_DOC.toString(),
              };
              sectorxCAux.push(aux);
              return aux;
            });
            d.ColdexConfig.ColdexConfig.map((sect: ColdexConfigColdex) => {
              let aux: ColdexConfig = {
                IdColdex: coldex.id,
                IDPoll: sect.ID_POLL,
                IdCategory: sect.ID_SECTOR,
                IdParent: sect.ID_PARENT,
                PollLevel: sect.POLL_LEVEL,
                PollType: sect.POLL_TYPE,
                Value: sect.VALUE,
                Calification:
                  sect.CALIFICACION !== undefined || null ? sect.CALIFICACION : 0,
                IdType: sect.ID_TIPO,
              };
              coldexConfigAux.push(aux);
              return aux;
            });
            d.Permissions!.Permissions.forEach((element: PermissionsColdex) => {
              const polls = element.POLL_LEVEL.toString().split("-");
              polls.forEach((polllevel: any) => {
                permissionsColdex.push({
                  idColdex: coldex.id!,
                  idPoll: element.ID_POLL,
                  idCategory: element.ID_SECTOR!,
                  email: element.EMAIL,
                  pollLevel: Number(polllevel),
                  tdocCompany: element.TDOC.toString(),
                  ndocCompany: element.NDOC.toString(),
                });
              });
            });
            d.Users!.Usuarios.forEach((element: Usuarios) => {
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_USERCOLDEX,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                phone : element.CELULAR.toString(),
                firstTimeLogging:true
              };
              users.push(newUser);
              usersxColdex.push({
                idColdex: coldex.id!,
                state: false,
                email: element.EMAIL,
                idType: element.TYPE,
                position:element.CARGO
              });
            });
            d.Padrinos!.Padrinos.forEach((element:Padrinos)=>{
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_PADRINO,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                firstTimeLogging:true,
                phone : element.CELULAR.toString(),
              };
              padrinosuser.push(newUser)
              padrinos.push({
                idColdex: coldex.id!,
                email: element.EMAIL,
                state: false,
                idType: element.TYPE,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
              })
            })
            const newUsersArray=users.map(user=>{
              const padrinoTemp = padrinosuser.find(padrino=>padrino.email==user.email)
              if(padrinoTemp!==undefined){
                padrinosuser=padrinosuser.filter(padrino=>padrino.email!==padrinoTemp.email)
                  return padrinoTemp
              }else{
                  return user
              }
          })

            setUsersColdex(newUsersArray.concat(padrinosuser));
            setSectors(sectorAux);
            setSectorxCompany(sectorxCAux);
            setColdexConfig(coldexConfigAux);
            setPermissions(permissionsColdex);
            setUsersxColdex(usersxColdex);
            setPadrinoxColdex(padrinos);
          } else if (type ===  TITLE_COLDEX_SECTION_RED) {
            let participantsAux: Array<Sector> = [];
            let coldexConfigAux: Array<ColdexConfig> = [];
            let sectorxCAux: Array<SectorxCompany> = [];
            d.Sectors.Sectores.map((sect: Sectores) => {
              let aux: Sector = {
                IDColdex: coldex.id!,
                code: sect.ID_CATEGORIA!,
                Description: sect.DESCRIPCION,
              };
              participantsAux.push(aux);
              return aux;
            });
            d.SectorxCompany!.SectorxCompañia.map((sect: SectorxCompañia) => {
              let aux: SectorxCompany = {
                IDColdex: coldex.id!,
                IdIndustry: sect.ID_INDUSTRIA,
                CompanyType: sect.TIPO_INDUSTRIA,
                TDOC: sect.TIPO_DOC,
                NDOC: sect.NUMERO_DOC.toString(),
              };
              sectorxCAux.push(aux);
              return aux;
            });

            d.ColdexConfig.ColdexConfig.map((sect: ColdexConfigColdex) => {
              let aux: ColdexConfig = {
                IdColdex: coldex.id,
                IDPoll: sect.ID_POLL,
                IdCategory: sect.ID_PARTICIPANTE,
                IdParent: sect.ID_PARENT,
                PollLevel: sect.POLL_LEVEL,
                PollType: sect.POLL_TYPE,
                Value: sect.VALUE,
                Calification:
                  sect.CALIFICACION !== undefined || null ? sect.CALIFICACION : 0,
                IdType: sect.ID_TIPO,
              };
              coldexConfigAux.push(aux);
              return aux;
            });
            d.Users!.Usuarios.forEach((element: Usuarios) => {
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_USERCOLDEX,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                phone : element.CELULAR.toString(),
                firstTimeLogging:true
              };
              users.push(newUser);
              usersxColdex.push({
                idColdex: coldex.id!,
                state: false,
                email: element.EMAIL,
                idType: element.TYPE,
                position:element.CARGO
              });
            });
            d.Permissions!.Permissions.forEach((element: PermissionsColdex) => {
              const polls = element.POLL_LEVEL.toString().split("-");
              polls.forEach((polllevel: string) => {
                permissionsColdex.push({
                  idColdex: coldex.id!,
                  idPoll: element.ID_POLL,
                  idCategory: element.ID_PARTICIPANTE!,
                  email: element.EMAIL,
                  pollLevel: Number(polllevel),
                  tdocCompany: element.TDOC.toString(),
                  ndocCompany: element.NDOC.toString(),
                });
              });
            });
            d.Padrinos!.Padrinos.forEach((element:Padrinos)=>{
              let newUser: User = {
                id: null!,
                name: element.NOMBRES,
                lastName: element.APELLIDOS,
                email: element.EMAIL,
                password: "",
                state: true,
                idRol: ID_ROL_PADRINO,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
                firstTimeLogging:true,
                phone : element.CELULAR.toString(),
              };
              padrinosuser.push(newUser)
              padrinos.push({
                idColdex:  coldex.id!,
                email: element.EMAIL,
                state: false,
                idType: element.TYPE,
                tdocCompany: element.TDOC.toString(),
                ndocCompany: element.NDOC.toString(),
              })
            })
            const newUsersArray=users.map(user=>{
              const padrinoTemp = padrinosuser.find(padrino=>padrino.email==user.email)
              if(padrinoTemp!==undefined){
                padrinosuser=padrinosuser.filter(padrino=>padrino.email!==padrinoTemp.email)
                  return padrinoTemp
              }else{
                  return user
              }
          })
            setParticipants(participantsAux);
            setUsersColdex(newUsersArray.concat(padrinosuser));
            setSectorxCompany(sectorxCAux);
            setColdexConfig(coldexConfigAux);
            setPermissions(permissionsColdex);
            setUsersxColdex(usersxColdex);
            setPadrinoxColdex(padrinos);
          }else if(type===TITLE_COLDEX_SECTION_PUBLICO){
            let participantsAux: Array<Sector> = [];
            let coldexConfigAux: Array<ColdexConfig> = [];
            let permissonAux : Array<IPermissionColdex>=[]
            d.Sectors.Sectores.map((sect: Sectores) => {
              let aux: Sector = {
                IDColdex: coldex.id!,
                code: sect.ID_PARTICIPANTE!,
                Description: sect.DESCRIPCION,
              };
              participantsAux.push(aux);
              return aux;
            });
            d.ColdexConfig.ColdexConfig.map((sect: ColdexConfigColdex) => {
              let aux: ColdexConfig = {
                IdColdex: coldex.id,
                IDPoll: sect.ID_POLL,
                IdCategory: sect.ID_PARTICIPANTE,
                IdParent: sect.ID_PARENT,
                PollLevel: sect.POLL_LEVEL,
                PollType: sect.POLL_TYPE,
                Value: sect.VALUE,
                Calification:
                  sect.CALIFICACION !== undefined || null ? sect.CALIFICACION : 0,
                IdType: 'DEFAULT',
              };
              coldexConfigAux.push(aux);
              return aux;
            });
            usersxColdex.push({
              idColdex: coldex.id!,
              state: false,
              email:'useraue',
              idType:'DEFAULT',
              position:'GERENTE'
            });
            permissonAux= coldexConfigAux.filter(conf=>conf.PollType=="Tematica").map((conf)=>{
              return ({
                idColdex:coldex.id!,
                idPoll: conf.IDPoll!,
                idCategory: conf.IdCategory!,
                email:'useraue',
                pollLevel: conf.PollLevel!,
                tdocCompany:'TDOC', 
                ndocCompany:'DEFAULT'
              })
            })
            setParticipants(participantsAux);
            setColdexConfig(coldexConfigAux);
            setPermissions(permissonAux);
            setUsersxColdex(usersxColdex);
          }
        });
      };
      return {file,sectors,sectorxCompany,coldexConfig,permissions,participants,usersColdex,usersxColdex,padrinoxColdex, handleProcessFile}
      
}

export default useActionsUploadTemplate;