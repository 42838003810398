import { Add } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import {
  CREATE_ACTION,
  TITLE_COLDEX_SECTION_PUBLICO
} from "../../../utils/constants/text-constants";
import useActionsColdex from "../../../utils/hooks/coldex/useActionsColdex";
import useColdex from "../../../utils/hooks/coldex/useColdex";
import usePermissionsColdex from "../../../utils/hooks/permissions/usePermissionsColdex";
import Loader from "../../Loader/Loader";
import { TableColdex } from "../../Tables/Coldex/TableColdex/TableColdex";
import { PageTitle } from "../../Titles/PageTitle";
import usePermissionsAdmin from "../../../utils/hooks/permissions/usePermissionsAdmin";
import { CreateColdexPopUp } from "../../PopUps/Coldex/CreateColdexPopUp";
import React, { useEffect } from "react";
import { getColdex } from "../../../store/slices/coldex";

/**
 *  Este código exporta un componente de React llamado `Public` utilizando el componente de orden superior `React.memo`. El componente representa un título de página, una tabla con los coldex de tipo publico y un botón para crear un nuevo elemento en la tabla. También utiliza varios hooks personalizados para recuperar datos de la tienda Redux y manejar los permisos de los usuarios. El hooks `useEffect` se usa para enviar una acción para recuperar datos del servidor cuando se monta el componente. */
export const Public = React.memo(() => {
  const { isLoading, dataError } = useAppSelector(
    (state: RootState) => state.coldex
  );
  const { permissionRolUser:permission } = useAppSelector((state: RootState) => state.permission);
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const { permissionAdminColdex } = usePermissionsAdmin(permission);
  const { flagCreate } = usePermissionsColdex(permissionAdminColdex);
  const { handleSetCreateColdex, open, action, setOpen } = useActionsColdex();
  const { publicColdex } = useColdex();
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getColdex())
  }, [])
  
  return (
    <>
      <PageTitle
        title={`${language.views.coldexView.titles.TITLE_COLDEX} ${language.views.coldexView.titles.TITLE_COLDEX_SECTION_PUBLICO}`}
        icon={TITLE_COLDEX_SECTION_PUBLICO}
        key={TITLE_COLDEX_SECTION_PUBLICO}
      />
      {isLoading === false ? (
        <>
          <TableColdex
            content={publicColdex}
            type={TITLE_COLDEX_SECTION_PUBLICO}
            key={TITLE_COLDEX_SECTION_PUBLICO}
          ></TableColdex>
          <div className="options-container">
            {flagCreate && (
              <button onClick={() => handleSetCreateColdex()}>
                <Add fontSize="medium" />
                {language.views.coldexView.publicView.buttons.BTN_CREATE_COLDEX}
              </button>
            )}
          </div>
          {open && action === CREATE_ACTION && (
            <CreateColdexPopUp
              type={TITLE_COLDEX_SECTION_PUBLICO}
              isOpen={open}
              setIsOpen={setOpen}
            ></CreateColdexPopUp>
          )}
        </>
      ) : (
        <Loader></Loader>
      )}
      {dataError && <></>}
    </>
  );
});
