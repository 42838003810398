import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { getColdexConfig } from "../../../store/slices/pollxColdex";
import { RootState } from "../../../store/Store";
import { SELECTED_ACTION } from "../../../utils/constants/text-constants";
import usePreviewActionsColdex from "../../../utils/hooks/coldex/usePreviewActionsColdex";
import usePreviewColdex from "../../../utils/hooks/coldex/usePreviewColdex";
import { IPreviewData, IPreviewInterface } from "../../../utils/interfaces/app/IPopUps";
import { Category, Levels } from "../../../utils/types/Polls";
import Loader from "../../Loader/Loader";
import { PreviewColdex } from "../../Preview/PreviewColdex";

/**  Este es un componente funcional llamado `PreviewColdexPopUp` que toma un objeto con tres propiedades (`flag`, `coldex` y `setIsOpen`) 
 * como accesorios. Utiliza varios hooks personalizados. (`useAppSelector`, `usePreviewColdex` y `usePreviewActionsColdex`) para recuperar 
 * datos de la tienda Redux y realizar algunas acciones. También utiliza el hook `useEffect` para enviar una acción para recuperar datos del 
 * servidor cuando se monta el componente. El componente devuelve un elemento JSX que muestra una ventana emergente con varios botones y 
 * campos de texto, así como un componente de carga mientras se recuperan los datos. La ventana emergente muestra una vista previa de un 
 * formulario de Coldex basado en las selecciones del usuario de tipo, categoría, encuesta y tema. 
 * */

export const PreviewColdexPopUp = ({
  flag,
  coldex,
  setIsOpen,
}: IPreviewInterface) => {
  const {pollxColdex, isLoading, info } = useAppSelector(
    (state: RootState) => state.pollxColdex
  );
  const { data } = usePreviewColdex(pollxColdex);
  const {language} = useAppSelector((state:RootState) => state.multiLanguage);
  const {
    category,
    type,
    polls,
    poll,
    levels,
    dimension,
    categories,
    hanndleSelectCategory,
    hanndleColdexType,
    handleType,
    handleclosePopUp,
    setDimension,
  } = usePreviewActionsColdex(coldex, setIsOpen);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getColdexConfig(coldex.id!));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={flag ? "open-PopUp" : "close-PopUp"}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="popUp-Content wide">
          <div className="popUp-Content__Close">
            <Close fontSize="medium" onClick={() => handleclosePopUp()}></Close>
          </div>
          <div className="popUp-Content__title">
            <h3>
              {`${language.components.popUps.preview.titles.TITLE_FORM_PREVIEW_COLDEX} ${coldex.coldexName!.toUpperCase()}`}
            </h3>
          </div>

          <div className="popUp-Content__actions actions2">
            <div className="actions2-column1">
              <h3>{language.components.popUps.preview.descriptions.TYPE_DESCRIPTION}</h3>
            </div>
            <div className="actions2-column2">
              {data.map((selected : IPreviewData) => {
                return (
                  <button
                    className={type === selected.idType ? SELECTED_ACTION : ""}
                    onClick={(e) => {
                      handleType(selected);
                    }}
                    key={selected.idType}
                  >
                    {selected.idType}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="popUp-Content__actions actions2">
            <div className="actions2-column1">
              <h3>{language.components.popUps.preview.descriptions.CATEGORY_DESCRIPTION}</h3>
            </div>
            <div className="actions2-column2">
              
              {
              categories !== undefined &&
              categories.map((selected : Category) => {
                return (
                  <button
                    className={
                      category === selected.idCategory ? SELECTED_ACTION : ""
                    }
                    onClick={(e) => {
                      hanndleSelectCategory(selected);
                    }}
                    key={selected.idCategory}
                  >
                    {selected.description}
                  </button>
                );
              })}
            </div>
          </div>

          <div className="popUp-Content__actions actions2">
            <div className="actions2-column1">
              <h3>{language.components.popUps.preview.descriptions.POLL_DESCRIPTION}</h3>
            </div>
            <div className="actions2-column2">
              {polls !== undefined &&
                polls.map((selected) => {
                  return (
                    <button
                      className={poll === selected.idPoll ? SELECTED_ACTION : ""}
                      onClick={(e) => {
                        hanndleColdexType(selected);
                      }}
                      key={selected.idPoll}
                    >
                      {selected.pollDescription}
                    </button>
                  );
                })}
            </div>
          </div>

          <div className="popUp-Content__actions actions2">
            <div className="actions2-column1">
              <h3>{language.components.popUps.preview.descriptions.TOPICS_DESCRIPTION}</h3>
            </div>
            <div className="actions2-column2">
              {levels !== undefined &&
                levels.map((selected : Levels) => {
                  return (
                    <button
                      className={
                        dimension === selected.pollLevel.toString() ? SELECTED_ACTION : ""
                      }
                      onClick={(e) => setDimension(selected.pollLevel.toString())}
                      key={selected.pollLevel}
                    >
                      {selected.value}
                    </button>
                  );
                })}
            </div>
          </div>
          {info !== null && <PreviewColdex></PreviewColdex>}
        </div>
      )}
    </div>
  );
};
