import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { getColdex, getColdexPadrino } from "../../../store/slices/coldex";
import { RootState } from "../../../store/Store";
import { FILTER_COLDEX_PUBLIC_TYPE, FILTER_COLDEX_RED_TYPE, FILTER_COLDEX_SECTORIAL_TYPE } from "../../constants/text-constants";
import { Coldex } from "../../types/Coldex";

/**
 * Funcion que filtra los coldex desde redux, los filtra por tipo, tambien se hace la peticion que trae los coldex de acuerdo al rol, email
 * @returns Retorna 3 listas coldexNetwork, coldexSectorial,publicColdex, cada una es de tipo coldex, es el resultado del filtrado
 */
const useColdex = () => {
    const {coldexs} = useAppSelector( (state:RootState) => state.coldex );
    const { user} = useAppSelector( (state:RootState) => state.loginuser );
    const [coldexNetwork, setColdexNetwork] = useState<Array<Coldex>>()
    const [coldexSectorial, setColdexSectorial] = useState<Array<Coldex>>()
    const [publicColdex, setPublicColdex] = useState<Array<Coldex>>()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if(coldexs !== undefined && coldexs.length!==0){
            let auxColdexNetwork : Array<Coldex> = coldexs.filter((f: Coldex) =>{return f.coldexType === FILTER_COLDEX_RED_TYPE })
            let auxColdexSectorial : Array<Coldex> = coldexs.filter((f: Coldex) =>{return f.coldexType === FILTER_COLDEX_SECTORIAL_TYPE })
            let auxColdexPublic : Array<Coldex> = coldexs.filter((f: Coldex) =>{return f.coldexType === FILTER_COLDEX_PUBLIC_TYPE })
            setColdexNetwork(auxColdexNetwork)
            setColdexSectorial(auxColdexSectorial)
            setPublicColdex(auxColdexPublic)
        }
    }, [coldexs])


    useEffect(() => {
    if(coldexs.length === 0){
        if(user.rol==2){
            dispatch(getColdexPadrino(user.email!))
        }else{
            dispatch(getColdex())
        }   
    }
    }, [])
    
    return {coldexNetwork, coldexSectorial,publicColdex}
}



export default useColdex;