import {  Outlet } from 'react-router-dom'
import { Public } from '../../../components/Coldex/components/Public'


const ColdexPublicView = () => {
  return (
    <div className="container">
        <Public/>
        <Outlet></Outlet>
    </div>
   
  )
}

export default ColdexPublicView;
