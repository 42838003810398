import { useState } from "react";
import {
  CREATE_ACTION,
  DELETE_ACTION,
  MONOTORING_REPORT_ACTION,
  PREVIEW_ACTION,
  REPORT_ACTION,
  UPDATE_ACTION,
  UPLOAD_ACTION,
} from "../../constants/text-constants";
import { Coldex } from "../../types/Coldex";
/**
 * Hook para el manejo de los datos decoldex,permite cuado abrir o cerrar popups, tambien tiene las activaciones para:
 * borrar coldex, actualizar coldex, crear coldex, cargar plantilla del coldex, preview del coldex, ver reportes, ver reportes
 * de seguimiento, entrar a ver detalles del coldex
 *@returns Un objeto que contiene las siguientes propiedades y sus respectivos valores:
 * - open: el valor para abrir modal
 * - setOpen:funcion para modificar open
 * - action: el valor de la seccion
 * - setAction: funcion para modificar action
 * - setColdex: funcion para modificar coldex
 * - coldex: coldex que se va a manejar
 * -handleSetReports: funcion para ver reportes
 * -handleSetMonitoringReports: funcion para ver reportes de seguimiento
 * -handleSetDeleteColdex: funcion para borrar coldex
 * -handleSetUpdateColdex: funcion para actualizar coldex
 * -handleSetUploadTemplete: funcion para cargar plantilla del coldex
 * -handleSetPreviewColdex: funcion para ver preview del coldex
 * -handleSetCreateColdex: funcion para crear coldex
 * -handleSetDetails: funcion para entrar a ver detalles del coldex
 
 */
const useActionsColdex = () => {
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<string>();
  const [coldex, setColdex] = useState<Coldex>();
  const [idcoldex, setIdColdex] = useState<number>();
/**
      * Esta funcion sets el estado coldex y la accion en DELETE_ACTION tanbien open en true, para borrar un coldex
      * @param {Coldex} coldex - coldex que se desea borrar
      */
  const handleSetDeleteColdex = (coldex: Coldex) => {
    setColdex(coldex);
    setAction(DELETE_ACTION);
    setOpen(true);
  };
  /**
     * Esta funcion sets el estado "open" a verdadero y action en "UPDATE_ACTION".Tambien sets el estado coldex
     * al parametro recibido, para actualizar coldex
     * @param {Coldex} coldex - coldex que se va a actualizar .
     */
  const handleSetUpdateColdex = (coldex: Coldex) => {
    setColdex(coldex);
    setAction(UPDATE_ACTION);
    setOpen(true);
  };
  /**
    * Esta funcion sets el estado "open" a verdadero y action en "CREATE_ACTION", para crear coldex.
    */
  const handleSetCreateColdex = () => {
    setOpen(true);
    setAction(CREATE_ACTION);
  };

 /**
  *Funcion para subir plantilla de coldex, solo lo hace si el estado del coldex es 'configuration'. sets la accion en UPLOAD_ACTION
  * @param {Coldex} coldex -Coldex del que se desea subir la planilla
  */
  const handleSetUploadTemplete = (coldex: Coldex) => {
    if(coldex.status=='configuration'){
      setColdex(coldex);
      setAction(UPLOAD_ACTION);
      setOpen(true);
    }
  };
  /**
  *Funcion para ver preview de coldex,sets open en true, y set el estado coldex, la accion en PREVIEW_ACTION
  * @param {Coldex} coldex -Coldex del que se desea ver su preview
  */
  const handleSetPreviewColdex = (coldex: Coldex) => {
    setColdex(coldex);
    setAction(PREVIEW_ACTION);
    setOpen(true);
  };
/**
  *Funcion para  ver preview los detalles coldex,sets open en true, y set el estado idcoldex.
  * @param {Coldex} coldex -Coldex del que se desea ver su preview
  */
  const handleSetDetails = (coldex: Coldex) => {
    setIdColdex(coldex.id);
  };
/**
  *Funcion para  ver reportes del coldex,sets open en true, y set el estado idcoldex, la accion en REPORT_ACTION
  * @param {Coldex} coldex -Coldex del que se desea ver su preview
  */
  const handleSetReports = (coldex: Coldex) => {
    setColdex(coldex);
    setIdColdex(coldex.id);
    setAction(REPORT_ACTION);
    setOpen(true);
  };
  /**
  *Funcion para  ver reportes del coldex,sets open en true, la accion en MONOTORING_REPORT_ACTION.
  * @param {Coldex} coldex -Coldex del que se desea ver su preview
  */
  const handleSetMonitoringReports = (coldex: Coldex) => {
    setColdex(coldex);
    setAction(MONOTORING_REPORT_ACTION);
    setOpen(true);
   
  };

  return {
    open,
    idcoldex,
    action,
    coldex,
    setOpen,
    setAction,
    handleSetReports,
    handleSetMonitoringReports,
    handleSetDeleteColdex,
    handleSetUpdateColdex,
    handleSetUploadTemplete,
    handleSetPreviewColdex,
    handleSetCreateColdex,
    handleSetDetails,
  };
};

export default useActionsColdex;
