import { useEffect, useState } from "react";

/**
 * Este custom hook en TypeScript proporciona funciones para buscar y filtrar datos en un
* tabla, así como el manejo de la paginación.
 * @param itemList - Una matriz de elementos que se usarán para completar una tabla o lista.
 * @returns  devuelve un objeto que contiene varias variables y
  * funciones utilizadas para manejar la funcionalidad de paginación y búsqueda en una lista de elementos. Éstas incluyen
  * `page`, `rowsPerPage`, `rows`, `buscado`, `setRows`, `handleChangePage`, `cancelSearchResource`,
  * `requestSearchResource`, `handleChangeRowsPerPage`, `cancelSearch` y `requestSearch`.
 */
const useActionsFunctions = (itemList: Array<any>) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState(itemList === undefined ? [] : itemList);
  const [searched, setSearched] = useState<string>("");
  const [open,setOpen] = useState(false)

   useEffect(() => {
    setRows(itemList)
   }, [itemList])
  
/**
 * La función filtra una lista de elementos en función de un valor buscado y actualiza el estado con el
  * resultados filtrados.
 * @param {string} searchedVal - Una cadena que representa el valor que se busca en el
  * lista de articulos.
 */
  const requestSearch = (searchedVal: string) => {
      const filteredRows = itemList?.filter((row) => {
        if (row.idPoll !== undefined)
          return row?.idPoll?.toLowerCase().includes(searchedVal.toLowerCase());
        if (row.email !== undefined)
          return row?.email?.toLowerCase().includes(searchedVal.toLowerCase()) || row?.name?.toLowerCase().includes(searchedVal.toLowerCase());
        if (row.companyName !== undefined)
          return row?.companyName?.toLowerCase().includes(searchedVal.toLowerCase()) || row?.ndoc?.toLowerCase().includes(searchedVal.toLowerCase());
        if (row.name !== undefined)
          return row?.name?.toLowerCase().includes(searchedVal.toLowerCase());
        if(row.shortName !== undefined)
             return row?.shortName?.toLowerCase().includes(searchedVal.toLowerCase());
        if (row.coldexName !== undefined)
          return row?.coldexName?.toLowerCase().includes(searchedVal.toLowerCase());
        return null;
      })
      setRows(filteredRows);
    
  };

/**
 * Esta función filtra una lista de elementos en función de un valor buscado y actualiza el estado con el
  * resultados filtrados.
 * @param {string} searchedVal - searchedVal es un parámetro de cadena que representa el valor que el
  * el usuario está buscando. Se utiliza para filtrar la matriz itemList en función de si el shortName o
  * La propiedad de tipo de cada objeto en la matriz incluye la cadena searchedVal. La matriz filteredRows es usada 
  * para guardar  el resultado de la busqueda y a partir del uso de 'useState' se actualiza el valor de "rows"
 */
  const requestSearchResource=(searchedVal: string)=>{
      const filteredRows= itemList?.filter((row)=>{
        if(row.shortName !== undefined)
          return row?.shortName?.toLowerCase().includes(searchedVal.toLowerCase());
        if(row.type !== undefined)
          return row?.type?.toLowerCase().includes(searchedVal.toLowerCase());
      })
      setRows(filteredRows);
  }

 /**
  * La función "cancelSearch" borra el valor buscado y solicita una búsqueda con una cadena vacía.
  */
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
/**
 * La función cancela una solicitud de recurso de búsqueda y restablece el estado buscado.
 */
  const cancelSearchResource = () => {
    setSearched("");
    requestSearchResource(searched)
  };

/**
 * Esta función maneja un cambio en la página actualizando el número de página actual.
 * @param {React.MouseEvent<HTMLButtonElement> | null} event - El parámetro de evento es un MouseEvent que
  * se activa cuando el usuario hace clic en un elemento de botón. Contiene información sobre el evento, como
  * como el elemento de destino y la posición del cursor del ratón.
 * @param {number} newPage - El parámetro newPage es un número que representa el número de página que el
  * el usuario desea navegar. Este parámetro se pasa a la función handleChangePage cuando el usuario
  * hace clic en un botón o interactúa con un componente de paginación. A continuación, la función actualiza el estado de
  * la variable de página para reflejar el nuevo número de página.
 */
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

/**
  * Esta función maneja un cambio en el número de filas por página y actualiza el estado en consecuencia.
 * @param event - El parámetro `event` es un `ChangeEvent` de React que se activa cuando el valor de un elemento Html
  * se cambia. Puede ser del tipo `HTMLInputElement` o `HTMLTextAreaElement`.
  * En este caso, se utiliza para obtener el nuevo valor de las filas por elemento de entrada de la páginación usada en 
  * visual del componente que renderiza [rows] 
 */
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return { page, rowsPerPage, rows, searched, setRows, handleChangePage, cancelSearchResource, requestSearchResource,handleChangeRowsPerPage, cancelSearch, requestSearch }
}

export default useActionsFunctions;