import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {  IRowDataColdexState } from '../../../utils/interfaces/store/IStore'
import { RowDataColdex } from '../../../utils/types/RowDataColdex'
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces'

const initialState: IRowDataColdexState  = {
  rowDateColdex: [],
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}

export const rowDataColdexLRSlice = createSlice({
  name: 'rowDataColdexLR',
  initialState,
  reducers: {
    startLoading:(state)=>{
        state.isLoading=true
    },
    setRowDataColdexLRSlice:(state,{payload}:PayloadAction<IPayloadSetdata<Array<RowDataColdex>>>)=>{
        state.isLoading=false
        state.rowDateColdex = payload.resultObject;
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading=false
      state.dataError = payload.iserror;
      state.resultMessage = payload.error;
      state.shortMessage = payload.shorterror;
      state.open = payload.open!;
    },
    endLoading:(state)=>{
      state.isLoading=false
    },
    setAddlocalRowDataColdexLRSlice:(state,{payload}:PayloadAction<Array<RowDataColdex>>)=>{
      state.rowDateColdex=state.rowDateColdex.concat(payload)
    }
  },
})

// Action creators are generated for each case reducer function
export const {  startLoading,setRowDataColdexLRSlice,setError,endLoading,setAddlocalRowDataColdexLRSlice} = rowDataColdexLRSlice.actions

export default rowDataColdexLRSlice.reducer