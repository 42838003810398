import { useEffect } from "react";
import { Outlet} from "react-router";
import {  Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/Hooks";
import { refreshTokenUser } from "../../store/slices/loginUser";
import { RootState } from "../../store/Store";
import { LOGIN_ROUTE } from "../../utils/constants/routes-constants";
import { logout } from '../../store/Store';

/**
  * El componente RouterContainer verifica si el usuario ha iniciado sesión y actualiza su token si es necesario, y lo redirige a la página de inicio de sesión si no ha iniciado sesión.
  * @returns Se devuelve el componente `RouterContainer`. Si el usuario ha iniciado sesión, devuelve el componente `Outlet`, que representa las rutas secundarias. Si el usuario no ha iniciado sesión, devuelve el componente `Navegar`, que redirige al usuario a la página de inicio de sesión.
  */
const RouterContainer = () => {
  const { isLogged, expiresIn, user } = useAppSelector((state: RootState) => state.loginuser);
  const dispatch = useAppDispatch()
 
  useEffect(() => {
    // refresh Token 
    let interval = setInterval(()=>{
     if(isLogged){
      dispatch(refreshTokenUser(user.email!))
     }
    },((expiresIn-600)*1000))
    return () =>{
      clearInterval(interval)
    }
  }, [isLogged])

  useEffect(() => {
    if(localStorage.getItem('date') !== null ){
        const dateExprires = localStorage.getItem('date')
        const date = new Date(dateExprires!)
        const currentDate = new Date()
        if(date <= currentDate){
            dispatch(logout());
        }
    }else{
        dispatch(logout());
    }
}, [])

  return isLogged ? <Outlet /> :   <Navigate to={LOGIN_ROUTE} />    ;
};

export default RouterContainer;
 