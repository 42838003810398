import { SpinnerCircular } from "spinners-react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import useFormActionsColdex from "../../../utils/hooks/coldex/useFormActionsColdex";
import { IPopUpColdex } from "../../../utils/interfaces/app/IPopUps";
import { Parameter } from "../../../utils/types/Parameter";
import TextField from "@mui/material/TextField";
import { useForm, SubmitHandler } from "react-hook-form";
import { IFormInputsColdex } from "../../../utils/interfaces/app/IFormInputs";
import {  useState,useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  CONFIG,
  FILTER_COLDEX_RED_TYPE,
  OPTION_COMPANY_DEFAULT,
} from "../../../utils/constants/text-constants";
import useCompanies from "../../../utils/hooks/administration/useCompanies";
/**
  * Este es un componente de TypeScript React que representa un formulario para crear o actualizar un objeto Coldex,
  * con varios campos de entrada y botones.
  * @param {IPopUpColdex} - - `setIsOpen`: una función para establecer el estado de si la ventana emergente está abierta o
  * no
  * @returns Un componente funcional de React que genera un formulario para crear o actualizar un objeto Coldex.
  * El formulario incluye campos de entrada para el nombre de Coldex, año, país, fechas de inicio y fin, descripción,
  * y categoría de empresa (si el tipo de Coldex es FILTER_COLDEX_RED_TYPE). El componente también utiliza varios
  * ganchos para administrar el estado y recuperar datos de la tienda Redux.
  */
export const ColdexForm = ({ setIsOpen, coldex, type }: IPopUpColdex) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInputsColdex>();
  const { companies } = useCompanies();
 
  useEffect(() => {
    if (coldex !== undefined) {
      setValue('companyF', (`${coldex?.tdoc} - ${coldex?.ndoc}`))
    } else {
      setValue('companyF', "null - null")
    }
    setallowRender(true)
  }, [])
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [allowRender, setallowRender] = useState<boolean>(false);
  const { isLoading } = useAppSelector((state: RootState) => state.coldex);
  const { parameters } = useAppSelector((state: RootState) => state.parameters);
  const { companyColdexCategory } = useAppSelector((state: RootState) => state.companyColdexCategory);
  const { language } = useAppSelector(
    (state: RootState) => state.multiLanguage
  );
  const { parametersColdexType } = parameters;
  const {
    handleCreateColdex,
    handleUpdateColdex,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useFormActionsColdex(setIsOpen, coldex, type);
  const formSubmitHandler: SubmitHandler<IFormInputsColdex> = (
    data: IFormInputsColdex
  ) => {
    coldex !== undefined ? handleUpdateColdex(data,companyColdexCategory!) : handleCreateColdex(data);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(formSubmitHandler)}
        className="popUp-Content__form"
      >
        <div className="popUp-Content__form_field">
          <label>{language.components.forms.labels.LABEL_NAME}</label>
          <TextField
            placeholder={language.components.forms.labels.LABEL_NAME}
            type="text"
            defaultValue={coldex?.coldexName}
            error={!!errors?.coldexNameF}
            helperText={errors?.coldexNameF ? errors.coldexNameF.message : null}
            {...register("coldexNameF", { required: "Requerido" })}
          ></TextField>
        </div>
        <div className="popUp-Content__form_field">
          <label>{language.components.forms.coldex.labels.LABEL_YEAR}</label>
          <TextField
            placeholder={language.components.forms.coldex.labels.LABEL_YEAR}
            defaultValue={coldex?.coldexYear}
            type="number"
            error={!!errors?.coldexYearF}
            helperText={errors?.coldexYearF ? errors.coldexYearF.message : null}
            {...register("coldexYearF", { required: "Requerido" })}
          ></TextField>
        </div>
        <div className="popUp-Content__form_field">
          <label>{language.components.forms.coldex.labels.LABEL_COUNTRY}</label>
          <TextField
            placeholder={language.components.forms.coldex.labels.LABEL_COUNTRY}
            type="text"
            defaultValue={coldex?.coldexCountry}
            error={!!errors?.coldexCountryF}
            helperText={
              errors?.coldexCountryF ? errors.coldexCountryF.message : null
            }
            {...register("coldexCountryF", { required: "Requerido" })}
          ></TextField>
        </div>
        <div className="popUp-Content__form_field">
          <label>{language.components.forms.labels.LABEL_TYPE}</label>
          <TextField
            placeholder={language.components.forms.labels.LABEL_TYPE}
            value={type}
            disabled
          >
            {parametersColdexType !== undefined &&
              parametersColdexType.map((p: Parameter) => {
                return (
                  <option key={Number(p.value)} value={String(p.value)}>
                    {p.value}
                  </option>
                );
              })}
          </TextField>
        </div>
        <div className="popUp-Content__form_field">
          <label>
            {language.components.forms.coldex.labels.LABEL_START_DATE}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              inputFormat="YYYY-MM-DD"
              onChange={(newValue: any) => {
                setStartDate(dayjs(newValue).format("YYYY-MM-DD"));
                endDate !== undefined && endDate !== "" && setIsDisabled(false);
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="popUp-Content__form_field">
          <label>
            {language.components.forms.coldex.labels.LABEL_END_DATE}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              inputFormat="YYYY-MM-DD"
              minDate={startDate}
              onChange={(newValue: any) => {
                setEndDate(dayjs(newValue).format("YYYY-MM-DD"));
                startDate !== undefined &&
                  startDate !== "" &&
                  setIsDisabled(false);
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="popUp-Content__form_field">
          <label>{language.components.forms.labels.LABEL_DESCRIPTION}</label>
          <TextField
            placeholder={language.components.forms.labels.LABEL_DESCRIPTION}
            multiline
            rows={3}
            inputProps={{ maxLength: 300 }}
            defaultValue={coldex?.description}
            error={!!errors?.descriptionF}
            helperText={
              errors?.descriptionF ? errors.descriptionF.message : null
            }
            {...register("descriptionF", { required: "Requerido" })}
          ></TextField>
        </div>
        {
        type == FILTER_COLDEX_RED_TYPE.toUpperCase() && allowRender&&(
          <>
            <div className="popUp-Content__form_field">
              <label>{"categoria"}</label>
              <TextField
                placeholder={"categoria"}
                type="text"
                error={!!errors?.coldexCountryF}
                helperText={
                  errors?.coldexCountryF ? errors.coldexCountryF.message : null
                }
                defaultValue={companyColdexCategory?.categoryName}
                {...register("categoryNameF", { required: "Requerido" })}
                disabled={coldex!==undefined&&coldex?.status!==CONFIG}
              ></TextField>
            </div>
            <div className="popUp-Content__form_field">
              <label>
                {language.components.forms.labels.LABEL_COMPANY_NAME}
              </label>
              <TextField
                select
                disabled={coldex!==undefined&&coldex?.status!==CONFIG}
                defaultValue={coldex!==undefined? `${coldex.tdoc} - ${coldex.ndoc}`:OPTION_COMPANY_DEFAULT}
                SelectProps={{
                  native: true,
                }}
                error={!!errors?.companyF}
                helperText={errors?.companyF ? errors.companyF.message : null}
                {...register("companyF", {})}
              >
                {companies !== undefined ? (
                  companies.map((company) => {
                    return (
                        <option
                          key={`${company.tdoc} - ${company.ndoc}`}
                          value={`${company.tdoc} - ${company.ndoc}`}
                        >
                          {`${company.companyName} ${company.tdoc !== null ? company.tdoc : ''} ${company.ndoc !== null ? company.ndoc : ''}`}
                        </option>
                    )
                  })) : (
                  <option>Error</option>
                )}
              </TextField>
            </div>
          </>
        )}

        <div className="popUp-Content__actions">
          <button
            className="btn_OutLine"
            onClick={(e) => setIsOpen(false)}
            disabled={isLoading ? true : false}
          >
            {language.components.forms.buttons.BTN_FORM_CANCEL}
          </button>
          {coldex !== undefined ? (
            <button type="submit" disabled={isLoading ? true : false}>
              {isLoading ? (
                <>
                  <SpinnerCircular size={24}></SpinnerCircular> &nbsp;{" "}
                  {
                    language.components.forms.coldex.buttons
                      .BTN_UPDATE_COLDEX_STATE
                  }
                </>
              ) : (
                <>
                  {language.components.forms.coldex.buttons.BTN_UPDATE_COLDEX}
                </>
              )}
            </button>
          ) : (
            <button
              type="submit"
              disabled={isLoading ? true : false || isDisabled}
            >
              {isLoading ? (
                <>
                  <SpinnerCircular size={24}></SpinnerCircular> &nbsp;{" "}
                  {
                    language.components.forms.coldex.buttons
                      .BTN_CREATE_COLDEX_STATE
                  }
                </>
              ) : (
                <>
                  {language.components.forms.coldex.buttons.BTN_CREATE_COLDEX}
                </>
              )}
            </button>
          )}
        </div>
      </form>
    </>
  );
};
