import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import { DATE_FORMAT } from "../../../utils/constants/dateFormat";
import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_ACTION_TYPE_DELETE,
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_ACTION_TYPE_UPDATE,
  MESSAGE_CONTEXT_COLDEX,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { Coldex } from "../../../utils/types/Coldex";
import { startLoadingColdex, setColdex, setError, updateColdexLocal, deleteColdexLocal,addColdexLocal } from "./coldexSlice";

/**
  * Esta es una función de TypeScript React que recupera los coldex de un punto final de API y envía acciones
  * basado en el resultado.
  * @returns Una función que devuelve una función asincrónica que despacha acciones basadas en el
  * resultado de una llamada API.
 */
export const getColdex = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingColdex());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<Coldex>>>("adminColdex/coldex/GetColdexs");
    
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setColdex({
            resultObject: data.resultObject,
            resultMessage: data.shortMessage,
            
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
 * Esta es una función de TypeScript React que recupera datos de un punto final de API y envía acciones
  * basado en el resultado.
  * @param {string} email: el parámetro email es una cadena que representa la dirección de correo electrónico de un usuario Padrino.
  * @returns Una función que toma una cadena de correo electrónico como parámetro y devuelve una función asíncrona
  * que envía acciones para actualizar el estado con datos de una llamada API para recuperar los Coldex para un Padrino.
 */
export const getColdexPadrino = (email: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingColdex());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Array<Coldex>>>(`adminColdex/coldex/GetColdexPadrino?email=${email}&date=${new Date().toLocaleString(DATE_FORMAT).split(" ")[0]}`);
    
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setColdex({
            resultObject: data.resultObject,
            resultMessage: data.shortMessage,
            
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta función crea un nuevo objeto Coldex y envía una acción para actualizar el estado en consecuencia.
  * @param {Coldex} coldex: el parámetro de entrada de tipo Coldex que se pasa a la función
  * crearColdex.
  * @returns Una función que toma una función de despacho como argumento y devuelve una Promesa.
 */
export const createColdex = (coldex: Coldex) => {
  return async (dispatch: Dispatch<Action>/*|any*/) => {
    dispatch(startLoadingColdex());
    try {
      const { data } = await ColdexAPI.post<IApiResult<Coldex>>(`adminColdex/Coldex`, coldex);
      if (!data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: false,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
       dispatch(addColdexLocal(data.resultObject));
      } else {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_COLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
* Esta es una función de TypeScript React que elimina un elemento de Coldex por ID y envía acciones basadas en
  * el éxito o fracaso de la eliminación.
  * @param {number} id: la identificación del objeto Coldex que debe eliminarse.
  * @returns Una función que toma un parámetro 'id' y devuelve una función asíncrona que
  * toma un parámetro `Dispatch<Action>` `dispatch`. La función interna despacha
  * Acción `startLoadingColdex()`, luego envía una solicitud DELETE al servidor usando
  * Método `ColdexAPI.delete` con el parámetro `id`. Si la solicitud es exitosa y la respuesta
  * no contiene ningún error de datos,
 */
export const deleteColdex = (id: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingColdex());
    try {
      const { data } = await ColdexAPI.delete<IApiResult<Coldex>>(`adminColdex/Coldex/DeleteColdex/${id}`);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch( deleteColdexLocal(id))
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_COLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COLDEX}`,
          open: true,
        })
      );
    }
  };
};
/**
* Esta es una función de TypeScript React que actualiza un objeto Coldex usando una llamada API y envía
  * acciones basadas en el resultado.
  * @param {Coldex} coldex - El parámetro `coldex` es un objeto de tipo `Coldex` que contiene los datos
  * Para actualizarse.
  * @returns Una función que toma una función de despacho como argumento y devuelve una Promesa.
 */
export const updateColdex = (coldex: Coldex) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingColdex());
    try {
      const { data } = await ColdexAPI.put<IApiResult<Coldex>>(
        `adminColdex/Coldex/UpdateColdex/${coldex.id}`,
        coldex
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(updateColdexLocal(coldex))
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_COLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COLDEX}`,
          open: true,
        })
      );
    }
  };
};

/**
 * Esta es una función de TypeScript React que recupera detalles de un objeto Coldex de una API y
  * Despacha acciones basadas en el resultado.
  * @param {string} id: el ID del objeto Coldex que debe recuperarse. es de
  * tipo cadena.
  * @returns Una función que toma un parámetro de ID y devuelve una función asíncrona que despacha
  * acciones para comenzar a cargar los detalles de Coldex, hacer una llamada a la API para recuperar los detalles de Coldex en función de la
  * ID y enviar un error o establecer los detalles de Coldex recuperados.
 */
export const getColdexDetails = (id: string | undefined) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingColdex());
    try {
      const { data } = await ColdexAPI.get<IApiResult<Coldex>>(`adminColdex/Coldex/GetColdexId/${id}`);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_COLDEX}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setColdex({
            resultObject: [data.resultObject],
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_COLDEX}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_COLDEX}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
