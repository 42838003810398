import { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import { NAME_TEMPLATE_PUBLICO, NAME_TEMPLATE_RED, NAME_TEMPLATE_SECTORIAL, VALIDATE_TEMPLATE_PUBLICO, VALIDATE_TEMPLATE_RED, VALIDATE_TEMPLATE_SECTORIAL } from "../../constants/text-constants";

/**
 * This is a TypeScript function that uses hooks to create a downloadable link for a specific file
 * based on the title passed as a parameter.
 * @param {String} title - A string parameter that is used to determine which template file to
 * download. It is passed as an argument to the useFileFetch hook.
 * @returns a reference to an HTMLAnchorElement that has been created and set with a download link for
 * a specific file based on the title parameter passed to the function. The link is created using the
 * useAppSelector hook to retrieve the file path from the Redux store, and the useState hook to set the
 * link reference. The useEffect hook is used to create the link element, set its attributes,
 */
const useFileFecth = (title: String) => {
  const { file } = useAppSelector((state: RootState) => state.templeteFile);
  const [linkDownload, setLinkDownload] = useState<HTMLAnchorElement>()

  useEffect(() => {
    if (title.toUpperCase().includes(VALIDATE_TEMPLATE_SECTORIAL)) {
      if (file.length > 0) {
          const link = document.createElement("a");
          link.href = file;
          link.setAttribute("download", `${NAME_TEMPLATE_SECTORIAL}.xlsx`); //or any other extension
          document.body.appendChild(link);
          setLinkDownload(link)
          link.remove() 
      }
    } else if (title.toUpperCase().includes(VALIDATE_TEMPLATE_RED)) {
      if (file.length > 0) {
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download",`${NAME_TEMPLATE_RED}.xlsx`); //or any other extension
        document.body.appendChild(link);
        setLinkDownload(link)
        link.remove()
      }
    } else if (title.toUpperCase().includes(VALIDATE_TEMPLATE_PUBLICO)) {
      if (file.length > 0) {
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download",`${NAME_TEMPLATE_PUBLICO}.xlsx`); //or any other extension
        document.body.appendChild(link);
        setLinkDownload(link)
        link.remove()
      }
    }
    
  }, [title,file]);

  return linkDownload;
};

export default useFileFecth;
