import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import { memo, useEffect } from "react";
import { useAppSelector } from "../../../../store/Hooks";
import { RootState } from "../../../../store/Store";
import useActionsTable from "../../../../utils/hooks/tables/useActionsTable";
import { IColdex } from "../../../../utils/interfaces/app/IColdex";
import { Coldex } from "../../../../utils/types/Coldex";
import "../../tables.scss";
import TableRowColdexComponent from "./Components/TableRowColdexComponent";
import useActionsColdex from "../../../../utils/hooks/coldex/useActionsColdex";
import { DELETE_ACTION, PREVIEW_ACTION, UPDATE_ACTION, UPLOAD_ACTION } from "../../../../utils/constants/text-constants";
import { DeleteColdexPopUp } from "../../../PopUps/Coldex/DeleteColdexPopUp";
import { UpdateColdexPopUp } from "../../../PopUps/Coldex/UpdateColdexPopUp";
import { PreviewColdexPopUp } from "../../../PopUps/Preview/PreviewColdexPopUp";
import { UploadTemplatePopUp } from "../../../PopUps/Template/UploadTemplatePopUp";

/**  Este código exporta un componente de React llamado `TableColdex` que toma un objeto con propiedades `content` y `type` de tipo `IColdex<Coldex>`. 
 * Luego representa una tabla con paginación y búsqueda. funcionalidad basada en el `contenido` pasado. La función `memo` se utiliza para memorizar el 
 * componente y mejorar el rendimiento al evitar repeticiones innecesarias. El componente también usa varios hooks 
 * como `useAppSelector` y `useEffect` para administrar el estado y realizar efectos secundarios. 
 * */
export const TableColdex = memo(({content, type}: IColdex<Coldex>) => {
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    cancelSearch,
    requestSearch,
    searched,
    rows,
    setRows,
  } = useActionsTable(content!);
  useEffect(() => {
    setRows(content!);
  }, [content]); //eslint-disable-line react-hooks/exhaustive-deps
  const {
    handleSetPreviewColdex,
    handleSetUpdateColdex,
    handleSetDeleteColdex,
    handleSetUploadTemplete,
    handleSetDetails,
    handleSetReports,
    handleSetMonitoringReports,
    idcoldex,
    open,
    action,
    setOpen,
    coldex,
  } = useActionsColdex();
  return (
    <div className="table-container">
     
      {content !== undefined && (
        <>
          <div className="table-container__searchTable">
            <SearchBar
              value={searched}
              onChange={(searchVal) => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
            />
          </div>
          <TableContainer sx={{ maxHeight: "60vh" }}>
            <Table
              sx={{ minWidth: 700 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {language.components.tables.coldex.coldex.heads.map((column:any) => {
                    return <TableCell key={column.field}>{column.field}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows !== undefined ? (
                  rows.length > 0 ? (
                    rows
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: Coldex) => (
                          <TableRowColdexComponent row={row} type={type!} key={row.id!} handleSetPreviewColdex={handleSetPreviewColdex} handleSetUpdateColdex={handleSetUpdateColdex} handleSetDeleteColdex={handleSetDeleteColdex} handleSetUploadTemplete={handleSetUploadTemplete} handleSetDetails={handleSetDetails} handleSetReports={handleSetReports} handleSetMonitoringReports={handleSetMonitoringReports} idcoldex={idcoldex} coldex={coldex!} action={action}></TableRowColdexComponent>
                      ))
                  ) : (
                    <TableRow>
                    <TableCell  colSpan={language.components.tables.coldex.coldex.heads.length}>
                      {language.components.tables.descriptions.DESCRIPTION_NOT_FOUND}
                    </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableCell colSpan={language.components.tables.coldex.coldex.heads.length}>
                    {language.components.tables.descriptions.DESCRIPTION_NOT_FOUND}
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={content.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          
        </>
      )}
       {open && action === UPDATE_ACTION && (
            <UpdateColdexPopUp
              coldex={coldex!}
              setIsOpen={setOpen}
              type={type}
              isOpen={open}
            ></UpdateColdexPopUp>
          )}
          {open && action === DELETE_ACTION && (
            <DeleteColdexPopUp
              coldex={coldex!}
              setIsOpen={setOpen}
              type={type}
              isOpen={open}
            ></DeleteColdexPopUp>
          )}
          {open && action === PREVIEW_ACTION && (
            <PreviewColdexPopUp
              flag={open}
              setIsOpen={setOpen}
              coldex={coldex!}
            ></PreviewColdexPopUp>
          )}
          {open && action === UPLOAD_ACTION && (
            <UploadTemplatePopUp
              isOpen={open}
              setIsOpen={setOpen}
              coldex={coldex!}
              type={type}
            ></UploadTemplatePopUp>
          )}
    </div>
  );
});
