import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
  MESSAGE_ACTION_TYPE_CREATE,
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_ACTION_TYPE_UPDATE,
  MESSAGE_CONTEXT_ROLS,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { Role } from "../../../utils/types/Role";
import { startLoadingRol, setRols, setError } from "./rolSlice";

/**
  * Esta función recupera una lista de roles de una API y envía acciones según el resultado.
  * @returns Una función que devuelve una función asíncrona que toma una función de envío como argumento.
  */
export const getRols = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingRol());
    const { data } = await ColdexAPI.get<IApiResult<Array<Role>>>("adminUser/Rol");
    try {
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_ROLS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_ROLS}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setRols({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_ROLS}`,
          iserror: false,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_ROLS}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta función crea un nuevo rol y envía acciones de carga y error según la respuesta de la API.
  * @param {Rol} rol: el parámetro `rol` es un objeto de tipo `Role` que contiene información sobre el rol de un usuario.
  * @returns Una función que toma una función de despacho como argumento y devuelve una Promesa.
  */
export const createRol = (rol: Role) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingRol());
    try {
      const { data } = await ColdexAPI.post<IApiResult<Array<Role>>>("adminUser/Rol", rol);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_ROLS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_ROLS}`,
            open: true,
          })
        );
      } else {
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_ROLS}`,
          iserror: false,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_ROLS}`,
          open: true,
        })
      );
    }
  };
};

/**
  * Esta función actualiza un rol mediante una llamada API y envía acciones de carga y error.
  * @param {Rol} rol: el parámetro `rol` es un objeto de tipo `Role` que contiene información sobre el rol de un usuario.
  * @returns Una función que toma un objeto `Role` como entrada y devuelve una función asíncrona que toma un objeto `Dispatch` como entrada y despacha acciones relacionadas con la actualización de un rol en la aplicación.
  */
export const updateRol = (rol: Role) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingRol());
    try {
      const { data } = await ColdexAPI.put<IApiResult<Array<Role>>>(`adminUser/Rol/RolId?RolId=${rol.id}`, rol);
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_ROLS}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_ROLS}`,
            open: true,
          })
        );
      } else {
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_ROLS}`,
          iserror: false,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_ROLS}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
