import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexAPI } from '../../../apis/apiColdex/ColdexAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_ACTION_TYPE_DELETE, MESSAGE_ACTION_TYPE_GET, MESSAGE_ACTION_TYPE_UPDATE, MESSAGE_CONTEXT_PADRINO, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApiResult } from '../../../utils/interfaces/store/IApiResponse';
import { Padrino } from '../../../utils/types/Padrino';
import { startPradinosLoading, setPadrinos,setError,addPadrinoLocal,updatePadrinoLocal,deletePadrinoLocal } from './padrinoxColdexSlice';

/**
  * Esta es una función de TypeScript que recupera una lista de padrinos asociados con una ID de Coldex dada desde una API y envía el resultado a una  Redux store.
  * @param {bigint | number} idcoldex - El parámetro `idcoldex` es un número o bigint que representa el ID de un objeto Coldex. Se usa en la llamada API para recuperar una lista de Padrinos asociados con ese objeto Coldex.
  * @returns Una función que toma un argumento `idcoldex` de tipo `bigint` o `number` y devuelve una función asíncrona que toma un argumento `dispatch` de tipo `Dispatch<Action>`. La función interna envía acciones relacionadas con la carga y configuración de datos de `Padrino` desde una llamada API usando `ColdexAPI`. Si hay un error, envía un mensaje de error.
  */
export const getPadrinoxColdex = (idcoldex:bigint | number) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startPradinosLoading() );
        try {
            const { data } = await ColdexAPI.get<IApiResult<Array<Padrino>>> ("adminColdex/PadrinoxColdex/GetPadrinoxColdex?idColdex="+idcoldex);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
            }else{
                dispatch( setPadrinos({ resultObject: data.resultObject, resultMessage: data.resultMessage  }));
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
        }
    }
}

/**
  * Esta es una función de TypeScript que elimina un objeto PadrinoxColdex del servidor y actualiza el estado local si tiene éxito.
  * @param {Padrino} padrino - El parámetro `padrino` es un objeto de tipo `Padrino`, que se pasa como argumento a la función `deletePadrinoxColdex`.
  * @returns La función `deletePadrinoxColdex` devuelve una función que toma un parámetro `dispatch` y devuelve una Promesa.
  */
export const deletePadrinoxColdex = (padrino:Padrino) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startPradinosLoading() );
        try {
            const { data } = await ColdexAPI.delete<IApiResult<Padrino>>("adminColdex/PadrinoxColdex/DeletePadrinoxColdex/"+padrino.email,{data:padrino});
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
            }else{
                dispatch(deletePadrinoLocal(padrino))
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
        }
    }
}

/**
  * Esta es una función de TypeScript que publica una lista de padrinos en un extremo de la API de Coldex y envía una acción para agregar el primer padrino a la tienda local.
  * @param padrinos - Una matriz de objetos de tipo Padrino.
  * @returns Una función que toma una función de despacho como argumento y devuelve una Promesa.
  */
export const postPadrinoxColdex = (padrinos:Array<Padrino>)=>{
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startPradinosLoading() );
        try {
            const { data } = await ColdexAPI.post<IApiResult<Array<Padrino>>>("adminColdex/PadrinoxColdex",padrinos);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
            }else{
                dispatch(addPadrinoLocal(padrinos[0]))
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
        }
    }
}

/**
  * Esta es una función de TypeScript que actualiza un objeto PadrinoxColdex en la base de datos y envía una acción para actualizar el estado local.
  * @param {Padrino} padrino - El parámetro `padrino` es un objeto de tipo `Padrino` que contiene la información actualizada de un padrino (padrino) en un sistema Coldex.
  * @param {cadena} email: el parámetro de correo electrónico es una cadena opcional que representa el correo electrónico del
  *padrino (padrino) siendo actualizado en el sistema. Se utiliza como parte del extremo de la API para identificar el padrino específico que se está actualizando. Si no se proporciona, el padrino no se actualizará.
  * @devuelve una función asíncrona que toma una función de envío como argumento.
  */
export const updatePadrinoxColdex = (padrino:Padrino,email?:string) => {
    return async( dispatch:Dispatch<Action>) => {
        dispatch( startPradinosLoading() );
        try {
            const { data } = await ColdexAPI.put<IApiResult<Padrino>>("adminColdex/PadrinoxColdex/UpdatePadrinoxColdex/"+email,padrino);
            if(data.dataError){
                dispatch(setError({ error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
              
            }else{
               dispatch(updatePadrinoLocal(padrino))
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_PADRINO}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_PADRINO}`, open: true }));
        }
    }
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}