import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPollxColdexConfigState } from '../../../utils/interfaces/store/IStore';
import { ColdexConfig } from '../../../utils/types/ColdexConfig';
import { ColdexInfo } from '../../../utils/types/ColdexInfo';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';

const initialState: IPollxColdexConfigState   = {
    info: {
        Children: undefined,
        IdColdex: 0,
        IdPoll: '',
        IdCategory: '',
        Description: '',
        PollDescription: '',
        IdType: '',
        PollLevel: '',
        PollType: '',
        Value: '',
        Calification: 0
    },
    pollxColdex: [],
    shortMessage: '',
    resultMessage: '',
    dataError: false,
    open: false,
    isLoading: false
}

export const pollxColdexConfigSlice = createSlice({
    name: 'Category',
    initialState,
    reducers:{
        startLoadingPollxColdexConfig: (state) =>{
            state.isLoading = true;
        },
        setPollxColdexConfig:(state, {payload}:PayloadAction<IPayloadSetdata<Array<ColdexConfig>>>)=>{
            state.isLoading = false;
            state.dataError = false;
            state.pollxColdex = payload.resultObject;
        },
        setInfo:(state,{payload}:PayloadAction<IPayloadSetdata<ColdexInfo|null>>)=>{
            state.isLoading = false;
            state.info=payload.resultObject
            state.pollxColdex=[]
        },
        setReset:(state)=>{
            state.pollxColdex= []
        },
        setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
            state.isLoading = false;
            state.dataError = payload.iserror;
            state.resultMessage = payload.error;
            state.shortMessage = payload.shorterror;
            state.open = payload.open!;
        }
    }
})

export const {startLoadingPollxColdexConfig, setPollxColdexConfig,setInfo,setReset,setError} = pollxColdexConfigSlice.actions

export default pollxColdexConfigSlice.reducer
