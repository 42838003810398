import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPermissionXRolState } from '../../../utils/interfaces/store/IStore'
import { PermissionxRolUpdate } from '../../../utils/types/Permission'
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces'

const initialState: IPermissionXRolState = {
  permissionRolUser: [],
  permissionRol: [],
  isLoadingUpdate: false,
  permissionUpdate: 0,
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}

export const permissionsXRolSlice = createSlice({
  name: 'permissionXRol',
  initialState,
  reducers: {
    startPermissionLoading:(state)=>{
        state.isLoading=true
    },
    startPermissionLoadingUpdate:(state)=>{
        state.isLoadingUpdate=true
    },
    setPermission:(state,{payload}:PayloadAction<IPayloadSetdata<string>>)=>{
        state.isLoading=false;
        state.dataError = false;
        state.permissionRolUser= JSON.parse(payload.resultObject)
        state.resultMessage = payload.resultMessage!;
    },
    setPermissionRol:(state,{payload}:PayloadAction<IPayloadSetdata<string>>)=>{
        state.isLoading=false;
        state.dataError = false;
        state.permissionRol= JSON.parse(payload.resultObject)
        state.resultMessage = payload.resultMessage!;   
    },
    setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
        state.isLoading = false;
        state.dataError = payload.iserror;
        state.resultMessage = payload.error;
        state.shortMessage = payload.shorterror;
        state.open=payload.open!
    },
    setErrorUpdate:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
        state.isLoadingUpdate = false;
        state.dataError = payload.iserror;
        state.resultMessage = payload.error;
        state.shortMessage = payload.shorterror;
    },
    setPermissionUpdate:(state,{payload}:PayloadAction<number>)=>{
      state.isLoadingUpdate=false;
      state.dataError = false;
      state.permissionUpdate= payload
  },
  },
})

// Action creators are generated for each case reducer function
export const {  startPermissionLoading,setPermission,setError, setPermissionRol,startPermissionLoadingUpdate, setPermissionUpdate, setErrorUpdate} = permissionsXRolSlice.actions

export default permissionsXRolSlice.reducer