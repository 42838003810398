import { useState, useEffect } from 'react';

/**
 * Esta función devuelve el ancho y el alto de la ventana del navegador.
  * @returns La función `getWindowDimensions` devuelve un objeto con dos propiedades: `width` y
  * `height`, que representan las dimensiones actuales de la ventana del navegador.
 */
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
/* Este código exporta un custom hook  que devuelve el ancho actual
y la altura de la ventana del navegador. Utiliza el hook `useState` para almacenar las dimensiones actuales y
el gancho `useEffect` para agregar y eliminar un detector de eventos para el evento `resize`, que actualiza el
dimensiones cada vez que se cambia el tamaño de la ventana. */
export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}