import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/Hooks";
import { RootState } from "../../../store/Store";
import {
  FILTER_COLDEX_RED_TYPE,
  TITLE_COLDEX_SECTION_PUBLICO,
  TITLE_COLDEX_SECTION_RED,
  TITLE_COLDEX_SECTION_SECTORIAL,
} from "../../../utils/constants/text-constants";
import { IPopUpColdex } from "../../../utils/interfaces/app/IPopUps";
import { ColdexForm } from "../../Forms/ColdexForm/ColdexForm";
import { PopUpTitle } from "../../Titles/PopUpTitle";
import "../popUps.scss";
import { GetCompanyColdexCategory } from "../../../store/slices/companyColdexCategory";

/**
 * Este es un componente de TypeScript React que muestra una ventana emergente para actualizar 
 * un coldex.
 * @param {IPopUpColdex}  - - `isOpen`: un valor booleano que indica si la ventana emergente está 
 * actualmente abierta o cerrada
 * @returns Se devuelve un componente funcional de React llamado UpdateColdexPopUp.
 */
export const UpdateColdexPopUp = ({
  isOpen,
  setIsOpen,
  coldex,
  type,
}: IPopUpColdex) => {
  const {language} = useAppSelector( (state:RootState) => state.multiLanguage);
  const {isLoading} = useAppSelector( (state:RootState) => state.companyColdexCategory);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if(type===FILTER_COLDEX_RED_TYPE.toUpperCase()){
     dispatch(GetCompanyColdexCategory(coldex?.idColdexCategory!))
    }
   }, [])
  return (
    <div className={isOpen ? "open-PopUp" : "close-PopUp"}>
      {
        isLoading===false&&
        <div className="popUp-Content">
        {type === TITLE_COLDEX_SECTION_RED && (
          <>
            <PopUpTitle
              setIsOpen={setIsOpen}
              title={`${language.components.popUps.coldex.titles.TITLE_FORM_UPDATE_COLDEX} ${language.components.popUps.coldex.titles.TITLE_COLDEX_SECTION_RED}`}
            ></PopUpTitle>
            <ColdexForm
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              type={TITLE_COLDEX_SECTION_RED}
              coldex={coldex}
            />
          </>
        )}
        {type === TITLE_COLDEX_SECTION_SECTORIAL && (
          <>
            <PopUpTitle
              setIsOpen={setIsOpen}
              title={`${language.components.popUps.coldex.titles.TITLE_FORM_UPDATE_COLDEX} ${language.components.popUps.coldex.titles.TITLE_COLDEX_SECTION_SECTORIAL}`}
            ></PopUpTitle>
            <ColdexForm
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              type={TITLE_COLDEX_SECTION_SECTORIAL}
              coldex={coldex}
            />
          </>
        )}
           {type === TITLE_COLDEX_SECTION_PUBLICO && (
          <>
            <PopUpTitle
              setIsOpen={setIsOpen}
              title={`${language.components.popUps.coldex.titles.TITLE_FORM_UPDATE_COLDEX} ${language.components.popUps.coldex.titles.TITLE_COLDEX_SECTION_PUBLICO}`}
            ></PopUpTitle>
            <ColdexForm
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              type={TITLE_COLDEX_SECTION_PUBLICO}
              coldex={coldex}
            />
          </>
        )}
      </div>
      }
      
    </div>
  );
};
