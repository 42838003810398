import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminUsersState } from '../../../utils/interfaces/store/IStore';
import { User } from '../../../utils/types/User';
import { IPayloadSetdata, IPayloadSetError } from '../../Interfaces';


const initialState:  IAdminUsersState = {
  users: [],
  user: undefined,
  shortMessage: '',
  resultMessage: '',
  dataError: false,
  open: false,
  isLoading: false
}

export const adminUsersSlice = createSlice({
  name: 'adminusers',
  initialState,
  reducers: {
  startLoadingUsers: (state) => {
      state.isLoading = true;
  },
  startLoadingUser: (state) => {
      state.isLoading = true;
  },
  setUsers: ( state, {payload}:PayloadAction<IPayloadSetdata<Array<User>>> ) => {
      state.isLoading = false;
      state.users = payload.resultObject;
  },
  setError:(state,{payload}:PayloadAction<IPayloadSetError>)=>{
      state.isLoading = false;
      state.dataError= payload.iserror;
      state.resultMessage=payload.error;
      state.shortMessage=payload.shorterror;
      state.open=payload.open!
  },
  setUser:(state,{payload}:PayloadAction<IPayloadSetdata<User>>)=>{
      state.isLoading = false;
      state.user= payload.resultObject;
  },
  endLoadingUsers: (state ) => {
    state.isLoading = false;
  },
  deleteUserLocal:(state,{payload}:PayloadAction<string>)=>{
    state.users = state.users.filter( user => user.email !== payload );
    state.isLoading=false;
  },
  addUserLocal:(state,{payload}:PayloadAction<User>)=>{
    state.users.push(payload)
  },
  updateUserLocal: (state, {payload}:PayloadAction<User> ) => {
    state.users = state.users.map( user => {
        if ( user.email === payload.email) {
            return payload;
        }
        return user;
    });
  }
  }
})

export const { startLoadingUsers,setUsers,setError,endLoadingUsers, setUser, startLoadingUser,deleteUserLocal,addUserLocal,updateUserLocal } = adminUsersSlice.actions

export default adminUsersSlice.reducer