import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexAPI } from "../../../apis/apiColdex/ColdexAPI";
import {
    MESSAGE_ACTION_TYPE_CREATE,
    MESSAGE_ACTION_TYPE_DELETE,
    MESSAGE_ACTION_TYPE_GET,
    MESSAGE_ACTION_TYPE_UPDATE,
    MESSAGE_CONTEXT_RESOURCES,
    MESSAGE_ERROR_TIMEOUT,
  } from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";

  import { SectionForm } from "../../../utils/types/Section";
  import { startLoadingSectionForm, setSectionForm, setError } from "./sectionFormSlice";

/**
   * Esta es una función que recupera datos de secciones de formulario y envía acciones en función del resultado.
   * @returns Una función que devuelve una función asíncrona que distribuye acciones en función del resultado de una llamada a la API.
   */
  export const getSectionForm = () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(startLoadingSectionForm());
      try {
        const { data } = await ColdexAPI.get<IApiResult<Array<SectionForm>>>("/adminresources/api/SectionForm");
        if (data.dataError) {
          dispatch(
            setError({
              error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_RESOURCES}`,
              iserror: true,
              shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
              open: true,
            })
          );
        } else {
          dispatch(
            setSectionForm({
              resultObject: data.resultObject,
              resultMessage: data.resultMessage,
            })
          );
        }
      } catch (error) {
        dispatch(
          setError({
            error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      }
    };
  };

/**
   * Esta es una función que crea un nuevo de sección formulario y envía una acción para actualizar el estado en consecuencia.
   * @param {SectionForm} sectionForm: el objeto de sección formulario  que se enviará al servidor para su creación.
   * @returns Una función que toma un objeto `sectionForm` como entrada y devuelve una función asíncrona que envía acciones para comenzar a cargar el formulario de sección, realizar una solicitud POST a un punto final API específico con los datos de `sectionForm`, manejar cualquier error que pueda ocurren durante la solicitud y envían acciones para establecer un mensaje de error o un mensaje de éxito según el resultado de la solicitud.
   */
  export const createSectionForm = (
    sectionForm: SectionForm
    ) => {
    return async (dispatch: Dispatch<Action> | any) => {
      dispatch(startLoadingSectionForm());
      try {
        const { data } = await ColdexAPI.post<IApiResult<Array<SectionForm>>>(
        `/adminresources/api/SectionForm`, 
        sectionForm
        );
        if (data.dataError) {
          dispatch(setError({ iserror: true, error: data.resultMessage,shorterror:data.shortMessage }));
        } else {
          dispatch(
            setError({
              error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
              iserror: false,
              shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
              open: true,
            })
          );
          dispatch(getSectionForm());
        }
      } catch (error) {
        dispatch(
          setError({
            error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      }
    };
  }

/**
  * Esta es una función asíncrona que actualiza un sección formulario y envía un mensaje de error si hay algún problema.
  * @param {SectionForm} sectionForm: una variable de tipo SectionForm, que es un objeto que contiene datos para un sección formulario .
  * @returns Una función que toma un objeto `sectionForm` y devuelve una función asíncrona que envía acciones para actualizar los datos del sección formulario en la tienda Redux y realiza una llamada a la API para actualizar los datos del sección formulario  en el servidor. La función devuelve una Promesa que resuelve con los datos del formulario de la sección actualizada o rechaza con un mensaje de error.
  */
  export const updateSectionForm = (sectionForm: SectionForm) => {
    return async (dispatch: Dispatch<Action> | any) => {
      dispatch(startLoadingSectionForm());
      try {
        const { data } = await ColdexAPI.put<IApiResult<Array<SectionForm>>>(`/adminresources/api/SectionForm/${sectionForm.fieldID}`,sectionForm);
        if (data.dataError) {
          dispatch(
            setError({
              error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
              iserror: true,
              shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
              open: true,
            })
          );
        } else {
          dispatch(
            setError({
              error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
              iserror: false,
              shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
              open: true,
            })
          );
          dispatch(getSectionForm());
        }
      } catch (error) {
        dispatch(
          setError({
            error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      }
    };
  };
  
/**
  * Esta es una función de TypeScript React que elimina un sección formulariopor ID y envía acciones según el resultado.
  * @param {number} id: el parámetro id es un número que representa el identificador único de sección que debe eliminarse.
  * @returns Una función que toma un parámetro numérico y devuelve una función asíncrona que toma un parámetro de envío y envía acciones según el resultado de una llamada a la API.
  **/
  export const deleteSectionForm = (id: number) => {
    return async (dispatch: Dispatch<Action> | any) => {
      dispatch(startLoadingSectionForm());
      try {
        const { data } = await ColdexAPI.delete<IApiResult<Array<SectionForm>>>(`/adminresources/api/SectionForm/${id}`);
        if (data.dataError) {
          dispatch(
            setError({
              error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_DELETE} en ${MESSAGE_CONTEXT_RESOURCES}`,
              iserror: true,
              shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_RESOURCES}`,
              open: true,
            })
          );
        } else {
          dispatch(getSectionForm());
        }
      } catch (error) {
        dispatch(
          setError({
            error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_UPDATE} en ${MESSAGE_CONTEXT_RESOURCES}`,
            iserror: true,
            shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_RESOURCES}`,
            open: true,
          })
        );
      }
    };
  };
  /**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
  export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
      dispatch(
        setError({ error: "", iserror: false, shorterror: "", open: false })
      );
    };
  };
  