import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Test{
  isLoading : boolean,
  language: any,
  languageSelect: string
}

const initialState: Test = {
    isLoading: false,
    language: undefined,
    languageSelect:"spanish"
};

export const multiLanguage = createSlice({
  name: "multiLanguage",
  initialState,
  reducers: {
     startLoadingLanguage: (state) =>{
        state.isLoading = true;
    },
    setLanguage: (state, {payload}:PayloadAction<any>) => {
        state.isLoading = false;
        state.language = payload;
    },
    setLanguageSelect:(state, {payload}:PayloadAction<string>)=>{
      state.languageSelect = payload
    }
  },
});

export const { setLanguage, startLoadingLanguage, setLanguageSelect} = multiLanguage.actions;

export default multiLanguage.reducer;
